import React from 'react';
import { Select as AntdSelect, Form } from 'antd';
import { SelectWrapper } from './styles';

const Select = (props: any) => {
  const { formItem, marginBottom, mode, ...rest } = props;
  return (
    <SelectWrapper marginBottom={marginBottom} mode={mode}>
      <Form.Item {...formItem}>
        <AntdSelect {...rest} />
      </Form.Item>
    </SelectWrapper>
  );
};

export default Select;
