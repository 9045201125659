import React from 'react';
import { Input as AntdInput, Form, Select, Popover, InputNumber } from 'antd';
import { InputWrapper } from './styles';
import CountriesFlagDialCodes from '../../data/countries-flags-dial-codes.json';

const Input = (props: any) => {
  const { formItem, marginBottom, mode, ...rest } = props;
  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 100 }} defaultValue="🇳🇬+234">
        {CountriesFlagDialCodes.map((item) => {
          const { code, flag } = item;
          return (
            <Select.Option value={code}>
              {flag}+{code}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  return (
    <InputWrapper marginBottom={marginBottom} mode={mode}>
      {rest.type === 'password' && rest.hasHintPopoverContent ? (
        <Popover
          overlayClassName="password-hints-popover"
          content={rest.hintPopoverContent}
          visible={rest.hasHintPopoverContent}
          title=""
        >
          <Form.Item {...formItem}>
            <AntdInput.Password {...rest} />
          </Form.Item>
        </Popover>
      ) : (
        <Form.Item {...formItem}>
          {rest.type === 'password' ? (
            <AntdInput.Password {...rest} />
          ) : rest.type === 'phone' ? (
            <AntdInput addonBefore={prefixSelector} {...rest} />
          ) : rest.type === 'number' ? (
            <InputNumber
              addonBefore={prefixSelector}
              {...rest}
              style={{ width: '100%' }}
            />
          ) : (
            <AntdInput {...rest} value={props.value} />
          )}
        </Form.Item>
      )}
    </InputWrapper>
  );
};

export default Input;
