import React from 'react';
import { Space } from 'antd';
import Typography from 'component/Typography';

export type DrugProps = {
  name: string;
  description: string;
  prescription: string;
};

function Drug(props: DrugProps) {
  const { name, description, prescription } = props;
  return (
    <div style={{ marginBottom: 16 }}>
      <Space direction="vertical">
        <Typography weight="bold">{name}</Typography>
        <Typography color="textSecondary" variant="body2">
          {prescription}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {description}
        </Typography>
      </Space>
    </div>
  );
}

export default Drug;
