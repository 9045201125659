import React from 'react';
import { Row, Col, Space } from 'antd';
import { NoteContainer } from './styles';
import Divider from 'component/Divider';
import Typography from 'component/Typography';
import Tag from 'component/Tag';
import * as dfn from 'date-fns';
import InfoTypography from 'component/InfoTypography';

function Note(props: any) {
  const { consult } = props;

  return (
    <NoteContainer>
      <Row justify="space-between" align="middle">
        <Col>
          <Typography>
            {dfn.format(new Date(consult?.created_at), 'MMMM d, yyyy KK:mma')}
          </Typography>
        </Col>
        <Col>
          <Typography transform="capitalize">
            Status: {consult?.status}
          </Typography>
        </Col>
      </Row>
      <Divider />
      <Space direction="vertical" size="large">
        <div>
          <Typography gutterBottom weight="bolder">
            Reasons for encounter
          </Typography>
          <Space wrap>
            {consult?.symptoms?.map((symptom: any, index: number) => (
              <Tag key={index}>{symptom.symptom}</Tag>
            ))}
          </Space>
        </div>
        {/* <div>
          <Typography gutterBottom weight="bolder">
            History of presenting complaints
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {consult?.complaint_history}
          </Typography>
        </div> */}
        <div>
          <Typography gutterBottom weight="bolder">
            Examination findings
          </Typography>
          {consult?.examinations?.map((examination: any, index: number) => (
            <InfoTypography
              labelTypographyProps={{ variant: 'body2' }}
              valueTypographyProps={{ variant: 'body2' }}
              labelCol={{ md: 24, sm: 24 }}
              label={examination.exam_name}
              value={examination.exam_comment}
            />
          ))}
        </div>
        <div>
          <Typography gutterBottom weight="bolder">
            Diagnosis
          </Typography>
          <Space wrap>
            {consult?.diagnosis?.map((item: any, index: number) => (
              <Tag key={index}>{item.condition}</Tag>
            ))}
          </Space>
        </div>
        <div>
          <Typography gutterBottom weight="bolder">
            Recommendations
          </Typography>
          <Space wrap>
            {consult?.recommendations?.map((item: any, index: number) => (
              <Tag key={index}>{item}</Tag>
            ))}
          </Space>
        </div>
        <div>
          <Typography gutterBottom weight="bolder">
            Investigations
          </Typography>
          <Space wrap>
            {consult?.investigations?.map((item: any, index: number) => (
              <Tag key={index}>{item.name}</Tag>
            ))}
          </Space>
        </div>
        <div>
          <Typography gutterBottom weight="bolder">
            Prescriptions
          </Typography>
          <Space wrap>
            {consult?.medications?.map((item: any, index: number) => (
              <Tag key={index}>{item.name}</Tag>
            ))}
          </Space>
        </div>
      </Space>
    </NoteContainer>
  );
}

export default Note;
