import React, { useContext, useMemo, useState } from 'react';

import { Row, Col, Space, Checkbox, Divider, Empty } from 'antd';
import { LabSidePaneColumnContainer } from './styles';
import { DeleteFilled } from '@ant-design/icons';
import Typography from 'component/Typography';
import Button from 'common/Button';
import Modal from 'component/Modal';
import {
  encounterConsultApi,
  encounterStartApi
} from 'redux/queries/encounter';
import ContentLoading from 'component/ContentLoading';
import Card from 'common/Card';
import * as dfn from 'date-fns';
import { DateFormatEnum } from 'constants/global';
import useToggle from 'hooks/useToggle';
import Select, { SelectOption } from 'common/Select';
import { useTheme } from 'styled-components';
import consultUnselectedMessageSvg from 'assets/consult-unselected-message.gif';
import useDataRef from 'hooks/useDataRef';
import { useAppSelector } from 'redux/store';

function MyHealthLabSidePaneOrderTestInvestigationsSelection(props: any) {
  const { formik } = props;
  const { user } = useAppSelector((state) => state.global);
  const theme = useTheme() as any;

  const [isAddTestModal, toggleAddTestModal] = useToggle();

  const isFindInvestigation = formik.values.investigationSlice === 1;

  const consultQueryResult = encounterConsultApi.useGetEncounterConsultsQuery(
    { userId: user.id },
    { skip: isFindInvestigation }
  );

  console.log('e render sidepane');

  const dataRef = useDataRef({ formik });

  const consults = consultQueryResult?.data?.data;
  const investigations = useMemo(
    () => Object.values(formik.values.investigations),
    [formik.values.investigations]
  );

  const [investigationState, setInvestigationState] = useState<any[]>(
    investigations || []
  );

  const CONSULTS_INVESTIGATION = useMemo(() => {
    return (
      consults
        ?.filter(
          (consult: any, index: number) => !!consult.investigations?.length
        )
        ?.map((consult: any, index: number) => (
          <Col span={24} key={index}>
            {/* <ConsultCard consult={consult} formik={dataRef.current.formik} /> */}
            <ConsultCard
              consult={consult}
              formik={dataRef.current.formik}
              investigationState={investigationState}
              setInvestigations={setInvestigationState}
            />
          </Col>
        )) || []
    );
  }, [consults, dataRef, investigationState, setInvestigationState]);

  return (
    <>
      {isAddTestModal && (
        <AddInvestigationModal formik={formik} onCancel={toggleAddTestModal} />
      )}
      <LabSidePaneColumnContainer>
        {isFindInvestigation ? null : (
          <Typography color="textSecondary" style={{ marginBottom: 16 }}>
            Select an encounter to order a test
          </Typography>
        )}
        <ContentLoading
          loading={consultQueryResult.isLoading}
          error={consultQueryResult.isError}
          onReload={consultQueryResult.refetch}
        >
          {() => (
            <>
              <div className="flex-column-content">
                <Row gutter={[0, 8]} justify="center">
                  {
                    [
                      <>
                        {!!CONSULTS_INVESTIGATION?.length ? (
                          CONSULTS_INVESTIGATION
                        ) : (
                          <Col span={24}>
                            <Empty
                              image={consultUnselectedMessageSvg}
                              imageStyle={{
                                height: 250
                              }}
                              description={
                                <Typography>
                                  Oh! you’ve no consult with investigations
                                </Typography>
                              }
                            >
                              <Button
                                type="primary"
                                onClick={() => {
                                  formik.setFieldValue('investigationSlice', 1);
                                }}
                              >
                                Find investigations
                              </Button>
                            </Empty>
                          </Col>
                        )}
                      </>,
                      <Col span={24}>
                        <SearchInvestigation formik={formik} />
                      </Col>
                    ][formik.values.investigationSlice]
                  }
                  {investigations.map((investigation: any, index: any) => {
                    return !investigation?.fromConsult ? (
                      <Col span={24}>
                        <Card
                          key={investigation.id}
                          bodyStyle={{ padding: '8px 16px' }}
                        >
                          <Row wrap={false}>
                            <Col flex={1}>
                              <Typography key={index}>
                                {investigation.name}
                              </Typography>
                            </Col>
                            <Col>
                              <DeleteFilled
                                style={{ color: theme.palette.error.main }}
                                onClick={(e) => {
                                  const investigations = Object.assign(
                                    {},
                                    formik.values.investigations
                                  );
                                  delete investigations[investigation.id];
                                  formik.setFieldValue(
                                    'investigations',
                                    investigations
                                  );
                                }}
                              />
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    ) : null;
                  })}
                </Row>
              </div>
              <Divider style={{ margin: 0 }} />
              {!isFindInvestigation && !!CONSULTS_INVESTIGATION?.length && (
                <Row justify="center" style={{ padding: 8 }}>
                  <Col>
                    <Button type="link" onClick={toggleAddTestModal}>
                      Add investigations
                    </Button>
                  </Col>
                </Row>
              )}
            </>
          )}
        </ContentLoading>
      </LabSidePaneColumnContainer>
    </>
  );
}

export default MyHealthLabSidePaneOrderTestInvestigationsSelection;

function ConsultCard(props: any) {
  const { formik, consult, investigationState = [], setInvestigations } = props;

  const formQueryResult = encounterStartApi.useGetEncounterComplaintFormQuery(
    useMemo(() => ({ path: { id: consult?.form } }), [consult?.form])
    // { skip: isAccepted || hasEnded }
  );

  return (
    <Card bodyStyle={{ padding: 16 }}>
      <ContentLoading
        loading={formQueryResult.isLoading}
        error={formQueryResult.isError}
        onReload={formQueryResult.refetch}
      >
        {() => (
          <>
            <Row>
              <Col flex={1}>
                <Typography>For {formQueryResult.data?.data?.name}</Typography>
                <Typography color="primary" variant="body2">
                  By{' '}
                  {`${consult?.provider?.last_name} ${consult?.provider?.first_name}`}
                  {' on '}
                  {dfn.format(
                    new Date(consult?.created_at),
                    DateFormatEnum.HYPHEN
                  )}
                </Typography>
              </Col>
            </Row>
            <Divider style={{ margin: '16px 0px' }} />
            <Space direction="vertical" style={{ width: '100%' }}>
              {consult.investigations.map((investigation: any, index: any) => (
                <Row wrap={false} key={investigation.id}>
                  <Col flex={1}>
                    <Typography key={index}>{investigation.name}</Typography>
                  </Col>
                  <Col>
                    <Checkbox
                      checked={investigationState.find(
                        (item: any) => item.id === investigation.id
                      )}
                      onChange={(e) => {
                        const investigations = [...investigationState];
                        // if element is selected, push to to our investigations arr.
                        if (e.target.checked) {
                          investigations.push({
                            id: investigation.id,
                            name: investigation.name,
                            category: investigation.category,
                            fromConsult: true
                          });
                        } else {
                          // get element index
                          const index = investigations.findIndex(
                            (elem) => elem.id === investigation.id
                          );
                          // delete it from investigations list
                          if (index > -1) {
                            investigations.splice(index, 1);
                          }
                        }
                        setInvestigations(() => [...investigations]);
                        formik.setFieldValue('investigations', investigations);
                      }}
                    />
                  </Col>
                </Row>
              ))}
            </Space>
          </>
        )}
      </ContentLoading>
    </Card>
  );
}

function AddInvestigationModal(props: any) {
  const { formik, onCancel } = props;

  const [state, setState] = useState([]);

  const investigationsQueryResult =
    encounterConsultApi.useGetInvestigationsQuery(undefined);

  const data = investigationsQueryResult?.data?.data;

  const normalized = useMemo(
    () =>
      data?.reduce((acc: any, curr: any) => {
        acc[curr.id] = curr;
        return acc;
      }, {}) || {},
    [data]
  );

  function handleAddTest() {
    formik.setFieldValue('investigations', {
      ...formik.values.investigations,
      ...state.reduce((acc: any, curr: any) => {
        acc[curr] = normalized[curr];
        return acc;
      }, {})
    });
    onCancel();
  }

  return (
    <Modal
      title="Add new investigation"
      visible
      footer={null}
      onCancel={onCancel}
    >
      <ContentLoading
        loading={investigationsQueryResult.isLoading}
        error={investigationsQueryResult.isError}
        onReload={investigationsQueryResult.refetch}
      >
        {() => (
          <Row justify="center" gutter={[0, 16]}>
            <Col xs={24}>
              <Select
                underline
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Search for new investigations to add"
                value={state}
                onChange={(values) => setState(values)}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option!.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {data?.map((item: any, index: number) =>
                  !formik.values.investigations[item.id] ? (
                    <SelectOption key={index} value={item.id}>
                      {item.name}
                    </SelectOption>
                  ) : null
                )}
              </Select>
            </Col>
            <Col>
              <Button type="primary" onClick={handleAddTest}>
                Add investigations
              </Button>
            </Col>
          </Row>
        )}
      </ContentLoading>
    </Modal>
  );
}

function SearchInvestigation(props: any) {
  const { formik } = props;
  const [value, setValue] = useState(undefined! as string);

  const investigationsQueryResult =
    encounterConsultApi.useGetInvestigationsQuery(undefined);

  const data = investigationsQueryResult?.data?.data;

  const normalized = useMemo(
    () =>
      data?.reduce((acc: any, curr: any) => {
        acc[curr.id] = curr;
        return acc;
      }, {}) || {},
    [data]
  );

  return (
    <ContentLoading
      loading={investigationsQueryResult.isLoading}
      error={investigationsQueryResult.isError}
      onReload={investigationsQueryResult.refetch}
    >
      {() => (
        <Select
          underline
          style={{ width: '100%', marginBottom: 16 }}
          // showArrow={false}
          value={value}
          showSearch
          placeholder="Search for new investigation to add"
          onChange={(id) => {
            formik.setFieldValue('investigations', {
              ...formik.values.investigations,
              [id]: normalized[id]
            });
            setValue('');
          }}
          optionFilterProp="children"
        >
          {data?.map((item: any, index: number) =>
            !formik.values.investigations[item.id] ? (
              <SelectOption key={item.id} value={item.id}>
                {item.name}
              </SelectOption>
            ) : null
          )}
        </Select>
      )}
    </ContentLoading>
  );
}
