import { CloseCircleOutlined } from '@ant-design/icons';
import { Col, Form, Row, message } from 'antd';
import React, { useContext, useState } from 'react';
import Button from '../Button';
import DatePicker, { TimePicker } from '../DatePicker';
import TextArea from '../TextArea';
import { OrderPlanWrapper, ModalWrapper } from './styles';
import { useAppDispatch } from '../../redux/store';
import moment from 'moment';
import {
  IConfig,
  daysOfWeek,
  SinglePlan,
  ProviderProfile
} from 'config/interfaces';
import { usePaystackPayment } from 'react-paystack';
import { send } from 'emailjs-com';
import { profileApi } from 'redux/queries/profile';
import { Context } from 'context/context';
import http from 'utils/api';
import { getDisabledHours, getDisabledMinutes } from 'utils/helpers';

interface OrderPlanModalProps {
  selectedPlan: SinglePlan;
  setSelectedPlan: (plan: any) => void;
  providerProfile: ProviderProfile['data'];
}

const OrderPlanModal = ({
  selectedPlan,
  setSelectedPlan,
  providerProfile
}: OrderPlanModalProps) => {
  const dispatch = useAppDispatch();
  const [requestForm] = Form.useForm();
  const servicePlanOfInterest = selectedPlan;
  const [applyLoading, setApplyLoading] = useState(false);
  const [unitValue, setUnitValue] = useState(1);
  const [isTodaySelected, setIsTodaySelected] = useState(false);
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [selectedDay, setSelectedDay] = useState<daysOfWeek>();

  const config: IConfig = JSON.parse(useContext(Context));
  const { paystackKey, userId, markup } = config || {};

  const { data: profile } = profileApi.useGetPatientProfileQuery({ userId });
  const userProfile = profile?.data;

  const { email, first_name } = userProfile || {};

  const { price } = servicePlanOfInterest;

  const totalAmount = parseInt(price) * unitValue;

  const totalAmountPlusMarkup = markup
    ? totalAmount + (markup / 100) * totalAmount
    : totalAmount;

  const paystackConfig = {
    reference: new Date().getTime().toString(),
    email,
    amount: totalAmountPlusMarkup,
    publicKey: paystackKey || ''
  };
  const initializePayment = usePaystackPayment(paystackConfig);

  const handleOrderRequest = async (values: any) => {
    const date = values?.dob?.format('DD/MM/YYYY');
    const data = date ? { ...values, dob: date } : values;

    try {
      if (totalAmountPlusMarkup < 1000) {
        makeOrder(data);
      } else {
        await initializePayment((ref: any) => onSuccess(ref, data), onClose);
      }
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const makeOrder = async (data?: any) => {
    const successfulPurchaseTemplate = {
      business_name: providerProfile.business_name,
      number: 1,
      plan_name: selectedPlan?.name,
      provider_name: providerProfile.name,
      phone_number: providerProfile.phone,
      provider_email: providerProfile.email,
      email,
      first_name,
      preferred_date: data['preferred_date']
        ? data['preferred_date'].format('DD/MM/YYYY')
        : '',
      preferred_time: data['preferred_time']
        ? data['preferred_time'].format('h:mm a')
        : '',
      slug: providerProfile.slug || providerProfile.id
    };

    const { id: planId } = servicePlanOfInterest;
    const order = {
      plan: planId,
      user: userProfile?.id,
      number: 1,
      note: data.notes,
      metadata: {
        preferred_date: data['preferred_date']
          ? data['preferred_date'].format('DD/MM/YYYY')
          : '',
        preferred_time: data['preferred_time']
          ? data['preferred_time'].format('h:mm a')
          : '',
        source: 'integration',
        payment_status: 'paid',
        payment_method: 'pneumapay'
      }
    };
    setApplyLoading(true);
    try {
      const call = await http.post('/services/orders', order);

      // send email here
      await send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
        'pneumacare_3y8z4t8',
        successfulPurchaseTemplate
      );

      message.success('Your order was successfully placed.');
      setApplyLoading(false);
      setSelectedPlan(null);
    } catch (error: any) {
      console.log(error);
      message.error('An error occurred. Please try again.');
      setApplyLoading(false);
    }
  };

  const onSuccess = async (reference: any, values: any) => {
    makeOrder(values);
    requestForm.resetFields();
  };

  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log('closed');
  };

  const handleCancel = () => {
    setSelectedPlan(null);
    requestForm.resetFields();
  };

  const changeTitle = `${servicePlanOfInterest?.name}`;

  const disabledDays =
    providerProfile?.metadata?.pneumapage?.open_hours &&
    providerProfile?.metadata?.pneumapage?.open_hours === 'custom'
      ? Object.keys(
          providerProfile?.metadata?.pneumapage?.custom_hours!
        ).filter(
          (day) =>
            !providerProfile?.metadata?.pneumapage?.custom_hours[
              day as daysOfWeek
            ].isOpen
        )
      : [];

  // get open hour as number from openHour string
  const selectedDayOpenHour = Number(
    providerProfile?.metadata?.pneumapage?.custom_hours[
      selectedDay!
    ]?.open?.split(':')[0]
  );

  // get closing hour as number, and check for time of day to account for 24-hour time format.
  const selectedDayCloseHour =
    providerProfile?.metadata?.pneumapage?.custom_hours[
      selectedDay!
    ]?.close?.includes('pm')
      ? Number(
          providerProfile?.metadata?.pneumapage?.custom_hours[
            selectedDay!
          ]?.close?.split(':')[0]
        ) + 12
      : Number(
          providerProfile?.metadata?.pneumapage?.custom_hours[
            selectedDay!
          ]?.close?.split(':')[0]
        );

  let selectedDayHours: number[] = [];
  for (let i = selectedDayOpenHour; i <= selectedDayCloseHour; i++) {
    if (providerProfile?.metadata?.pneumapage?.open_hours === 'custom') {
      selectedDayHours.push(i);
    }
  }

  return (
    <div className="M-body">
      <ModalWrapper
        title={changeTitle}
        okText="Request"
        footer={null}
        className="dashboard-mode-modal"
        visible={true}
        onOk={handleOrderRequest}
        onCancel={handleCancel}
        centered
        width={450}
        closeIcon={
          <CloseCircleOutlined
            style={{ fontSize: '1.2rem', color: '#1a90ff' }}
          />
        }
        style={{ borderRadius: '80px !important' }}
      >
        <OrderPlanWrapper>
          <Form
            name="basicForm"
            form={requestForm}
            layout="vertical"
            onFinish={handleOrderRequest}
          >
            <>
              <Row gutter={24}>
                <Col span={12}>
                  <DatePicker
                    formItem={{
                      name: 'preferred_date',
                      label: 'Preferred date',
                      rules: [
                        {
                          required: true,
                          message: 'Please select preferred appointment date'
                        }
                      ]
                    }}
                    onChange={(e: any) => {
                      setIsDateSelected(true);
                      setSelectedDay(
                        moment(e).format('dddd').toLowerCase() as daysOfWeek
                      );

                      if (moment(e) > moment()) {
                        setIsTodaySelected(false);
                      } else {
                        setIsTodaySelected(true);
                      }
                    }}
                    disabledDate={(d: any) => {
                      // check if currentDate in moment format is less than yesterday & disable accordingly, also disabled `disabledDays` based on metadata information)
                      return (
                        !d ||
                        d.isBefore(
                          new Date().getTime() - 24 * 60 * 60 * 1000
                        ) ||
                        disabledDays.includes(
                          moment(d).format('dddd').toLowerCase()
                        )
                      );
                    }}
                    placeHolder="Preferred appointment date"
                    mode="normal"
                    inputReadOnly={true}
                    format="DD/MM/YYYY"
                    popupStyle={{
                      maxHeight: '400px !important'
                    }}
                  />
                </Col>

                {+price / 100 > 100 && (
                  <Col span={12}>
                    <TimePicker
                      formItem={{
                        name: 'preferred_time',
                        label: 'Preferred time'
                      }}
                      format="hh:mm"
                      mode="normal"
                      inputReadOnly={true}
                      disabled={!isDateSelected}
                      disabledHours={() =>
                        getDisabledHours(isTodaySelected, selectedDayHours)
                      }
                      disabledMinutes={(selectedHour: number) =>
                        getDisabledMinutes(selectedHour, isTodaySelected)
                      }
                      minuteStep={30}
                    />
                  </Col>
                )}

                <Col span={24}>
                  <TextArea
                    formItem={{
                      name: 'notes',
                      label: 'Additional notes/instructions'
                    }}
                    label=""
                    mode="normal"
                    maxLength={300}
                    autoSize={{ minRows: 4, maxRows: 4 }}
                  />
                </Col>
              </Row>
              <Row justify="end" style={{ marginTop: '20px' }}>
                <Button
                  type="secondary"
                  onClick={handleCancel}
                  style={{ marginRight: 10 }}
                >
                  Go back
                </Button>
                <Button htmlType="submit" type="primary">
                  {applyLoading
                    ? 'Processing...'
                    : `${
                        totalAmountPlusMarkup < 1000
                          ? 'Proceed'
                          : 'Proceed to pay'
                      }`}
                </Button>
              </Row>
            </>
          </Form>
        </OrderPlanWrapper>
      </ModalWrapper>
    </div>
  );
};

export default OrderPlanModal;
