import React, { useMemo } from 'react';
import { Space } from 'antd';

import { encounterConsultApi } from 'redux/queries/encounter';

// import { OverallDiv } from './styles';
import Typography from 'component/Typography';
import EmptyTabContent from './EmptyContent';
import treatment from 'assets/icons/treatments.svg';
import doctor from 'assets/icons/doctor.svg';
import SidePaneCard from './Card';

export type TreatmentProps = {
  data: any;
};

function Treatments(props: any) {
  const { data } = props;

  // setup connection with state for refetching - with memoized object arg to prevent unneccesarry rerenders
  encounterConsultApi.useOnRecommendationAdditionQuery(
    useMemo(() => ({ path: { consultId: data?.id } }), [data?.id]),
    { skip: !data?.id }
  );

  if (data?.status !== 'pending') {
    return (
      <SidePaneCard data={data}>
        {/* <OverallDiv completed={data?.treatment_plan_completed}> */}
        {data?.recommendations?.length ? (
          <Space direction="vertical">
            {data?.recommendations?.map((plan: any) => (
              <Space align="center">
                <img src={doctor} alt="" height={11} />
                <Typography>{plan}</Typography>
              </Space>
            ))}
          </Space>
        ) : (
          <EmptyTabContent
            title="You do not have any recommendation plans."
            description="Your recommendation plans will appear here."
            icon={treatment}
          />
        )}
        {/* <Checkbox onClick={toggleCheckbox} checked={treatment.completed}>
            {treatment.completed ? 'Completed' : 'Mark as complete'}
          </Checkbox> */}
        {/* </OverallDiv> */}
      </SidePaneCard>
    );
  }

  return null;
}

export default Treatments;
