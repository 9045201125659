import React from "react";
import { Spin } from 'antd';
import { LoaderWrapper } from './styles';

const Loader = (props: any) => {
  const { height } = props;
  return (
    <LoaderWrapper height={height}>
      <Spin />
    </LoaderWrapper>
  );
};

export default Loader;
