import moment from 'moment';

/**
 * Provides currency formatting for naira amounts.
 * @param price number
 * @returns string
 */
export const priceFormatter = (price: number) => {
  return `₦${new Intl.NumberFormat('en-NG', {
    maximumFractionDigits: 2
  }).format(price)}`;
};

/**
 * Returns an array containing disabled hours based on if todays date was selected in the `DatePicker`. If `today` was selected, return an array combining both outside work hours and below current hour hours. Else, for non `today` future dates return just outside work hours. Work hours are gotten from the metadata's `pneumapage` object
 * @param isTodaySelected boolean
 * @param selectedDaysHours number[]
 * @returns number[]
 */
export const getDisabledHours = (
  isTodaySelected: boolean,
  selectedDaysHours?: number[]
) => {
  const allHours: number[] = [];
  for (let i = 0; i <= 24; i++) {
    allHours.push(i);
  }

  if (!isTodaySelected) {
    return allHours.filter((hour) =>
      selectedDaysHours?.length ? !selectedDaysHours?.includes(hour) : !hour
    );
  }

  let disabledHours = allHours.filter((hour) =>
    selectedDaysHours?.length ? !selectedDaysHours?.includes(hour) : !hour
  );

  for (let i = 0; i < moment().hour(); i++) {
    if (
      selectedDaysHours?.length
        ? selectedDaysHours?.includes(i)
        : allHours.includes(i)
    ) {
      disabledHours.push(i);
    }
  }
  return disabledHours;
};

/**
 * Returns an array containing disabled minutes. If `isTodaySelected` is true, and `selectedHour` is outside of work hours or -1 (no selection), then return all numbers from 1 to 60. Else only disable minutes less than current time.
 * @param selectedHour number
 * @param isTodaySelected boolean
 * @returns number[]
 */
export const getDisabledMinutes = (
  selectedHour: number,
  isTodaySelected: boolean
) => {
  const minutes = [];
  if (
    isTodaySelected &&
    (selectedHour > 19 || selectedHour < 7 || selectedHour === -1)
  ) {
    for (let i = 0; i < 60; i++) {
      minutes.push(i);
    }
  }

  if (selectedHour === moment().hour()) {
    for (let i = 0; i < moment().minute(); i++) {
      minutes.push(i);
    }
  }
  return minutes;
};
