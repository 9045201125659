import React from 'react';
import Typography, { TypographyProps } from './Typography';
import currencyjs from 'currency.js';
import { CurrencyEnum } from 'constants/global';

function CurrencyTypography(
  props: {
    currency: keyof typeof CurrencyEnum;
    children: any;
  } & TypographyProps
) {
  const { children, currency, ...rest } = props;
  const _currency =
    CurrencyEnum[(currency || 'NG') as keyof typeof CurrencyEnum] ||
    CurrencyEnum.NG;

  return (
    <Typography {...rest}>
      {currencyjs(children, { symbol: _currency.symbol }).format()}
    </Typography>
  );
}

CurrencyTypography.defaultProps = {
  currency: 'NG'
};

export default CurrencyTypography;
