import React from 'react';
import SidePane from 'component/SidePane';
import Treatment from 'component/SidePane/Treatment';
import Prescriptions from 'component/SidePane/Prescription';
import Labs from 'component/SidePane/Lab';
// import Uploads from 'component/SidePane/Upload';
import EmptyConsult from './EmptyConsult';

function SideTab(props: any) {
  const { consult } = props;

  return (
    <SidePane>
      <SidePane.TabPane tab="Prescriptions" key="1">
        {consult ? <Prescriptions data={consult} /> : <EmptyConsult />}
      </SidePane.TabPane>
      <SidePane.TabPane tab="Lab Investigations" key="2">
        {consult ? <Labs data={consult} /> : <EmptyConsult />}
      </SidePane.TabPane>
      <SidePane.TabPane tab="Recommendations" key="3">
        {consult ? <Treatment data={consult} /> : <EmptyConsult />}
      </SidePane.TabPane>
      {/* <SidePane.TabPane tab="Uploads" key="4">
        {consult ? <Uploads data={consult} /> : <EmptyConsult />}
      </SidePane.TabPane> */}
    </SidePane>
  );
}

export default SideTab;
