import styled from 'styled-components';
import { Form as AntdForm } from 'antd';

const AddUserModalWrapper = styled.div`
  padding: 20px 40px !important;
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .ant-form {
    margin-top: -60px;
    width: 100%;

    .login-wrapper {
      height: calc(100% - 50px);
      display: flex;
      gap: 20px;
      justify-content: center;

      .left-pane {
        & > div.ant-col {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;

          img {
            margin-bottom: 20px;
            height: 80px;

            @media (min-width: 768px) {
              height: auto;
            }
          }

          p {
            max-width: 310px;
          }
        }

        @media (min-width: 768px) {
          margin-top: -40px;
          margin-bottom: -30px;
        }
      }

      .right-pane {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: flex-end;
        width: 100%;

        small {
          color: #0f9af0;
          cursor: pointer;
          font-size: 12px;
          display: flex;
          justify-content: flex-end;
          margin-top: -20px;

          &:hover {
            text-decoration: underline;
          }
        }

        @media (min-width: 600px) {
          width: 60%;
        }
      }
    }

    .btn-wrapper {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      div[type='primary'] {
        button {
          height: auto;
          padding: 12px 28px;
        }
      }

      @media (min-width: 768px) {
        justify-content: center;
      }
    }

    .register-prompt {
      margin-top: 24px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media (min-width: 768px) {
        flex-direction: row;
        gap: 6px;
      }

      span {
        display: inline-block;
        background-color: #0f9af052;
        border-radius: 4px;
        color: #08b;
        padding: 4px 6px;
        cursor: pointer;

        &:hover {
          background-color: #0f9af022;
        }
      }
    }
  }

  .header {
    margin-bottom: 15px;
    h2 {
      margin-bottom: 0px;
    }
    span {
      color: #0f9af0;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  .otp-pane {
    text-align: center;
    label {
      font-size: 13px;
    }
    input:not([value='']) {
      border: 2px solid #0f9af0 !important;
    }
  }
  .redirectToLogin {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

export const FormItem = styled(AntdForm.Item)`
  .ant-form-item-label {
    padding: 0;
    margin-bottom: 3px;
  }
`;

export { AddUserModalWrapper };
