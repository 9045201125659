import styled from 'styled-components';
import Card from 'component/Card';

const DashboardWrapper = styled('div')`
  width: 100%;
  color: #002e46;
  padding: 20px 25px;

  .ant-row {
    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }
  }
  .col1 {
    @media only screen and (max-width: 600px) {
      order: 1;
      width: 100%;
    }
  }
  .col2 {
    @media only screen and (max-width: 600px) {
      margin-bottom: 20px;
      align-self: flex-start;
    }
  }

  .details {
    display: inline-block;
    margin-top: 3px;
    opacity: 0.75;
  }

  h1 {
    /* font-family: 'DM Sans'; */
    font-weight: 500;
    font-size: 1.5rem;
    text-align: left;
    text-transform: capitalize;
  }
  a.insta:hover {
    color: #fb3958;
  }
  svg {
    font-size: 1.1rem;
    margin-right: 13px;
  }
  .ant-avatar {
    /* border: 10px solid red !important; */
  }

  .price--amount {
    font-weight: bold;
  }
`;

const RequestsWrapper = styled(Card)`
  width: 100%;
  color: #002e46 !important;
  padding: 0 15px;
  margin-top: 10px;
  @media only screen and (max-width: 500px) {
    padding: 0;
  }
  .ant-pagination {
    text-align: right;
    margin-top: 20px;
  }
`;

const PlanWrapper = styled(Card)`
  width: 100%;
  color: #002e46;
  margin: 20px 0;
  padding: 0 15px 0 !important;
  box-shadow: #002e461a 1px 1px 3px !important;
  h3 {
    text-transform: capitalize;
    font-size: 20px;
  }
  .ant-card-body {
    padding: 20px 0;
  }
  .ant-col:last-of-type {
    display: flex;
    justify-content: space-between;
    span {
      text-transform: capitalize;
      align-self: center;
    }
  }
`;

export { DashboardWrapper, RequestsWrapper, PlanWrapper };
