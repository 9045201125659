import styled, { css } from 'styled-components';

interface ButtonProps {
  type: string;
}

const styles = (key: string) => {
  return key === 'primary'
    ? css`
        background: #0078e2;
        color: #fff;
        border-radius: 4px;
        font-size: 13px;
      `
    : key === 'secondary'
    ? css`
        height: 32px;
        border-radius: 3px;
        background: #f2f8fe;
        font-size: 13px;
        text-align: left;
        color: #0078e2;
      `
    : key === 'tertiary'
    ? css`
        height: 32px;
        border: 1px solid #0078e2;
        border-radius: 4px;
        /* width: 80px; */
        background: #fff;
        font-size: 13px;
        text-align: center;
        color: #0078e2;
      `
    : null;
};

const ButtonWrapper = styled.div<ButtonProps>`
  display: inline-block;
  button.ant-btn {
    font-family: 'DM Sans';
    font-weight: 400;
    border: none;
    line-height: 9px;
    text-align: center;
    ${(props) => (props.type ? styles(props.type) : '')};
  }
`;

const FullButtonWrapper = styled(ButtonWrapper)`
  width: 100% button {
    width: 100%;
  }
`;

export { ButtonWrapper, FullButtonWrapper };
