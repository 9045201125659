import React, { useContext, useEffect, useState } from 'react';
import { CustomCollapsePanel } from './styles';
import { Container } from '../styles';
import { Collapse, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import AbdominalSwellingSvg from 'assets/symptoms/symptom-Adbominal-Swelling.svg';
import AnalProtrusionSvg from 'assets/symptoms/symptom-Anal-Protrusion.svg';
import BloodInUrineSvg from 'assets/symptoms/symptom-Blood-in-Urine.svg';
import ChestPainSvg from 'assets/symptoms/symptom-Chest-Pain.svg';
import CoughSvg from 'assets/symptoms/symptom-Cough.svg';
import DiabetesSvg from 'assets/symptoms/symptom-Diabetes.svg';
import DiarrhoeaSvg from 'assets/symptoms/symptom-Diarrhoea.svg';
import EarPainSvg from 'assets/symptoms/symptom-Ear-Pain.svg';
import EyeDischargeSvg from 'assets/symptoms/symptom-Eye-Discharge.svg';
import EyePainSvg from 'assets/symptoms/symptom-Eye-Pain.svg';
import FeverSvg from 'assets/symptoms/symptom-Fever.svg';
import GenitalSoresAndUlcersSvg from 'assets/symptoms/symptom-Genital-Sores-&-Ulcers.svg';
import HeadacheSvg from 'assets/symptoms/symptom-Headache.svg';
import HeartburnSvg from 'assets/symptoms/symptom-Heartburn.svg';
import HypertensionSvg from 'assets/symptoms/symptom-Hypertension.svg';
import IrregularMenstrualCycleSvg from 'assets/symptoms/symptom-Irregular-Menstrual-Cycle.svg';
import ItchyEyesSvg from 'assets/symptoms/symptom-Itchy-Eyes.svg';
import ItchySkinSvg from 'assets/symptoms/symptom-Itchy-Skin.svg';
import ItchyVaginaSvg from 'assets/symptoms/symptom-Itchy-Vagina.svg';
import LowerAbdominalPainSvg from 'assets/symptoms/symptom-Lower-Abdominal-Pain.svg';
import LowerBackPainSvg from 'assets/symptoms/symptom-Lower-Back-Pain.svg';
import NeckPainSvg from 'assets/symptoms/symptom-neck-pain.svg';
import PainfulUrinationSvg from 'assets/symptoms/symptom-Painful-Urination.svg';
import PenileDischargeSvg from 'assets/symptoms/symptom-Penile-Discharge.svg';
import PrematureEjaculationSvg from 'assets/symptoms/symptom-Premature-Ejaculation.svg';
import RunningNoseSvg from 'assets/symptoms/symptom-Runny-Nose.svg';
import ScrotalPainSvg from 'assets/symptoms/symptom-Scrotal-Pain.svg';
import SneezingSvg from 'assets/symptoms/symptom-Sneezing.svg';
import SoreThroatSvg from 'assets/symptoms/symptom-Sore-Throat.svg';
import UnusualVaginalBleedingSvg from 'assets/symptoms/symptom-Unusual-Vaginal-Bleeding.svg';
import UpperAbdominalPainSvg from 'assets/symptoms/symptom-Upper-Abdominal-Pain.svg';
import VaginalDischargeSvg from 'assets/symptoms/symptom-Vaginal-Discharge.svg';
import VomitingSvg from 'assets/symptoms/symptom-Vomiting.svg';
import WeakErectionSvg from 'assets/symptoms/symptom-Weak-Erection.svg';

import Card from 'common/Card';
import { Link, generatePath } from 'react-router-dom';
import Input from 'common/Input';
import Typography from 'component/Typography';
import { RouteEnum } from 'constants/routes';
import ScreenLayout from 'layouts/ScreenLayout';
import { AppStartContext } from 'context/appStart';
import Button from 'common/Button';

function Complaints(props: any) {
  const { history } = props;

  const [searchTerm, setSearchTerm] = useState('');
  const appStart = useContext(AppStartContext);
  useEffect(() => {
    if (appStart?.updateAppStart) {
      appStart?.updateAppStart(false);
    }
  }, [appStart]);

  function generateStartPath(id: string) {
    return generatePath(RouteEnum.ENCOUNTER_START_QUESTIONS, {
      id
    });
  }

  // reduce grouped array of symptoms into just the symptoms
  const symptomArr = COMPLAINTS.reduce((acc: any, element) => {
    acc.push(...element.symptoms);
    return acc;
  }, []);

  // filter symptoms array based on searchTerm
  const filteredComplaints = symptomArr.filter((symptom: any) =>
    symptom.name.toLowerCase().includes(searchTerm.toLowerCase().trim())
  );

  return (
    <ScreenLayout>
      <Container>
        <Input
          placeholder="Search for symptoms to start a consultation"
          suffix={<SearchOutlined />}
          style={{ marginBottom: 32 }}
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          size="large"
        />
        <Typography variant="h6" gutterBottom>
          Choose a category to start a consultation
        </Typography>
        {!searchTerm ? (
          COMPLAINTS.map(({ name, symptoms }) => (
            <Card style={{ marginBottom: 16 }} bodyStyle={{ padding: 0 }}>
              <Collapse bordered={false} expandIconPosition="right" ghost>
                <CustomCollapsePanel
                  header={<Typography>{name}</Typography>}
                  key={name}
                >
                  <div>
                    <Row gutter={[16, 16]}>
                      {symptoms.map(({ color, icon: Icon, name, id }) => (
                        <Col xs={24} sm={12} md={6}>
                          <Card
                            hoverable
                            onClick={() => history.push(generateStartPath(id))}
                            style={{ cursor: 'pointer', borderRadius: 12 }}
                            bodyStyle={{
                              padding: 8,
                              height: 45,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                            cover={<img alt="example" src={Icon} />}
                          >
                            <Typography
                              style={{
                                textAlign: 'center',
                                marginBottom: 0,
                                fontSize: 14
                              }}
                            >
                              {name}
                            </Typography>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </CustomCollapsePanel>
              </Collapse>
            </Card>
          ))
        ) : (
          <Card style={{ marginBottom: 16 }} bodyStyle={{ padding: 16 }}>
            <div>
              <Row gutter={[16, 16]}>
                {filteredComplaints.length ? (
                  filteredComplaints.map(
                    ({
                      icon: Icon,
                      name,
                      id
                    }: {
                      icon: any;
                      name: string;
                      id: string;
                    }) => (
                      <Col xs={24} sm={12} md={6}>
                        <Card
                          hoverable
                          onClick={() => history.push(generateStartPath(id))}
                          style={{ cursor: 'pointer', borderRadius: 12 }}
                          bodyStyle={{
                            padding: 8,
                            height: 45,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                          cover={<img alt="example" src={Icon} />}
                        >
                          <Typography
                            style={{
                              textAlign: 'center',
                              marginBottom: 0,
                              fontSize: 14
                            }}
                          >
                            {name}
                          </Typography>
                        </Card>
                      </Col>
                    )
                  )
                ) : (
                  <Typography>
                    No results found for{' '}
                    <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                      {searchTerm}
                    </span>
                  </Typography>
                )}
              </Row>
            </div>
          </Card>
        )}

        <Typography color="textSecondary">
          Can't find what you are looking for?
        </Typography>
        <Link to={generateStartPath('k4o2il9d1kfp3jl')}>
          <Button type="primary" style={{ marginTop: '0.6em' }}>
            Start a consultation
          </Button>
        </Link>
      </Container>
    </ScreenLayout>
  );
}

export default Complaints;

export const COMPLAINTS = [
  {
    name: 'Common Symptoms & Allergies',
    symptoms: [
      {
        id: '0j96s7gk35xnoas',
        name: 'Headache and migraines',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: HeadacheSvg
      },
      {
        id: 'r75vhmd8bz26a50',
        name: 'Fever',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: FeverSvg
      },
      {
        id: '3etpvlsh1ccoo7r',
        name: 'Runny Nose',
        color: 'rgba(134, 57, 201, 0.1)',
        icon: RunningNoseSvg
      },
      {
        id: 'gkb9zilb0wm6hxi',
        name: 'Sore Throat',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: SoreThroatSvg
      },
      {
        id: 'ktgqw7og8v4zep4',
        name: 'Cough',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: CoughSvg
      },
      {
        id: 'gifxisnj5lclptu',
        name: 'Chest Pain',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: ChestPainSvg
      },
      {
        id: 'k0k1loiomyxsfpa',
        name: 'Sneezing',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: SneezingSvg
      },
      {
        id: 'zenqvum91xdlbiz',
        name: 'Itchy Eyes',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: ItchyEyesSvg
      },
      {
        id: 'l7prizoxheskq3l',
        name: 'Itchy Skin',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: ItchySkinSvg
      },
      {
        id: 's015tqeazb6tisy',
        name: 'Unusual Eye Discharge',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: EyeDischargeSvg
      },
      {
        id: 'ud38hcj56rad8fe',
        name: 'Eye Pain',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: EyePainSvg
      },
      {
        id: '9o4kq9rw8zh86j8',
        name: 'Ear Pain',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: EarPainSvg
      },
      {
        id: '3isj52sfcdbhdi0',
        name: 'Unusual discharge from the ear(s)',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: EarPainSvg
      },
      {
        id: 'q7ouuu3l0pp2215',
        name: 'Neck pain',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: NeckPainSvg
      },
      {
        id: '5gwzxhvvaons4tw',
        name: 'Upper back pain',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: NeckPainSvg
      },
      {
        id: '4jc9e7ixjazrtbc',
        name: 'Lower back pain',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: LowerBackPainSvg
      },
      {
        id: 'a5v03h400jzqd5r',
        name: 'Bloody urination',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: BloodInUrineSvg
      }
    ]
  },
  {
    name: "Women's Reproductive Health",
    symptoms: [
      {
        id: 'r1lib258hxs6g3v',
        name: 'Unusual Vaginal Discharge',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: VaginalDischargeSvg
      },
      {
        id: 'lytf2dyjpxdec97',
        name: 'Itchy Vaginal',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: ItchyVaginaSvg
      },
      {
        id: '1j33m23m5glssiq',
        name: 'Unusual Vaginal Bleeding',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: UnusualVaginalBleedingSvg
      },
      {
        id: 'tha124zg6gnd1nv',
        name: 'Genital Sores & Ulcers',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: GenitalSoresAndUlcersSvg
      },
      {
        id: 'ealcjfbdi3mvqdc',
        name: 'Irregular Menstrual Cycle',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: IrregularMenstrualCycleSvg
      },
      {
        id: 'o0vuykzgouhetji',
        name: 'Painful Urination (Women)',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: PainfulUrinationSvg
      }
    ]
  },
  {
    name: "Men's Reproductive Health",
    symptoms: [
      {
        id: '82ugb3q4j14w4c2',
        name: 'Unusual Penile Discharge',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: PenileDischargeSvg
      },
      {
        id: 'wszl7c77i12edkm',
        name: 'Painful Urination (Men)',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: PenileDischargeSvg
      },
      {
        id: 'tmpdahp4rzw5ob4',
        name: 'Scrotal Pain',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: ScrotalPainSvg
      },
      {
        id: 'cfivqp5q0yxrdn9',
        name: 'Premature Ejaculation',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: PrematureEjaculationSvg
      },
      {
        id: '6hdwdg63c7gzfaw',
        name: 'Weak Erection',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: WeakErectionSvg
      }
    ]
  },
  {
    name: 'Gastrointestinal (Acid Reflux, Gastritis, Peptic Ulcer and Digestive system)',
    symptoms: [
      {
        id: 'wdnytw8hxwxv664',
        name: 'Heartburn',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: HeartburnSvg
      },
      {
        id: 'gp4si4kb45dqgek',
        name: 'Lower Abdominal Pain',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: LowerAbdominalPainSvg
      },
      {
        id: '1qizps6wgdbwcyb',
        name: 'Upper Abdominal Pain',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: LowerAbdominalPainSvg
      },
      {
        id: 'pq47r2jw8gw2fv9',
        name: 'Vomiting',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: VomitingSvg
      },
      {
        id: 'p5cuoj8m9i8kbnb',
        name: 'Diarrhoea',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: DiarrhoeaSvg
      },
      {
        id: 'st71pu5lzdjktvo',
        name: 'Abdominal Swelling',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: AbdominalSwellingSvg
      },
      {
        id: 'l7iyvmb21wt2ffg',
        name: 'Abdominal (Stomach) Pain',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: AbdominalSwellingSvg
      },
      {
        id: 'n7rjpiesx7id4nw',
        name: 'Anal Protrusion',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: AnalProtrusionSvg
      }
    ]
  },
  {
    name: 'Chronic Conditions',
    symptoms: [
      {
        id: 'vx5t51do4bkydpf',
        name: 'Diabetes (Care Support)',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: DiabetesSvg
      },
      {
        id: '91j8pimqleye2it',
        name: 'Diabetes Mellitus Check',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: DiabetesSvg
      },
      {
        id: 'gek655xl2krnnz2',
        name: 'Hypertension',
        color: 'rgba(117, 132, 242, 0.1)',
        icon: HypertensionSvg
      }
    ]
  }
];
