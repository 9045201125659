import React from 'react';
import { Card as AntdCard, CardProps as AntdCardProps } from 'antd';
import styled from 'styled-components';

export type CardProps = {} & AntdCardProps;

const Card = styled(function Card(props: CardProps) {
  return <AntdCard {...props} />;
})(({ theme }) => ({
  borderRadius: '5px',
  overflow: 'hidden',
  boxShadow: theme.shadows[2]
}));

Card.defaultProps = {
  // bordered: false
};

export const CardMeta = AntdCard.Meta;

export default Card;
