import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import App from './App';
import { IConfig } from './config/interfaces';
import theme from './constants/theme';
import { Context } from './context/context';
import './index.css';
import store from './redux/store';
import * as serviceWorker from './registerServiceWorker';
import reportWebVitals from './reportWebVitals';

window.addEventListener('DOMContentLoaded', (event) => {
  window.parent.postMessage(JSON.stringify({ action: 'init' }), '*');
  window.removeEventListener('DOMContentLoaded', () => null);
});

window.addEventListener('message', (event) => {
  event.preventDefault();
  if (!event.data || typeof event.data !== 'string') return;
  const config: IConfig = JSON.parse(event.data);
  const oldConfig: any = JSON.parse(
    window.localStorage.getItem('pneumaConfig') || 'null'
  );
  const providerValue =
    Object.keys(config).includes('userId') ||
    Object.keys(config).includes('firebaseConfig')
      ? config
      : oldConfig;

  return render(
    <Context.Provider value={JSON.stringify(providerValue)}>
      <React.StrictMode>
        <BrowserRouter>
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </Provider>
        </BrowserRouter>
      </React.StrictMode>
    </Context.Provider>,
    document.body
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.register();
