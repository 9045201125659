import React from 'react';
import styled from 'styled-components';
import { Collapse } from 'antd';

export const Container = styled.div``;

export const CustomCollapsePanel = styled(Collapse.Panel)`
  background-color: #fff;
  & > div {
    padding: 16px;
  }
`;

export const CardCover = styled.div`
  background-color: ${(props) => props.color};
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
