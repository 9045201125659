import React from 'react';
import {} from 'antd';
import { ListContainer } from './styles';
import ListItem from './ListItem';
import { FixedSizeList } from 'react-window';
import { encounterConsultApi } from 'redux/queries/encounter';
import useBoundingClientRect from 'hooks/useBoundingClientRect';

import { useAppSelector } from 'redux/store';

function List() {
  const [ref, rect] = useBoundingClientRect();

  const { user } = useAppSelector((state) => state.global);
  const { data } = encounterConsultApi.useGetEncounterConsultsQuery({
    userId: user.id
  });

  const consults = data.data;

  return data ? (
    <ListContainer ref={ref as any}>
      <FixedSizeList
        height={rect?.height || 100}
        itemCount={consults.length}
        itemSize={56}
        itemData={consults}
        width="100%"
      >
        {Row as any}
      </FixedSizeList>
    </ListContainer>
  ) : null;
}

export default List;

function Row(props: any) {
  const { index, style, data } = props;
  return (
    <div style={{ ...style, padding: '2px 0' }} key={index}>
      <ListItem data={data[index]} />
    </div>
  );
}
