import styled from 'styled-components';
import { Modal } from "antd";

const ModalWrapper = styled(Modal)`
   .ant-modal-content {
       border-radius: 8px;
   }
   .ant-modal-title {
    padding-right: 20px;
   }
`;

const OrderPlanWrapper = styled.div`
   .ant-row .ant-form-item {
       margin-bottom: 10px;
   }
   .check-box{
    font-weight: 500;
    font-size: 13px;
    text-align: left;
    color: #002e46;
    padding: 0px;
    margin: 10px 0;
    span.ant-checkbox-inner {
    width: 18px;
    height: 18px;
}
.picker-drop {
    border:30px solid green; 
}
}
`;

export { OrderPlanWrapper, ModalWrapper }