import React, { forwardRef, ComponentPropsWithoutRef } from 'react';
import { Input as AntdInput, InputProps as AntdInputProps } from 'antd';
import styled from 'styled-components';
import clsx from 'clsx';

export type InputProps = { underline?: boolean } & AntdInputProps;

const Input = styled(
  forwardRef<AntdInput, InputProps>(function Input(props, ref) {
    const { underline, className, ...rest } = props;
    return (
      <AntdInput
        ref={ref}
        className={clsx(className, underline && 'ant-input-underline')}
        {...rest}
      />
    );
  })
)`
  &.ant-input-underline,
  .ant-input-underline .ant-input {
    border-top: none;
    border-right: none;
    border-left: none;
    box-shadow: none;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const InputGroup = AntdInput.Group;
export const InputPassword = styled(
  forwardRef<
    AntdInput,
    { underline?: boolean } & ComponentPropsWithoutRef<
      typeof AntdInput.Password
    >
  >(function InputPassword(props, ref) {
    const { underline, className, ...rest } = props;
    return (
      <AntdInput.Password
        ref={ref}
        className={clsx(className, underline && 'ant-input-underline')}
        {...rest}
      />
    );
  })
)`
  &.ant-input-underline,
  .ant-input-underline .ant-input {
    border-top: none;
    border-right: none;
    border-left: none;
    box-shadow: none;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const InputTextArea = styled(
  forwardRef<
    AntdInput,
    { underline?: boolean } & ComponentPropsWithoutRef<
      typeof AntdInput.TextArea
    >
  >(function InputPassword(props, ref) {
    const { underline, className, ...rest } = props;
    return (
      <AntdInput.TextArea
        ref={ref}
        className={clsx(className, underline && 'ant-input-underline')}
        {...rest}
      />
    );
  })
)`
  resize: 'none';
  &.ant-input-underline,
  .ant-input-underline .ant-input {
    border-top: none;
    border-right: none;
    border-left: none;
    box-shadow: none;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export default Input;
