import React, { useContext, useState } from 'react';
import { Row, Col, message } from 'antd';
import { LabSidePaneColumnContainer } from './styles';
import Card from 'common/Card';
import Button from 'common/Button';
import Typography from 'component/Typography';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import MyHealthLabSidePaneOrderTestInvestigationSelection from './MyHealthLabSidePaneOrderTestInvestigationSelection';
import MyHealthLabSidePaneOrderTestLabSelection from './MyHealthLabSidePaneOrderTestLabSelection';
import MyHealthLabSidePaneOrderTestLabTestSelection from './MyHealthLabSidePaneOrderTestLabTestSelection';
import { useFormik } from 'formik';
import laboratoryApi from 'redux/queries/laboratory';
import useAuthUser from 'hooks/useAuthUser';
import useLoadingSpin from 'hooks/useLoadingSpin';
import { useTheme } from 'styled-components';
import { usePaystackPayment } from 'react-paystack';
import { IConfig } from 'config/interfaces';
import { Context } from 'context/context';
import { profileApi } from 'redux/queries/profile';
import emailjs from 'emailjs-com';

function MyHealthLabSidePaneOrderTest(props: any) {
  const { setActiveTab } = props;
  const { toggleLoadingSpin } = useLoadingSpin();

  const config: IConfig = JSON.parse(useContext(Context));
  const { paystackKey, markup, userId, businessName } = config || {};

  const [step, setStep] = useState(0);
  const user = useAuthUser();

  const [orderLaboratoryTestMutation] =
    laboratoryApi.useOrderLaboratoryTestMutation();

  const { data } = profileApi.useGetPatientProfileQuery({ userId });
  const userProfile = data?.data;

  const { email, firstName } = userProfile || {};

  const formik = useFormik({
    initialValues: {
      investigationSlice: 0,
      investigations: {},
      labInvestigations: {},
      laboratory: {}
    },
    onSubmit: async (values, helper) => {
      toggleLoadingSpin(true);
      try {
        await initializePayment(
          (ref: any) => onSuccess(ref, values, helper),
          onClose
        );
      } catch (error: any) {
        message.error(error.message);
      }
      toggleLoadingSpin(false);
    }
  });

  const laboratoryInvestigations = formik.values?.labInvestigations;
  let totalAmount = 0;
  const allInvestigations: any[] = Object.values(laboratoryInvestigations);
  allInvestigations.forEach((items: any) => {
    Object.values(items).forEach((item: any) => {
      totalAmount += item.price;
    });
  });

  const totalAmountPlusMarkup = markup
    ? totalAmount + (markup / 100) * totalAmount
    : totalAmount;

  const sendEmail = () => {
    const investigationsList = (formik.values.investigations as any[]).map((item) => item.name);
    const laboratoryObject: any = Object.values(formik.values?.laboratory || {})[0];
    const { name: lab_name  } = laboratoryObject || {};

    var templateParams = {
      to_name: firstName,
      lab_name,
      investigations: investigationsList,
      business_name: businessName,
      to_email: email,
    };

    const YOUR_SERVICE_ID = 'pneumacare_mailer';
    const YOUR_TEMPLATE_ID = 'template_tuhfyiq';
    const YOUR_USER_ID = 'user_NIJR0GiUcVHkEjwQ8jqrb';

    emailjs
      .send(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, templateParams, YOUR_USER_ID)
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const paystackConfig = {
    reference: new Date().getTime().toString(),
    email,
    amount: totalAmountPlusMarkup,
    publicKey: paystackKey || ''
  };
  const initializePayment = usePaystackPayment(paystackConfig);

  const onSuccess = async (reference: any, values: any, helper: any) => {
    const data = await orderLaboratoryTestMutation({
      data: Object.keys(values.labInvestigations).map((laboratory) => ({
        request_mode: 'in-person',
        laboratory,
        user: user.patientId,
        investigations: Object.keys(
          (values.labInvestigations as any)[laboratory]
        ).map((item) => parseInt(item))
      }))
    }).unwrap();
    sendEmail();
    message.success(data.message);
    helper.resetForm();
    setStep(0);
    setActiveTab('2');
  };

  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log('closed');
  };

  const STEPS = [
    { item: <OrderTestChoices formik={formik} handleNext={handleNext} /> },
    {
      disabled: !Object.keys(formik.values.investigations).length,
      item: (
        <MyHealthLabSidePaneOrderTestInvestigationSelection formik={formik} />
      ),
      destroy: () => {
        formik.setFieldValue('investigations', {});
      }
    },
    {
      disabled: !Object.keys(formik.values.laboratory).length,
      item: <MyHealthLabSidePaneOrderTestLabSelection formik={formik} />,
      destroy: () => {
        formik.setFieldValue('laboratory', {});
      }
    },
    {
      disabled: !Object.keys(
        Object.values<any>(formik.values.labInvestigations)[0] || {}
      ).length,
      item: <MyHealthLabSidePaneOrderTestLabTestSelection formik={formik} />,
      destroy: () => {
        formik.setFieldValue('labInvestigations', {});
      }
    }
  ];

  const { item, disabled, destroy } = STEPS[step];

  function handleNext() {
    setStep((s) => s + 1);
  }

  function handlePrevious() {
    destroy?.();
    setStep((s) => s - 1);
  }

  return (
    <LabSidePaneColumnContainer>
      <div className="flex-column-content">{item}</div>
      {step ? (
        <Row justify="space-between" style={{ marginTop: 16 }}>
          <Col>
            <Button type="primary" onClick={handlePrevious}>
              <LeftOutlined />
            </Button>
          </Col>
          <Col>
            {step === STEPS.length - 1 ? (
              <Button
                type="primary"
                disabled={disabled}
                onClick={() => formik.handleSubmit()}
              >
                Request investigations
              </Button>
            ) : (
              <Button type="primary" disabled={disabled} onClick={handleNext}>
                Proceed
                <RightOutlined />
              </Button>
            )}
          </Col>
        </Row>
      ) : null}
    </LabSidePaneColumnContainer>
  );
}

export default MyHealthLabSidePaneOrderTest;

function OrderTestChoices(props: any) {
  const { formik, handleNext } = props;
  const theme = useTheme() as any;
  return (
    <Row gutter={[0, 16]}>
      {CHOICES.map((choice, index) => (
        <Col span={24} key={choice.name}>
          <Card
            onClick={() => {
              formik.setFieldValue('investigationSlice', index);
              handleNext();
            }}
            style={{ cursor: 'pointer' }}
            bodyStyle={{ padding: 16 }}
          >
            <Row wrap={false} gutter={8}>
              <Col flex={1}>
                <Typography weight="bold">{choice.name}</Typography>
              </Col>
              <Col>
                <RightOutlined style={{ color: theme.palette.primary.main }} />
              </Col>
            </Row>
          </Card>
        </Col>
      ))}
    </Row>
  );
}

const CHOICES = [
  { name: 'Start from an existing consult' },
  { name: 'Find investigations' }
];
