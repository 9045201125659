import React, { useContext, useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import CancelIcon from 'assets/icons/close.svg';
import { Route, Switch, useHistory } from 'react-router';
import { Row, Col, message } from 'antd';
import Consults from 'screens/Encounters/containers/Consults';
import Complaints from 'screens/Encounters/containers/Start/Complaints';
import ConsultIcon from 'assets/menu/consults.png';
import LabIcon from 'assets/menu/lab.png';
import './App.css';
import { LeftOutlined } from '@ant-design/icons';
import Questions from 'screens/Encounters/containers/Start/Questions';
import Button from 'common/Button';
import { IConfig } from 'config/interfaces';
import { Context } from 'context/context';
import { AppStartContext } from 'context/appStart';
import { FirebaseContext } from './context/firebase';
import MyHealthLabSidePane from 'screens/my-health/MyHealthLabSidePane';
import { WidgetsMenuContainer } from 'styles';
import _ from 'lodash';
import WarningIcon from 'assets/icons/warning-icon.svg';
import ServicePlans from 'screens/ServicePlans';
import OnboardUser from 'component/OnboardUser';

import firebase from 'firebase/app';
import 'firebase/auth/';
import 'firebase/firestore';
import 'firebase/storage';
import { profileApi } from 'redux/queries/profile';

import Swal from 'sweetalert2';
import { init } from 'emailjs-com';

// export const storage = firebase.storage();
function App() {
  // init emailjs
  init(process.env.REACT_APP_EMAILJS_USER_ID as string);

  const [fetchPatientProfile, { error: patientProfileError }] =
    profileApi.useLazyGetPatientProfileQuery();

  const history = useHistory();
  const config: IConfig = JSON.parse(useContext(Context));
  const {
    userId,
    service,
    firebaseConfig,
    brandLogo,
    firebaseUsersCollectionName
  } = config || {};
  const [isAppStart, setIsAppStart] = useState(true);

  // initialize firebase app
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }

  // update localStorage with 'pneumaConfig'
  useEffect(() => {
    window.localStorage.setItem('pneumaConfig', JSON.stringify(config));
    if (config?.developerAPIKey) {
      localStorage.setItem('developerAPIKey', config?.developerAPIKey);
    }
  }, [config]);

  const closeWidget = () => {
    window.parent.postMessage(JSON.stringify({ action: 'close' }), '*');
  };

  const goBack = () => {
    history.goBack();
  };

  const updateAppStart = (appStart: boolean) => {
    setIsAppStart(appStart);
  };

  useEffect(() => {
    async function fetchUserDetails() {
      if (config?.userId) {
        try {
          await fetchPatientProfile({ userId: config.userId });
        } catch (err) {
          message.error('Unable to get user details. Please retry.');
        }
      }
    }

    fetchUserDetails();
  }, [config.userId, fetchPatientProfile]);

  if (patientProfileError) {
    Swal.fire({
      icon: 'error',
      title: 'User not found!',
      text: 'Please check with your provider and try again.',
      confirmButtonColor: '#186fa5'
    }).then(() => {
      closeWidget();
    });
  }

  // widget validation
  const requiredKeys = [
    'firebaseConfig',
    'firebaseUsersCollectionName',
    'developerAPIKey'
  ];
  let errorDisplay = null;
  const missingFields = [];
  if (_.every(requiredKeys, _.partial(_.has, config))) {
    if (
      config.service === 'diagnostics' ||
      (config.service === 'consults' && config.consultationFee)
    ) {
      let required =
        config.service === 'diagnostics'
          ? ['paystackKey', 'businessName']
          : ['paystackKey'];
      if (!_.every(required, _.partial(_.has, config))) {
        missingFields.push(_.difference(required, Object.keys(config || {})));
      }
    }
    const isMultipleMissingFields = missingFields.length > 1;
    if (missingFields.length > 0) {
      errorDisplay = (
        <ErrorScreen
          caption={`Please enter ${
            isMultipleMissingFields ? 'these' : 'this'
          } missing field${
            isMultipleMissingFields ? 's' : ''
          }: ${missingFields.join(', ')}`}
        />
      );
    }
  } else {
    const missingFields = _.difference(requiredKeys, Object.keys(config || {}));
    const isMultipleMissingFields = missingFields.length > 1;
    if (isMultipleMissingFields) {
      errorDisplay = (
        <ErrorScreen
          caption={`Please enter either of these missing fields: ${missingFields.join(
            ', '
          )}`}
        />
      );
    } else {
      errorDisplay = (
        <ErrorScreen
          caption={`Please enter this missing field: ${missingFields.join(
            ', '
          )}`}
        />
      );
    }
  }

  const isAuth = userId;

  return (
    <AppStartContext.Provider value={{ appStart: isAppStart, updateAppStart }}>
      <div id="pneuma-widget-main-app">
        <div className="app-container">
          <div className="app-header">
            {service === 'consults' && !isAppStart ? (
              <Button onClick={goBack}>
                <LeftOutlined />
              </Button>
            ) : (
              <div />
            )}
            <div>
              <img
                className="logo"
                src="https://app.usenorth.care/static/media/north-logo-lg.cebecca994fa22720d6efbc524f3ac7b.svg"
                alt="icon"
              />
            </div>
            <img
              className="cancel"
              src={CancelIcon}
              alt="cancel"
              onClick={closeWidget}
            />
          </div>
          <FirebaseContext.Provider value={{ firebase }}>
            {errorDisplay ? (
              errorDisplay
            ) : !isAuth ? (
              <OnboardUser
                firebase={firebase}
                firebaseUsersCollectionName={firebaseUsersCollectionName}
              />
            ) : (
              <div className="app-body">
                {service === 'consults' ? (
                  <Switch>
                    <Route path="/" component={Consults} exact={true} />
                    <Route
                      path="/encounters/consults/:id"
                      component={Consults}
                      exact={true}
                    />
                    <Route
                      path="/encounters/start"
                      component={Complaints}
                      exact={true}
                    />
                    <Route
                      path="/encounters/start/:id"
                      component={Questions}
                      exact={true}
                    />
                  </Switch>
                ) : service === 'diagnostics' ? (
                  <Switch>
                    <Route
                      path="/"
                      component={MyHealthLabSidePane}
                      exact={true}
                    />
                  </Switch>
                ) : service === 'service' ? (
                  <Switch>
                    <Route path="/" component={ServicePlans} exact={true} />
                  </Switch>
                ) : (
                  <WidgetsMenu />
                )}
              </div>
            )}
          </FirebaseContext.Provider>
        </div>
      </div>
    </AppStartContext.Provider>
  );
}

export default App;

const ErrorScreen = ({ caption }: any) => {
  return (
    <div id="pneumacare-widget-error">
      <div className="content">
        <img src={WarningIcon} alt="WarningIcon" />
        <h3>We could not open this widget</h3>
        <p>{caption}</p>
      </div>
    </div>
  );
};

const WidgetsMenu = () => {
  const switchWidget = (service: string) => {
    window.parent.postMessage(
      JSON.stringify({ action: 'switch', service }),
      '*'
    );
  };

  return (
    <WidgetsMenuContainer>
      <h3>Hi there, Welcome to PneumaCare Widgets!</h3>
      <p>Here is the list of our available widgets.</p>
      <Row justify="center" gutter={40}>
        <Col xs={24} md={12}>
          <div className="menu-item" onClick={() => switchWidget('consults')}>
            <img className="logo" src={ConsultIcon} alt="icon" />
            <p className="title">Consults</p>
            <p className="body">
              Get started with a free chat or request a doctor to visit your
              home.
            </p>
          </div>
        </Col>
        <Col xs={24} md={12}>
          <div
            className="menu-item"
            onClick={() => switchWidget('diagnostics')}
          >
            <img className="logo" src={LabIcon} alt="icon" />
            <p className="title">Diagnostics</p>
            <p className="body">
              Get started with booking any diagnostic test at your convenience.
            </p>
          </div>
        </Col>
      </Row>
    </WidgetsMenuContainer>
  );
};
