import React, { forwardRef } from 'react';
import { Button as AntdButton, ButtonProps as AntdButtonProps } from 'antd';
import styled from 'styled-components';

export type ButtonProps = AntdButtonProps & {
  color?: 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success';
};

const Button = styled(
  forwardRef<HTMLElement, ButtonProps>(function Button(props, ref) {
    return <AntdButton ref={ref} {...props} />;
  })
)(({ theme, type, color, shape }) => ({
  ...theme.typography.button,
  backgroundColor: type === 'primary' ? theme.palette[color!].main : undefined,
  color:
    type === 'primary'
      ? theme.palette[color!].contrastText
      : theme.palette[color!].main,
  borderColor:
    type === 'dashed' || type === 'default' || type === 'primary'
      ? theme.palette[color!].main
      : undefined,
  ...(shape !== 'circle' ? { borderRadius: '4px' } : {}),
  '&:hover': {
    backgroundColor:
      type === 'primary' ? theme.palette[color!].light : undefined,
    borderColor:
      type === 'dashed' || type === 'default' || type === 'primary'
        ? theme.palette[color!].light
        : undefined
  },
  '&:active': {
    backgroundColor:
      type === 'primary' ? theme.palette[color!].dark : undefined,
    borderColor:
      type === 'dashed' || type === 'default' || type === 'primary'
        ? theme.palette[color!].dark
        : undefined
  }
}));

Button.defaultProps = {
  type: 'default',
  color: 'primary'
};

export default Button;
