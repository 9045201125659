import React from 'react';
import styled from 'styled-components';

export const WidgetsMenuContainer = styled.div`
    padding: 40px 40px 0px;
    h3 {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 5px;
        line-height: 40px;
    }
    p {
        margin-bottom: 20px;
    }
    .menu-item {
        padding: 30px 20px 20px;
        border: 1px solid #f2f2f2;
        box-shadow: 5px 5px 25px 0 rgb(0 0 0 / 14%);
        background: #fff;
        cursor: pointer;
        border-radius: 8px;
        margin-bottom: 20px;
        img {
            margin-bottom: 10px;
            width: 60px;
        }
        p.title {
            font-size: 20px;
            margin-bottom: 5px;
            font-weight: bold;
            color: #0F9AF0;
        }
        p.body {
            font-size: 13px;
            margin-bottom: 0px;
            color: #333;
        }
        &:hover {
            border-color: #0F9AF0;
        }
    }
`;