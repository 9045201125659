import { Row, Col, Divider, Space, Checkbox } from 'antd';
import {} from '@ant-design/icons';
import { LabSidePaneColumnContainer } from './styles';
import MyHealthLabSidePaneOrderTestLabCard from './MyHealthLabSidePaneOrderTestLabCard';
import Typography from 'component/Typography';
import CurrencyTypography from 'component/CurrencyTypography';
import laboratoryApi from 'redux/queries/laboratory';
import ContentLoading from 'component/ContentLoading';
import React, { useMemo } from 'react';

function MyHealthLabSidePaneOrderTestLabTestSelection(props: any) {
  const { formik } = props;

  const laboratories = useMemo(
    () => Object.values(formik.values.laboratory),
    [formik.values.laboratory]
  );

  const investigations = useMemo(
    () => Object.values(formik.values.investigations),
    [formik.values.investigations]
  );

  return (
    <LabSidePaneColumnContainer>
      <Typography color="textSecondary">
        Select the tests to be done in each lab
      </Typography>
      <div className="flex-column-content">
        <Row gutter={[0, 8]} justify="center">
          {laboratories?.map((laboratory: any, index: number) => (
            <Col span={24} key={index}>
              <Laboratory
                formik={formik}
                investigations={investigations}
                laboratory={laboratory}
              />
            </Col>
          ))}
        </Row>
      </div>
    </LabSidePaneColumnContainer>
  );
}

export default MyHealthLabSidePaneOrderTestLabTestSelection;

function Laboratory(props: any) {
  const { formik, investigations, laboratory } = props;

  const { data, isLoading, isError, refetch } =
    laboratoryApi.useGetLaboratoryQuery({ path: { id: laboratory.id } });

  const labInvestigations = data?.data?.investigations;

  const availableInvestigations = useMemo(() => {
    return (investigations && labInvestigations) ? labInvestigations
      .filter((investigation: any) => investigations?.find((item: any) => item.id === investigation.id)) : [];
  }, [investigations, labInvestigations]);

  return (
    <ContentLoading loading={isLoading} error={isError} onReload={refetch}>
      {() => (
        <MyHealthLabSidePaneOrderTestLabCard
          laboratory={laboratory}
          labInvestigations={labInvestigations}
          requestedInvestigations={investigations}
          availableInvestigations={availableInvestigations}
          investigations={investigations}
        >
          <Divider style={{ margin: '16px 0px' }} />
          {availableInvestigations?.length ? (
            <>
              <Space direction="vertical" style={{ width: '100%' }}>
                {availableInvestigations?.map(
                  (investigation: any, index: any) => (
                    <Row>
                      <Col flex={1}>
                        <Typography key={index}>
                          {investigation.name}
                        </Typography>
                      </Col>
                      <Col>
                        <Space>
                          <CurrencyTypography variant="body2">
                            {investigation.price / 100}
                          </CurrencyTypography>
                          <Checkbox
                            checked={
                              !!formik.values.labInvestigations[
                                laboratory.id
                              ]?.[investigation.id]
                            }
                            onChange={(e) => {
                              const _laboratory = Object.assign(
                                {},
                                formik.values.labInvestigations
                              );
                              if (e.target.checked) {
                                _laboratory[laboratory.id] = {
                                  ..._laboratory[laboratory.id],
                                  [investigation.id]: investigation
                                };
                              } else {
                                delete _laboratory[laboratory.id]?.[
                                  investigation.id
                                ];

                                if (
                                  !Object.keys(_laboratory[laboratory.id])
                                    .length
                                ) {
                                  delete _laboratory[laboratory.id];
                                }
                              }
                              formik.setFieldValue(
                                'labInvestigations',
                                _laboratory
                              );
                            }}
                          />
                        </Space>
                      </Col>
                    </Row>
                  )
                )}
              </Space>
            </>
          ) : (
            <Typography align="center">
              Selected tests unavailable
            </Typography>
          )}
        </MyHealthLabSidePaneOrderTestLabCard>
      )}
    </ContentLoading>
  );
}
