import React, { useEffect, ReactNode, ComponentPropsWithoutRef } from 'react';
import { Container } from './styles';
import Button from 'common/Button';
import LoadingIndicator from '../Loader';
import useDataRef from 'hooks/useDataRef';
import { ReloadOutlined } from '@ant-design/icons';

export type ContentLoadingProps = {
  size?: string | number;
  onMount?: Function;
  onReload?: Function;
  error?: boolean;
  loading?: boolean;
  errorContent?: ReactNode;
  loadingContent?: ReactNode;
} & ComponentPropsWithoutRef<typeof Container>;

function ContentLoading(props: ContentLoadingProps) {
  const {
    size,
    error,
    loading,
    children,
    onReload,
    onMount,
    loadingContent,
    errorContent,
    ...rest
  } = props;

  const dataRef = useDataRef({ onReload, onMount });

  useEffect(() => {
    if (dataRef.current.onMount) {
      dataRef.current.onMount();
    }
  }, [dataRef]);

  if (!loading && !error) {
    return children();
  }

  return (
    <Container {...rest}>
      {error
        ? errorContent || (
            <Button shape="circle" onClick={onReload as any}>
              <ReloadOutlined />
            </Button>
          )
        : loadingContent || <LoadingIndicator size={size} />}
    </Container>
  );
}

ContentLoading.defaultProps = {};

export default ContentLoading;
