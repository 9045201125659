import { configureStore, Action } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
// import logger from 'redux-logger';
import { ThunkAction } from 'redux-thunk';

import rootReducer, { RootState } from './reducers';
import connectApi from './queries/connect-api';
import {profileApi} from './queries/profile'

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      connectApi.middleware,
      // logger
      resetRTKQCacheOnLogoutMiddleware(connectApi)
    ),
  devTools: process.env.NODE_ENV !== 'production'
});

export function resetRTKQCacheOnLogoutMiddleware(...apis: any[]) {
  return (store: any) => (next: any) => (action: any) => {
    const result = next(action);
    if (profileApi.endpoints.logoutPatient.matchFulfilled(action)) {
      for (const api of apis) {
        store.dispatch(api.util.resetApiState());
      }
    }
    return result;
  };
}

// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppThunk = ThunkAction<void, RootState, unknown, Action>;
export default store;
