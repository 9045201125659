import React from 'react';
import { Container } from './styles';
import Tabs from 'component/Tabs';

function SidePane(props: any) {
  return (
    <Container>
      <Tabs defaultActiveKey="1" tabBarGutter={20}>
        {props.children}
      </Tabs>
    </Container>
  );
}

SidePane.TabPane = Tabs.TabPane;

export default SidePane;
