import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Select as AntdSelect, Form, message } from 'antd';
import moment from 'moment';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';

import { AddUserModalWrapper, FormItem } from './styles';
import Input from '../Input';
import Select from '../Select';
import Button from '../Button';
import { DateOfBirthPicker } from 'common/DatePicker';

import { resetHasVerifiedUser, userSelector } from 'redux/reducers/users';
import { profileApi } from 'redux/queries/profile';
import { IConfig } from 'config/interfaces';
import { Context } from 'context/context';
import LoginSvg from 'assets/icons/login-icon.svg';
import RegisterSvg from 'assets/icons/register-icon.svg';
import RecoverPasswordSvg from 'assets/icons/recover-password.svg';

const OnboardUser = ({ firebase, firebaseUsersCollectionName }: any) => {
  const [loginPatientMutation] = profileApi.useLoginPatientMutation();
  const [verifyEmailMutation] = profileApi.useVerifyPatientEmailMutation();
  const [requestAccessMutation] = profileApi.useRequestPatientAccessMutation();
  const [createPatientProfile] = profileApi.useCreatePatientProfileMutation();
  const [approveAccessMutation] = profileApi.useApprovePatientAccessMutation();
  const [recoverPassword] = profileApi.useRecoverPatientPasswordMutation();

  // const auth = firebase.auth();
  const db = firebase.firestore();
  const usersCollection = db.collection(firebaseUsersCollectionName);
  const dispatch = useDispatch();

  const { user, pageStepNo, hasCreatedUser } = useSelector(userSelector);
  const config: IConfig = JSON.parse(useContext(Context));
  const [userForm] = Form.useForm();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');

  useEffect(() => {
    dispatch(resetHasVerifiedUser());
  }, [dispatch]);

  // Action point when a user has been created successfully
  useEffect(() => {
    if (hasCreatedUser) {
      userForm.resetFields();
      setEmail('');
      setCurrentIndex(0);
      setOtp('');
      // updateWidgetConfigUserId(user.id);
      window.parent.postMessage(
        JSON.stringify({
          action: 'init',
          config: { ...config, userId: user.id }
        }),
        '*'
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, hasCreatedUser, user, userForm]);

  useEffect(() => {
    setCurrentIndex(pageStepNo);
  }, [pageStepNo]);

  const handleLoginUser = async (values: any) => {
    const _values = values as { email: string; password: string };

    setIsLoading(true);
    try {
      const res = await loginPatientMutation({
        data: { ..._values },
        firebase
      }).unwrap();

      const docRef = usersCollection.doc(res?.uid);
      await docRef
        .get()
        .then((doc: any) => {
          if (doc.exists) {
            const { patientId: userId } = doc.data();

            window.parent.postMessage(
              JSON.stringify({ action: 'init', config: { ...config, userId } }),
              '*'
            );
          } else {
            console.log('No such document!');
            message.error('Invalid credentials.');
          }
        })
        .catch((error: any) => {
          console.log('Error getting document:', error);
          message.error('An error occurred. Please try again');
        });

      message.success('Logged in successfully');
    } catch (err: object | any) {
      if (err.status === 'auth/user-not-found') {
        message.error('User not found. Please sign up');
        setCurrentIndex(2);
      } else if (err.status === 'auth/internal-error') {
        message.error('Server error. Please contact your administrator.');
      } else if (err.status === 'auth/wrong-password') {
        message.error('Invalid credentials');
      } else {
        message.error('Unable to Login. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyUser = async (values: any) => {
    const _values = values as { email: string };
    setEmail(_values.email);

    setIsLoading(true);
    try {
      // check provider's firebase collection for that email - if the email address has sign in methods attached to it, it means the email has been registered already, and the user should login
      const arr = await firebase
        .auth()
        .fetchSignInMethodsForEmail(_values.email);

      if (arr.includes('password')) {
        // user exists on providers firestore - push to LOGIN route
        setCurrentIndex(0);
        message.success('Existing User. Please Login.');
      } else {
        // check other PneumaCare integrations
        try {
          // 1. verify user email
          await verifyEmailMutation({
            data: { ..._values }
          }).unwrap();

          //2. request access to this integration & push to OTP screen
          const res2 = await requestAccessMutation({
            data: _values.email
          }).unwrap();
          message.success(res2.message);
          setCurrentIndex(3);
        } catch (err: object | any) {
          // at this point, its very likely the user is a new one

          // check if err thrown from verify email is 400 - user not found
          if (err.status === 400) {
            // push to register screen
            setCurrentIndex(2);
          } else {
            message.error('Unable to verify email. Please try again!');
          }
        }
      }
    } catch (err: object | any) {
      message.error('Please try again!');
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnboardUser = async (values: any) => {
    if (otp) {
      setIsLoading(true);
      try {
        const res = await approveAccessMutation({
          data: { code: otp }
        }).unwrap();

        message.success('Onboarded successfully!');
        console.log(res);
        window.parent.postMessage(
          JSON.stringify({
            action: 'init',
            config: { ...config, userId: res.data.data.id }
          }),
          '*'
        );
      } catch (err: object | any) {
        message.error(
          err.message || 'Unable to complete onboarding. Please try again!'
        );
      }
    } else {
      message.error('Please enter the OTP code');
    }
  };

  const handleCreateUser = async (values: any) => {
    const _values = values as {
      first_name: string;
      last_name: string;
      phone: string;
      gender: 'male' | 'female';
      dob: string;
      password: string;
    };

    _values.dob = moment(values.dob).format('DD/MM/YYYY');

    setIsLoading(true);
    try {
      const res = await createPatientProfile({
        data: { email, ..._values },
        firebase,
        collectionName: firebaseUsersCollectionName
      }).unwrap();

      message.success('Profile successfully registered!!');
      window.parent.postMessage(
        JSON.stringify({
          action: 'init',
          config: { ...config, userId: res.data.id }
        }),
        '*'
      );
    } catch (err: object | any) {
      message.warn(
        err.message ||
          'There was an error setting up your account. Please contact a representative.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleRecoverPassword = async (values: any) => {
    const _values = values as { email: string };

    setIsLoading(true);
    try {
      await recoverPassword({ data: { ..._values }, firebase });
      message.success('Reset email sent successfully!');
      setCurrentIndex(0);
    } catch (err: any | object) {
      message.error(err.message || 'Unable to send email. Please try again!');
    } finally {
      setIsLoading(false);
    }
  };

  const steps = [
    //  Login User
    {
      component: (props: any) =>
        props.isVisible && (
          <>
            <Row gutter={24} className="login-wrapper">
              <div className="left-pane" style={{ marginTop: '-40px' }}>
                <Col span={24} className="header">
                  <img src={LoginSvg} alt="icon" />
                  <h2>Welcome!</h2>
                  <p>Please provide your login details to get started</p>
                </Col>
              </div>

              <div className="right-pane" style={{ marginTop: '-40px' }}>
                <div>
                  <Col span={24}>
                    <Input
                      formItem={{
                        label: 'Email address',
                        name: 'email',
                        rules: [
                          {
                            required: true,
                            message: "Please input user's Email address"
                          },
                          {
                            type: 'email',
                            message: 'Please input a valid email'
                          }
                        ]
                      }}
                      mode="normal"
                      type="email"
                      onChange={(e: any) => setEmail(e.target.value)}
                    />
                  </Col>

                  <Col span={24}>
                    <Input
                      formItem={{
                        label: 'Password',
                        name: 'password',
                        type: 'password',
                        rules: [
                          {
                            required: true,
                            message: "Please input user's password"
                          }
                        ]
                      }}
                      mode="normal"
                      label="Password"
                      type="password"
                    />
                    <small onClick={() => setCurrentIndex(4)}>
                      Forgot your password?
                    </small>
                  </Col>
                </div>
              </div>
            </Row>
          </>
        )
    },
    //  Verify User email
    {
      component: (props: any) =>
        props.isVisible && (
          <>
            <Row gutter={24} className="login-wrapper">
              <div className="left-pane">
                <Col span={24} className="header">
                  <img src={RegisterSvg} alt="icon" />
                  <h2>Create an account!</h2>
                  <p>
                    Enter your details to create an account <br />{' '}
                  </p>
                </Col>
              </div>

              <div className="right-pane">
                <div>
                  <Col span={24}>
                    <Input
                      formItem={{
                        label: 'What is your email address?',
                        name: 'email',
                        rules: [
                          {
                            required: true,
                            message: 'Please input your email address'
                          },
                          {
                            type: 'email',
                            message: 'Please input a valid email address'
                          }
                        ]
                      }}
                      mode="normal"
                      placeholder="name@email.com"
                      type="email"
                    />
                  </Col>
                </div>
              </div>
            </Row>
          </>
        )
    },
    // Register User
    {
      component: (props: any) =>
        props.isVisible && (
          <div>
            <Col span={24} className="header">
              <h2>Create a telehealth profile!</h2>
              <p>
                Enter your details to create an account for <b>{email}</b>
              </p>
            </Col>
            <Row gutter={24}>
              <Col span={12}>
                <Input
                  formItem={{
                    label: 'First Name',
                    name: 'first_name',
                    rules: [
                      {
                        required: true,
                        message: "Please input user's first name"
                      }
                    ]
                  }}
                  mode="normal"
                  type="text"
                />
              </Col>
              <Col span={12}>
                <Input
                  formItem={{
                    label: 'Last Name',
                    name: 'last_name',
                    rules: [
                      {
                        required: true,
                        message: "Please input user's last name"
                      }
                    ]
                  }}
                  mode="normal"
                  type="text"
                />
              </Col>
            </Row>

            <Row gutter={24}>
              <Col xs={24} md={12}>
                <Input
                  formItem={{
                    label: 'Phone number',
                    name: 'phone',
                    rules: [
                      {
                        required: true,
                        message: "Please enter user's phone number"
                      },
                      {
                        min: 11,
                        message:
                          'Phone number cannot be less than 11 characters'
                      },
                      {
                        max: 11,
                        message:
                          'Phone number cannot be greater than 11 characters'
                      },
                      {
                        pattern: /^\d+$/,
                        message: 'Phone number can only contain digits'
                      }
                    ]
                  }}
                  mode="normal"
                  type="text"
                  label="Phone number"
                />
              </Col>
              <Col xs={12} md={6}>
                <Select
                  formItem={{
                    name: 'gender',
                    label: 'Gender',
                    rules: [
                      {
                        required: true,
                        message: 'Please select gender'
                      }
                    ]
                  }}
                  marginBottom={0}
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Select gender"
                  label=""
                  mode="normal"
                  required
                >
                  {['male', 'female'].map((option, index) => {
                    return (
                      <AntdSelect.Option key={index} value={option}>
                        <span style={{ textTransform: 'capitalize' }}>
                          {option}
                        </span>
                      </AntdSelect.Option>
                    );
                  })}
                </Select>
              </Col>
              <Col xs={12} md={6}>
                <FormItem
                  label="Date of Birth"
                  name="dob"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Date of Birth'
                    }
                  ]}
                >
                  <DateOfBirthPicker
                    clearIcon={null}
                    disableCalendar={true}
                    format="dd/MM/yyyy"
                    dayPlaceholder="DD"
                    monthPlaceholder="MM"
                    yearPlaceholder="YYYY"
                    maxDate={
                      new Date(
                        new Date().setFullYear(new Date().getFullYear() - 16)
                      )
                    }
                  />
                </FormItem>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <Input
                  formItem={{
                    label: 'Password',
                    name: 'password',
                    type: 'password',
                    rules: [
                      {
                        required: true,
                        message: 'Please input your password'
                      }
                    ]
                  }}
                  mode="normal"
                  type="password"
                />
              </Col>

              <Col span={12}>
                <Input
                  formItem={{
                    label: 'Confirm Password',
                    name: 'confirm_password',
                    dependencies: ['password'],
                    type: 'password',
                    rules: [
                      {
                        required: true,
                        message: 'Please confirm your password'
                      },
                      ({ getFieldValue }: any) => ({
                        validator(_: any, value: any) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              'The two passwords that you entered do not match!'
                            )
                          );
                        }
                      })
                    ]
                  }}
                  mode="normal"
                  type="password"
                />
              </Col>
            </Row>
          </div>
        )
    },
    // Authorize access with OTP
    {
      component: (props: any) =>
        props.isVisible && (
          <div className="otp-pane">
            <Col span={24} className="header">
              <h2>Authorize Access to Profile!</h2>
            </Col>
            <p
              style={{
                color: '#333',
                lineHeight: '22px',
                fontSize: '13px',
                background: '#0ff0c07a',
                padding: '10px 20px',
                borderRadius: '6px',
                marginBottom: '20px'
              }}
            >
              An OTP code has been sent to {email}
            </p>
            <label style={{ textAlign: 'left' }}>Enter OTP code</label>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '15px'
              }}
            >
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={8}
                containerStyle={{
                  display: 'flex',
                  flexWrap: 'wrap'
                }}
                inputStyle={{
                  height: '40px',
                  width: '40px',
                  border: '2px solid rgb(223 218 218)',
                  borderRadius: '6px',
                  marginRight: '10px',
                  marginBottom: '10px'
                }}
                focusStyle={{
                  border: '2px solid #0f9af0'
                }}
              />
            </div>
          </div>
        )
    },
    // Loading
    {
      component: (props: any) =>
        props.isVisible && (
          <>
            <Row gutter={24} className="login-wrapper">
              <div className="left-pane">
                <Col span={24} className="header">
                  <img src={RecoverPasswordSvg} alt="icon" />
                  <h2>Forgot your password?</h2>
                  <p>
                    Enter your email and we will send you a link to reset your
                    password
                  </p>
                </Col>
              </div>

              <div className="right-pane">
                <div>
                  <Col span={24}>
                    <Input
                      formItem={{
                        label: 'Email address',
                        name: 'email',
                        rules: [
                          {
                            required: true,
                            message: 'Please input your email address'
                          },
                          {
                            type: 'email',
                            message: 'Please input a valid email'
                          }
                        ]
                      }}
                      mode="normal"
                      type="email"
                    />
                  </Col>
                </div>
              </div>
            </Row>
          </>
        )
    }
  ];

  const formSubmitFn =
    currentIndex === 0
      ? handleLoginUser
      : currentIndex === 1
      ? handleVerifyUser
      : currentIndex === 2
      ? handleCreateUser
      : currentIndex === 3
      ? handleOnboardUser
      : currentIndex === 4
      ? handleRecoverPassword
      : () => null;

  const secondaryButtonText = currentIndex === 0 ? '' : 'Back';

  const secondaryButtonOnClick = () => setCurrentIndex(0);

  const mainButtonText =
    currentIndex === 0
      ? 'Login'
      : currentIndex === 1
      ? 'Verify email'
      : currentIndex === 2
      ? 'Create profile'
      : currentIndex === 3
      ? 'Add user'
      : 'Submit';

  const mainButtonLoadingText =
    currentIndex === 0
      ? 'Logging in...'
      : currentIndex === 1
      ? 'Verifying'
      : currentIndex === 2
      ? 'Creating...'
      : currentIndex === 3
      ? 'Onboarding...'
      : 'Submitting';

  return (
    <AddUserModalWrapper>
      <Form
        name="basicForm"
        form={userForm}
        layout="vertical"
        onFinish={formSubmitFn}
      >
        {steps.map((step, index) => {
          const isVisible = index === currentIndex;
          const { component } = step;
          return component({ isVisible });
        })}
        {currentIndex !== 3 && (
          <Row className="btn-wrapper">
            <Button
              type="secondary"
              onClick={secondaryButtonOnClick}
              style={{ marginRight: 10 }}
            >
              {secondaryButtonText}
            </Button>
            <Button htmlType="submit" type="primary" disabled={isLoading}>
              {isLoading ? mainButtonLoadingText : mainButtonText}
            </Button>
          </Row>
        )}

        {currentIndex === 0 && (
          <p className="register-prompt">
            Do not have an account?
            <span onClick={() => setCurrentIndex(1)}>Create account</span>
          </p>
        )}
      </Form>
    </AddUserModalWrapper>
  );
};

export default OnboardUser;
