import React, { forwardRef } from 'react';
import { Spin as AntdSpin, SpinProps as AntdSpinProps } from 'antd';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

export type SpinProps = {children?: any} & AntdSpinProps;

const Spin = styled(
  forwardRef<any, SpinProps>(function Spin(props, ref) {
    return <AntdSpin ref={ref} {...props} />;
  })
)`

`;

Spin.defaultProps = {
  indicator: <LoadingOutlined style={{ fontSize: 32 }} spin />
};
export default Spin;
