import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { profileApi } from '../queries/profile';

export type GlobalSlice = {
  isSidebar: boolean;
  isLoadingSpin: boolean;
  user: any;
  isLaboratory: boolean;
};

const initialState = {
  isSidebar: false,
  isLoadingSpin: false,
  user: {
    // patientId: 'rpqlkhev828627o'
  },
  isLaboratory: false
} as GlobalSlice;

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    toggleSidebarAction: (state, { payload }: PayloadAction<undefined>) => {
      state.isSidebar = payload || !state.isSidebar;
    },
    toggleLoadingSpinAction: (state, { payload }) => {
      state.isLoadingSpin =
        payload !== undefined ? !!payload : !state.isLoadingSpin;
    },
    toggleLaboratoryAction: (state, { payload }: PayloadAction<undefined>) => {
      state.isLaboratory = payload || !state.isLaboratory;
    },
    setUser: (state, { payload }) => {
      Object.assign(state.user, payload);
    },
    logout: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(
        profileApi.endpoints.getPatientProfile.matchFulfilled,
        (state, { payload }) => {
          Object.assign(state.user, payload?.data);
        }
      )
      .addMatcher(
        profileApi.endpoints.createPatientProfile.matchFulfilled,
        (state, { payload }) => {
          Object.assign(state.user, payload?.data);
        }
      )
      .addMatcher(
        profileApi.endpoints.logoutPatient.matchFulfilled,
        (state) => {
          Object.assign(state, initialState);
        }
      )
});

export const {
  toggleSidebarAction,
  toggleLoadingSpinAction,
  toggleLaboratoryAction,
  setUser: setUserAction
  // logout: logoutAction
} = globalSlice.actions;

export default globalSlice.reducer;
