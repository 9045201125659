import React from 'react';
import { EmptyContentContainer } from './styles';
import Typography from 'component/Typography';

export type EmptyContentProps = {
  title?: string;
  description?: string;
  icon?: any;
  imageStyle?: any;
};

function EmptyContent(props: EmptyContentProps) {
  const { title, description, icon, imageStyle } = props;
  return (
    <EmptyContentContainer>
      <img src={icon} alt="" style={{ height: 100, ...imageStyle }} />
      <Typography align="center">{title}</Typography>
      {description ? (
        <Typography variant="body2" color="textSecondary" align="center">
          {description}
        </Typography>
      ) : null}
    </EmptyContentContainer>
  );
}

export default EmptyContent;
