import { forwardRef } from 'react';
import {
  DatePicker as AntdDatePicker,
  DatePickerProps as AntdDatePickerProps
} from 'antd';
import ReactDateOfBirthPicker from 'react-date-picker';
import styled from 'styled-components';
import clsx from 'clsx';

export type DatePickerProps = { underline?: boolean } & AntdDatePickerProps;

const DatePicker = styled(
  forwardRef<any, DatePickerProps>(function DatePicker(props, ref) {
    const { underline, className, ...rest } = props;
    return (
      <AntdDatePicker
        ref={ref}
        className={clsx(className, underline && 'ant-datepicker-underline')}
        {...rest}
      />
    );
  })
)`
  display: flex;
  &.ant-datepicker-underline {
    border-top: none;
    border-right: none;
    border-left: none;
    box-shadow: none;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const DateOfBirthPicker = styled(ReactDateOfBirthPicker)`
  &.react-date-picker {
    width: 100%;
    border-radius: 8px;
  }

  .react-date-picker__wrapper {
    height: 44px;
    padding: 10px;
    border: 1px solid #bfcad0;
    border-radius: 8px;

    .react-date-picker__inputGroup__leadingZero {
      margin-bottom: 2px;
    }
    .react-date-picker__inputGroup__input {
      font-family: 'DM Sans';
    }
    .react-date-picker__inputGroup__input:focus {
      outline: none;
    }
    .react-date-picker__inputGroup__day::placeholder,
    .react-date-picker__inputGroup__month::placeholder,
    .react-date-picker__inputGroup__year::placeholder {
      color: #b1adad;
    }
  }
`;

export default DatePicker;
