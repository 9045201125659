import {
  MessageOutlined,
  PlusOutlined,
  UnorderedListOutlined
} from '@ant-design/icons';
import { Grid, Space, Switch } from 'antd';
import Button from 'common/Button';
import Card from 'common/Card';
import ContentLoading from 'component/ContentLoading';
import DashboardSidePaneToggleIcon from 'component/Dashboard/SidePane/ToggleIcon';
import Tabs from 'component/Tabs';
import { RouteEnum } from 'constants/routes';
import { AppStartContext } from 'context/appStart';
import useToggle from 'hooks/useToggle';
import ScreenLayout from 'layouts/ScreenLayout';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { encounterConsultApi } from 'redux/queries/encounter';
import { useAppSelector } from 'redux/store';
import { useTheme } from 'styled-components';
import Chats from './Chats';
import ConsultContext from './Context';
import SidePane from './SidePane';

const Consults = () => {
  const theme = useTheme() as any;
  const [activeTab, setActiveTab] = useState('consult');
  const [isViewNotes, setIsViewNotes] = useState(false);
  const { id: consultId } = useParams() as { id: string };
  const [isChatList, toggleChatList] = useToggle(true);
  const { user } = useAppSelector((state) => state.global);

  const breakpoints = Grid.useBreakpoint();

  // get user id from global state instead
  const { data, isLoading, refetch } =
    encounterConsultApi.useGetEncounterConsultsQuery({ userId: user.id });

  const consults = data?.data;

  const appStart = useContext(AppStartContext);
  useEffect(() => {
    if (appStart?.updateAppStart) {
      appStart?.updateAppStart(consultId ? false : true);
    }
  }, [appStart, consultId]);

  const activeConsult = useMemo(
    () => consults?.find((consult: any) => consult.id === consultId),
    [consultId, consults]
  );

  return (
    <ContentLoading loading={isLoading} error={false} onReload={refetch}>
      {() => (
        <ConsultContext.Provider
          value={{ isChatList, toggleChatList, breakpoints }}
        >
          <ScreenLayout
            pane={consults.length ? <SidePane consult={activeConsult} /> : null}
            fabIcon={<DashboardSidePaneToggleIcon />}
          >
            <Card
              style={{ height: '100%', overflow: 'auto' }}
              bodyStyle={{ height: '100%', padding: 8 }}
            >
              <Tabs
                defaultActiveKey="consult"
                activeKey={activeTab}
                onChange={setActiveTab}
                tabBarExtraContent={{
                  right: activeTab === 'consult' && (
                    <Space align="center" style={{ marginBottom: 8 }}>
                      {breakpoints.md || !consults.length ? null : (
                        <>
                          {isChatList ? (
                            <MessageOutlined
                              style={{
                                fontSize: 20,
                                marginTop: 4,
                                color: theme.palette.primary.main
                              }}
                              onClick={toggleChatList}
                            />
                          ) : (
                            <UnorderedListOutlined
                              style={{
                                fontSize: 20,
                                marginTop: 4,
                                color: theme.palette.primary.main
                              }}
                              onClick={toggleChatList}
                            />
                          )}
                        </>
                      )}
                      {!!activeConsult && (
                        <Switch
                          checked={isViewNotes}
                          onChange={setIsViewNotes}
                          checkedChildren="View chats"
                          unCheckedChildren="View notes"
                        />
                      )}
                      {consults?.length ? (
                        <>
                          <Link to={RouteEnum.ENCOUNTER_START}>
                            <Button type="primary">
                              {breakpoints.md ? (
                                'Start a new consult'
                              ) : (
                                <PlusOutlined />
                              )}
                            </Button>
                          </Link>
                        </>
                      ) : null}
                    </Space>
                  )
                }}
              >
                <Tabs.TabPane
                  tab="All consults"
                  key="consult"
                  style={{ overflow: 'hidden' }}
                >
                  <Chats
                    consults={consults}
                    isViewNotes={isViewNotes}
                    consult={activeConsult}
                    isChatList={isChatList}
                    toggleChatList={toggleChatList}
                  />
                </Tabs.TabPane>
              </Tabs>
            </Card>
          </ScreenLayout>
        </ConsultContext.Provider>
      )}
    </ContentLoading>
  );
};

export default Consults;
