import React, { useMemo } from 'react';
import { Row, Col, Avatar, message } from 'antd';
import Card, { CardProps } from 'common/Card';
import Typography from 'component/Typography';
import claire from 'assets/images/claire.svg';
import * as dfn from 'date-fns';
import { DateFormatEnum } from 'constants/global';
import Divider from 'component/Divider';
import { useTheme } from 'styled-components';
import Button from 'common/Button';
import {
  encounterConsultApi,
  encounterStartApi
} from 'redux/queries/encounter';
import useLoadingSpin from 'hooks/useLoadingSpin';

export type SidePaneCardProps = { data: any } & CardProps;

function SidePaneCard(props: SidePaneCardProps) {
  const { data, children, ...rest } = props;
  const provider = data?.provider;
  const theme = useTheme() as any;
  const { toggleLoadingSpin } = useLoadingSpin();

  const formQueryResult = encounterStartApi.useGetEncounterComplaintFormQuery(
    useMemo(() => ({ path: { id: data?.form } }), [data?.form])
    // { skip: isAccepted || hasEnded }
  );

  const [markAsCompleteMutation] =
    encounterConsultApi.useMarkEncounterConsultCompleteMutation();

  async function handleMarkAsComplete() {
    toggleLoadingSpin();
    try {
      const resData = await markAsCompleteMutation({
        path: { consult_id: data.id }
      }).unwrap();
      message.success(resData.message);
    } catch (error: object | any) {
      // message.error(error?.data?.error);
    }
    toggleLoadingSpin();
  }

  return (
    <Card
      {...rest}
      bodyStyle={{
        padding: 16,
        borderLeft: `6px solid ${
          theme.palette[data?.treatment_plan_completed ? 'success' : 'primary']
            .main
        }`
      }}
    >
      <Row gutter={16}>
        <Col>
        <Avatar
            style={{
              color: theme.palette.primary.contrastText,
              backgroundColor: theme.palette.primary.main
            }}
          >
            {provider?.last_name?.substring(0, 1)}
          </Avatar>
        </Col>
        <Col>
          <Typography>{`${provider?.last_name} ${provider?.first_name}`}</Typography>
          <Typography color="textSecondary" variant="body2">
            {formQueryResult.data?.data?.name} -{' '}
            {dfn.format(new Date(data?.created_at), DateFormatEnum.HYPHEN)}
          </Typography>
        </Col>
      </Row>
      <Divider />
      {children}
      {!data?.treatment_plan_completed &&
        !!(
          data?.recommendations?.length ||
          data?.investigations?.length ||
          data?.medications?.length
        ) && (
          <>
            <Divider />
            <Row justify="center" style={{ marginTop: '16px' }}>
              <Col>
                <Button type="primary" onClick={handleMarkAsComplete}>
                  Mark as complete
                </Button>
              </Col>
            </Row>
          </>
        )}
    </Card>
  );
}

export default SidePaneCard;
