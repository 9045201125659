import React, { useContext, useMemo } from 'react';
import { Row, Col, Avatar } from 'antd';
import Typography from 'component/Typography';
import { ListItemContainer } from './styles';
import { generatePath } from 'react-router-dom';
import { RouteEnum } from 'constants/routes';
import {
  encounterStartApi,
  encounterConsultApi
} from 'redux/queries/encounter';
// import * as dfn from 'date-fns';
import { useTheme } from 'styled-components';
import ConsultContext from '../Context';

function ListItem(props: any) {
  const { data } = props;
  const { toggleChatList } = useContext(ConsultContext);

  const theme = useTheme() as any;

  const provider = data?.provider;
  const status = data?.status;

  // const isAccepted = status === 'accepted';
  // const hasEnded = status === 'ended';
  const pending = status === 'pending';
  const rejected = status === 'rejected';

  const formQueryResult = encounterStartApi.useGetEncounterComplaintFormQuery(
    useMemo(() => ({ path: { id: data?.form } }), [data?.form])
    // { skip: isAccepted || hasEnded }
  );

  encounterConsultApi.useOnEncounterConsultStatusChangeQuery(
    useMemo(() => ({ path: { consultId: data?.id } }), [data?.id]),
    { skip: rejected || !data?.id }
  );

  const primary = pending
    ? 'Pending...'
    : rejected
    ? 'Rejected'
    : `${provider?.last_name} ${provider?.first_name}`;

  const secondary = formQueryResult.data?.data?.name; //!pending ? provider?.email : 'Pending';

  const append = { status };

  return (
    <ListItemContainer
      {...append}
      to={generatePath(RouteEnum.ENCOUNTERS_CONSULTS_DETAILS, {
        id: data?.id
      })}
      onClick={toggleChatList}
    >
      <Row gutter={8} wrap={false}>
        <Col>
          <Avatar
            style={{
              color: theme.palette.primary.contrastText,
              backgroundColor: theme.palette.primary.main
            }}
          >
            {primary.substring(0, 1)}
          </Avatar>
        </Col>
        <Col flex={1} style={{ minWidth: 0 }}>
          <Typography nowrap={true}>{primary}</Typography>
          <Typography nowrap={true} color="textSecondary" variant="body2">
            {secondary}
          </Typography>
        </Col>
      </Row>
    </ListItemContainer>
  );
}

export default ListItem;
