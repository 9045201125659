import React from 'react';
import { Button as AntdButton } from 'antd';
import { ButtonWrapper } from './styles';

const Button = (props: any) => {
  const { type, ...rest } = props;
  return (
    <ButtonWrapper type={type}>
      <AntdButton {...rest}>{props.children}</AntdButton>
    </ButtonWrapper>
  );
};

export default Button;