import React, { forwardRef } from 'react';
import { Select as AntdSelect, SelectProps as AntdSelectProps } from 'antd';
import styled from 'styled-components';
import clsx from 'clsx';

export type SelectProps = { underline?: boolean } & AntdSelectProps<any>;

const Select = styled(
  forwardRef<any, SelectProps>(function Select(props, ref) {
    const { underline, className, ...rest } = props;
    return (
      <AntdSelect
        ref={ref}
        className={clsx(className, underline && 'ant-select-underline')}
        {...rest}
      />
    );
  })
)`
  &.ant-select-underline {
    & .ant-select-selector {
      border-top: none;
      border-right: none;
      border-left: none;
      box-shadow: none !important;
      padding-left: 0px;
      padding-right: 0px;
    }
  }
`;

export const SelectOption = AntdSelect.Option;

export default Select;
