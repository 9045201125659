import {
  GlobalOutlined,
  InstagramFilled,
  MailFilled,
  PhoneFilled,
  TwitterOutlined
} from '@ant-design/icons';
import { Col, message, Row } from 'antd';
import AvatarImg from 'component/Avatar';
import Loader from 'component/Loader';
import LoadingSpin from 'component/LoadingSpin';
import OrderPlanModal from 'component/OrderPlanModal';
import { IConfig, ProviderProfile, SinglePlan } from 'config/interfaces';
import { Context } from 'context/context';
import React, { useContext, useEffect, useState } from 'react';
import http from 'utils/api';
import ServicePlan from './ServicePlan';
import { DashboardWrapper } from './styles';
// import AvatarImg from "component/Avatar";

const ServicePlans = () => {
  const [loading, setLoading] = useState(true);
  const [providerProfile, setProviderProfile] = useState<
    ProviderProfile['data']
  >({} as ProviderProfile['data']);
  const [providerProfileError, setProviderProfileError] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState<SinglePlan>();

  const config: IConfig = JSON.parse(useContext(Context));
  const { providerId } = config || '';

  const {
    business_name,
    address,
    description,
    email,
    phone,
    service_plans,
    website_url,
    type,
    social_media,
    metadata,
    logo_path
  } = providerProfile || {};

  useEffect(() => {
    fetchProviderProfile(providerId);
  }, [providerId]);

  const fetchProviderProfile = async (providerId: any) => {
    await http
      .get(`/services/providers/${providerId}`)
      .then(async (res) => {
        const data = res.data.data;
        setProviderProfile(data);
        setLoading(false);
      })
      .catch((err) => {
        message.error(err?.message || 'An error occured. Please try again');
        setLoading(false);
        setProviderProfileError(err?.message);
      });
  };

  const capitalizeFirstLetter = (string: string) => {
    if (!string) {
      return '';
    }
    return string.toLowerCase().charAt(0).toUpperCase() + string.slice(1);
  };

  const capitaliseWords = (words: string): string => {
    if (!words) {
      return '';
    }
    return words
      .toLowerCase()
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  if (loading) {
    return (
      <DashboardWrapper>
        <Loader />
      </DashboardWrapper>
    );
  }

  return (
    <DashboardWrapper>
      <div>
        <Row align="middle" gutter={20}>
          <Col flex={'none'} className="col2">
            <AvatarImg img={logo_path} fallbackText={business_name} />
          </Col>
          <Col flex={'auto'} className="col1">
            <h1>
              <span className="type-biz">{capitaliseWords(business_name)}</span>
            </h1>

            <p>
              {capitaliseWords(type)}
              <br />
              {description && capitalizeFirstLetter(description)}
              <br />
              <span className="details">
                {`${capitalizeFirstLetter(address?.street_line_one) + ','} ${
                  capitalizeFirstLetter(address?.city) + ','
                } ${
                  capitalizeFirstLetter(address?.state) + ','
                } ${capitalizeFirstLetter(address?.country)}`}
              </span>
            </p>
            <div className="icons">
              {website_url && (
                <a href={website_url} target="_blank" rel="noreferrer">
                  <GlobalOutlined />{' '}
                </a>
              )}

              {email && (
                <a href={`mailto:${email}`}>
                  <MailFilled />{' '}
                </a>
              )}

              {phone && (
                <a href={`tel:${phone}`}>
                  <PhoneFilled />
                </a>
              )}

              {(social_media.instagram || metadata?.social_media.instagram) && (
                <a
                  href={`https://www.instagram.com/${
                    social_media.instagram ?? metadata.social_media?.instagram
                  }`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstagramFilled />
                </a>
              )}

              {(social_media.twitter || metadata?.social_media.twitter) && (
                <a
                  href={`https://twitter.com/${
                    social_media.twitter ?? metadata.social_media.twitter
                  }`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <TwitterOutlined />
                </a>
              )}
            </div>
          </Col>
          {selectedPlan && (
            <OrderPlanModal
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              providerProfile={providerProfile}
            />
          )}
        </Row>
      </div>
      <div className="plans">
        {loading ? (
          <LoadingSpin />
        ) : (
          service_plans &&
          service_plans
            .filter((plan) => plan.public)
            .map((plan) => (
              <ServicePlan
                data={plan}
                actionText={metadata?.pneumapage?.actionText}
                key={plan.id}
                setSelectedPlan={setSelectedPlan}
              />
            ))
        )}
      </div>
    </DashboardWrapper>
  );
};

export default ServicePlans;
