import React from 'react';
import styled from 'styled-components';

export const FieldContainer = styled.div<{ active?: boolean }>`
  width: 100%;
  min-width: 200px;
  max-width: 400px;
  margin-bottom: 8px;
  > * {
    width: 100%;
    padding: 12px;
  }
`;

export const ChoiceFieldContainer = styled(FieldContainer)`
  border-radius: 5px;
  border: 1px solid
    ${({ active, theme }) => (active ? theme.palette.primary.main : '#8a8a8a')};
  color: ${({ active, theme }) =>
    active ? theme.palette.primary.main : undefined};

  > label {
    color: inherit
  }
`;

export const FormBody = styled.div`
  min-height: 100px;
`;
