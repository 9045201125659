import styled from 'styled-components';

const FilePreviewWrapper = styled.div`
  max-height: 200px;
  padding: 0px;
  object-fit: contain;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: inline-block;
  width: auto;
  min-width: 200px;
  max-width: 250px;
  border-radius: 6px;
  img {
    height: 180px;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
  }
  .file-fallback {
    display: flex;
    width: 100%;
    padding: 10px;
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
`;

export { FilePreviewWrapper };