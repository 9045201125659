import React from 'react';
import { Input as AntdInput, Form } from 'antd';
import { TextAreaWrapper } from './styles';

const TextArea = (props: any) => {
  const { formItem, marginBottom, ...rest } = props;
  return (
    <TextAreaWrapper marginBottom={marginBottom}>
      <Form.Item {...formItem}>
        <AntdInput.TextArea {...rest} />
      </Form.Item>
    </TextAreaWrapper>
  );
};

export default TextArea;
