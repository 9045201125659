import { useEffect } from 'react';
import useDataRef from './useDataRef';

export function useWindowResizeListener(callback: (event: UIEvent) => void) {
  const dataRef = useDataRef({ callback });

  useEffect(() => {
    function handleResize(event?: any) {
      if (dataRef.current.callback) {
        dataRef.current.callback(event);
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dataRef]);
}

export default useWindowResizeListener;
