import { Tabs as AntdTabs } from 'antd';
import styled from 'styled-components';

const Tabs = styled(AntdTabs)`
  height: 100%;

  .ant-tabs-content-holder {
    height: 100%;

    .ant-tabs-content {
      height: 100%;
      &.pb-40 {
        padding-bottom: 40px;
      }

      .ant-tabs-tabpane {
        height: 100%;
        overflow: auto;
      }
    }
  }
`;

Tabs.TabPane = AntdTabs.TabPane

export default Tabs;
