import { useContext } from 'react';
import {IConfig} from 'config/interfaces';
import {Context} from 'context/context';

function useAuthUser() {
  const config: IConfig = JSON.parse(useContext(Context));
  return {patientId: config.userId}
}

export default useAuthUser;
