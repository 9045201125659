import React from 'react';
import { Avatar } from 'antd';
import styled from 'styled-components';

const colors = {
  black: '#000000',
  blue: '#0000ff',
  brown: '#a52a2a',
  darkblue: '#00008b',
  darkcyan: '#008b8b',
  darkgreen: '#006400',
  darkmagenta: '#8b008b',
  darkolivegreen: '#556b2f',
  darkorange: '#ff8c00',
  darkorchid: '#9932cc',
  darkred: '#8b0000',
  darksalmon: '#e9967a',
  darkviolet: '#9400d3',
  green: '#008000',
  indigo: '#4b0082',
  maroon: '#800000',
  navy: '#000080',
  olive: '#808000',
  orange: '#ffa500',
  purple: '#800080',
  violet: '#800080',
  red: '#ff0000'
};

const generateDarkColor = (name: any) => {
  const asciiTotal = name?.split('').reduce((acc: any, item: any) => {
    acc += item ? parseInt(item.charCodeAt(0)) : 0;
    return acc;
  }, 0);

  const index = asciiTotal % 28;
  return Object.values(colors)[index] || '#0f9af0';
};

interface AvatarImgProps {
  bg: string;
}

const AvatarImgWrapper = styled(Avatar)<AvatarImgProps>`
  opacity: 1 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  font-size: 2.5rem;
  background: ${(props) => props.bg};
  @media only screen and (max-width: 1100px) {
    width: 100px;
    height: 100px;
    span {
      font-size: 2rem;
    }
  }
  @media only screen and (max-width: 1000px) {
    width: 90px;
    height: 80px;
    span {
      font-size: 1.8rem;
    }
  }
  span {
    opacity: 1 !important;
    &.ant-avatar-string {
      transform: scale(1) translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const AvatarImg = ({ img, fallbackText = '', ...props }: any) => {
  const titleCase = fallbackText
    .split(' ')
    .map((item: any) => item[0])
    .join('');
  const colorCode = generateDarkColor(fallbackText);
  return img ? (
    <img
      className="avatar"
      src={img}
      alt="avatar"
      style={{
        width: '60px',
        height: '80px',
        objectFit: 'contain',
        borderRadius: '100%'
      }}
    />
  ) : (
    <AvatarImgWrapper
      bg={colorCode}
      style={{ backgroundColor: `${colorCode}` }}
      {...props}
    >
      <span style={{ color: '#fff', padding: '10px' }}>{titleCase}</span>
    </AvatarImgWrapper>
  );
};

export default AvatarImg;
