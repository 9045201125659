export const ROUTES = Object.freeze({
  HOMEPAGE: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  TERMS_AND_CONDITIONS: '/terms-conditions',
  RECOVER_PASSWORD: '/password/recover',
  RESET_PASSWORD: '/password/reset',
  REQUEST_OTP: '/otp/request',
  ENTER_OTP: '/otp/enter',
  // DASHBOARD: '/dashboard',
  PROFILE: '/profile',
  PROFILE_EDIT: '/profile/edit',
  MY_HEALTH: '/my-health',
  ENCOUNTERS: '/encounters',
  ENCOUNTER_CONSULTS_NEW: '/encounters/new',
  ENCOUNTER_SERVICES: '/encounters/services',
  ENCOUNTER_CARETEAM: '/encounters/care-team',
  ENCOUNTER_PROVIDERS: '/encounters/providers',
  PATIENT_ENCOUNTERS: '/encounters/:patientId',
  TREATMENTS: '/treatments'
});

export const RouteEnum = Object.freeze({
  HOMEPAGE: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  EMAIL_VERIFICATION: '/email-verification',
  TERMS_AND_CONDITIONS: '/terms-conditions',
  RECOVER_PASSWORD: '/password/recover',
  RESET_PASSWORD: '/password/reset',
  REQUEST_OTP: '/otp/request',
  ENTER_OTP: '/otp/enter',
  // DASHBOARD: '/dashboard',
  PROFILE: '/profile',
  PROFILE_EDIT: '/profile/edit',
  MY_HEALTH: '/my-health',
  ENCOUNTERS: '/',
  ENCOUNTER_CONSULTS: '/encounters/consults',
  ENCOUNTERS_CONSULTS_DETAILS: '/encounters/consults/:id',
  ENCOUNTER_START: '/encounters/start',
  ENCOUNTER_START_QUESTIONS: '/encounters/start/:id',
  ENCOUNTER_SERVICES: '/encounters/services',
  ENCOUNTER_CARETEAM: '/encounters/care-team',
  ENCOUNTER_PROVIDERS: '/encounters/providers',
  PATIENT_ENCOUNTERS: '/encounters/:patientId',
  TREATMENTS: '/treatments',
  MY_DEVICES: '/my-devices',
});
