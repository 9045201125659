import styled from 'styled-components';

interface DatePickerWrapperProps {
  mode?: string;
}

const DatePickerWrapper = styled.div<DatePickerWrapperProps>`
  label,
  .ant-form-item-label {
    font-weight: 500;
    font-size: 13px;
    text-align: left;
    color: #002e46;
    padding: 0px;
  }
  .ant-picker {
    border: none;
    padding: ${(props) => (props.mode === 'normal' ? '10px' : '5px 0px')};
    border-bottom: ${(props) =>
      props.mode === 'normal' ? '' : '1px solid #BFCAD0'};
    border: ${(props) => (props.mode === 'normal' ? '1px solid #BFCAD0' : '')};
    border-radius: ${(props) => (props.mode === 'normal' ? '8px' : '0px')};
    width: 100%;
    &:focus,
    &.ant-picker-focused {
      box-shadow: none;
    }
  }
  .ant-form-item-explain,
  .ant-form-item-extra {
    font-family: 'DM Sans';
    font-weight: normal;
    font-size: 11px;
    text-align: left;
    color: rgba(0, 46, 70, 0.75);
  }
`;

export const TimePickerWrapper = styled.div`
  label,
  .ant-form-item-label {
    font-weight: 500;
    text-align: left;
    color: #002e46;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 0;

    label {
      color: #002e46;

      &::before {
        display: none !important;
      }
    }
  }

  div.ant-picker {
    padding: 11px 14px;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #bfcad0;
    border-radius: 5px;
  }
`;

export { DatePickerWrapper };
