import React from 'react';
import EmptyTabContent from 'component/SidePane/EmptyContent';
import avatar from 'assets/consult-unselected-treatment.svg';

function EmptyConsult(props: any) {
  return (
    <EmptyTabContent
      title="Select a previous consultation to view or start new"
      // description="Please select a consult"
      icon={avatar}
    />
  );
}

export default EmptyConsult;
