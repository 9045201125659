import React from 'react';
import { Radio, Checkbox, Input } from 'antd';
import Typography from 'component/Typography';
import { ChoiceFieldContainer, FieldContainer } from './styles';

export type QuestionResponse = {
  question: number;
  question_text: string;
  response: number | number[];
  value: any;
  next_question: number;
};

export type QuestionFormProps = {
  question: any;
  value: QuestionResponse;
  onChange: (value: QuestionResponse) => void;
};

function QuestionForm(props: QuestionFormProps) {
  const hasHint = props.question.hint && props.question.hint !== 'null';

  const labelProps = { gutterBottom: true, style: { marginBottom: 12 } };

  return (
    <div>
      <Typography {...(hasHint ? {} : labelProps)}>
        {props.question.text}
      </Typography>
      {hasHint ? (
        <Typography {...labelProps} color="textSecondary" variant="body2">
          {props.question.hint}
        </Typography>
      ) : null}
      {getInputs(props)}
    </div>
  );
}

export default QuestionForm;

function getInputs(props: QuestionFormProps) {
  const { question, value, onChange } = props;

  switch (question.type) {
    case 'select_one':
      return (
        <Radio.Group
          style={{ display: 'block' }}
          value={value?.response}
          onChange={(e) => {
            const option = question.response.find(
              (option: any) => option.id === e.target.value
            );
            onChange({
              question: question.id,
              question_text: question.text,
              response: e.target.value,
              value: option.value,
              next_question: option.next_question
            });
          }}
        >
          {question.response.map((option: any) => (
            <ChoiceFieldContainer active={value?.response === option.id}>
              <Radio value={option.id}>{option.value}</Radio>
            </ChoiceFieldContainer>
          ))}
        </Radio.Group>
      );
    case 'select_multiple':
      return (
        <>
          {question.response.map((option: any) => {
            const response = value?.response as number[];
            const responseValue = value?.value as string[];
            const checked = response?.includes(option.id);
            return (
              <ChoiceFieldContainer active={checked} key={option.id}>
                <Checkbox
                  checked={checked}
                  onChange={(e) =>
                    onChange({
                      question: question.id,
                      question_text: question.text,
                      response: e.target.checked
                        ? [...(response || []), option.id]
                        : (() => {
                            const newResponse = [...response];
                            newResponse.splice(response.indexOf(option.id), 1);
                            return newResponse;
                          })(),
                      value: e.target.checked
                        ? [...(responseValue || []), option.value]
                        : (() => {
                            const newResponseValue = [...responseValue];
                            newResponseValue.splice(
                              response.indexOf(option.value),
                              1
                            );
                            return newResponseValue;
                          })(),
                      next_question: option.next_question
                    })
                  }
                >
                  {option.value}
                </Checkbox>
              </ChoiceFieldContainer>
            );
          })}
        </>
      );
    case 'text':
    default:
      return (
        <FieldContainer>
          <Input.TextArea
            autoSize={{ minRows: 1, maxRows: 6 }}
            value={value?.value}
            onChange={(e) =>
              onChange({
                question: question.id,
                question_text: question.text,
                response: question.response?.id,
                value: e.target.value,
                next_question: question.next_question
              })
            }
          />
        </FieldContainer>
      );
  }
}
