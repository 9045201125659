import React, { useState, useEffect, useContext } from 'react';
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import Carousel, { Modal, ModalGateway } from 'react-images';

import { FilePreviewWrapper } from './styles';
import { Skeleton } from 'antd';
import FileIcon from 'assets/icons/file.svg';
import { FirebaseContext } from 'context/firebase';

const FilePreview = (props: any) => {
  const [fileType, setFileType] = useState<any>('');
  const [fileName, setFileName] = useState<any>('');
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const firebaseContext = useContext(FirebaseContext);
  const { firebase } = firebaseContext;

  useEffect(() => {
    firebase
      .storage()
      .refFromURL(props.file.content)
      .getMetadata()
      .then((metaData: any) => {
        setFileName(metaData.name);
        setFileType(metaData.contentType);
      })
      .catch((err: any) => console.error(err));
  }, [props.file.content]);

  function closeViewer() {
    setViewerIsOpen(false);
  }

  const photos = [
    {
      source: props?.file?.content,
      width: 3,
      height: 2,
      title: fileName
    }
  ];

  return (
    <>
      {props.file ? (
        <FilePreviewWrapper className="content">
          {fileType.split('/')[0] === 'image' ? (
            <>
              <img
                src={props.file.content}
                alt="img file"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src =
                    'https://aeroclub-issoire.fr/wp-content/uploads/2020/05/image-not-found-300x225.jpg';
                }}
                onClick={() => setViewerIsOpen(true)}
              />
              {viewerIsOpen && (
                <ModalGateway>
                  {viewerIsOpen ? (
                    <Modal onClose={closeViewer}>
                      <Carousel
                        currentIndex={0}
                        views={photos.map((x) => ({
                          ...x,
                          caption: x.title
                        }))}
                      />
                    </Modal>
                  ) : null}
                </ModalGateway>
              )}
            </>
          ) : fileType.split('/')[0] === 'video' ? (
            <video controls width="250">
              <source src={props.file.content} type={fileType} />
              Sorry, your browser doesn't support embedded videos.
            </video>
          ) : (
            <LinkPreview
              key={props.file.content}
              url={props.file.content}
              customLoader={
                <>
                  <Skeleton.Input
                    style={{ width: 200, height: 200 }}
                    active={true}
                  />
                </>
              }
              fallback={
                <>
                  {fileName ? (
                    <div className="file-fallback">
                      <img src={FileIcon} alt="file" />
                      <a
                        target="_blank"
                        className="fallback"
                        rel="noreferrer"
                        href={props.file.content}
                        style={{ display: 'inline-block', margin: '1em 0' }}
                      >
                        {fileName}
                      </a>
                    </div>
                  ) : (
                    <span
                      className="file-fallback"
                      style={{
                        fontStyle: 'italic',
                        fontSize: '12px',
                        display: 'inline-block',
                        userSelect: 'none'
                      }}
                    >
                      ⚠️ &nbsp; This file has been deleted...
                    </span>
                  )}
                </>
              }
            />
          )}
        </FilePreviewWrapper>
      ) : null}
    </>
  );
};

export default FilePreview;
