import React, { useMemo, useState, useRef, useEffect, useContext } from 'react';
import {
  Col,
  Row,
  Empty,
  Rate,
  message as antdMessage,
  Space,
  Avatar,
  Upload,
  Spin
} from 'antd';
import { VideoCameraOutlined } from '@ant-design/icons';
import {
  ChatMessageContainer,
  ChatMessageHeader,
  ChatMessageContent,
  ChatMessageFooter
} from './styles';
import moment from 'moment';
import Modal from 'component/Modal';
import ChatSend from 'assets/icons/chat-send.svg';
import ChatAttachments from 'assets/icons/chat-attachments.svg';
import { encounterConsultApi } from 'redux/queries/encounter';
import Typography from 'component/Typography';
import * as dfn from 'date-fns';
import MessageItem from './MessageItem';
import ContentLoading from 'component/ContentLoading';
import useLoadingSpin from 'hooks/useLoadingSpin';
import { InputTextArea } from 'common/Input';
import Button from 'common/Button';
import useToggle from 'hooks/useToggle';
import { useTheme } from 'styled-components';
import consultUnselectedMessageSvg from '../../../../../assets/consult-unselected-message.gif';
import { FirebaseContext } from 'context/firebase';

function Messages(props: any) {
  const { consult } = props;
  const { toggleLoadingSpin } = useLoadingSpin();
  const theme = useTheme() as any;

  const contentRef = useRef<HTMLDivElement>(null!);

  const firebaseContext = useContext(FirebaseContext);
  const { firebase } = firebaseContext;
  const storage = firebase.storage();

  const provider = consult?.provider;
  const status = consult?.status;
  const hasEnded = status === 'ended';
  const pending = status === 'pending';
  const isThreadOpen = consult?.thread?.status === 'opened';
  const canChat = !hasEnded && !pending && isThreadOpen;
  const rated = !!consult?.rating;

  const [isRatingModal, toggleRatingModal] = useToggle();

  const [message, setMessage] = useState('');
  const [rating, setRating] = useState({ rating: 0, text: '' });
  const [sendMessageLoading, setSendMessageLoading] = useState(false);

  const { data, isLoading, isFetching, isError, refetch, isUninitialized } =
    encounterConsultApi.useGetEncounterConsultMessagesQuery(
      useMemo(
        () => ({
          path: { consultId: consult?.id, threadId: consult?.thread?.id }
        }),
        [consult?.id, consult?.thread?.id]
      ),
      { skip: pending || !consult }
    );

  const [sendMessageMutation] =
    encounterConsultApi.useSendEncounterConsultMessageMutation();

  const [rateConsultMutation] = encounterConsultApi.useRateConsultMutation();

  const messages = data?.data;

  async function sendMessage(content = message, type = 'text') {
    setSendMessageLoading(true);
    try {
      setMessage('');
      await sendMessageMutation({
        path: { consultId: consult.id, threadId: consult.thread?.id },
        data: { content: content, type }
      });
    } catch (error: object | any) {
      console.log(error);
    } finally {
      setSendMessageLoading(false);
    }
  }

  const handleChange = (e: any) => {
    if (e.target.value === '\n') {
      return;
    }
    setMessage(e.target.value);
  };

  const handleFileUpload = (e: any) => {
    const file = e.file;

    if (file == null) return;

    if (file.size / 1000 > 30000) {
      antdMessage.error(
        'File too large. Please select a file not larger than 30mb'
      );
      return;
    }

    toggleLoadingSpin();
    const uploadTask = storage.ref(`/chats/files/${file.name}`).put(file);

    uploadTask.on(
      'state_changed',
      (snapshot: any) => {
        const progress =
          Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      },
      (error: any) => {
        antdMessage.error(error.message || 'Failed to upload file.');
        toggleLoadingSpin();
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(async (url: any) => {
          sendMessage(url, 'file');
        });
        toggleLoadingSpin();
      }
    );
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && message.trim()) {
      setMessage(message.trim());
      sendMessage(message.trim());
      setMessage('');
    }
  };

  function scrollToBottom() {
    if (contentRef.current) {
      contentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  async function handleRateConsult() {
    toggleLoadingSpin();
    try {
      const data = (await rateConsultMutation({
        path: { consult_id: consult.id },
        data: rating
      }).unwrap()) as any;
      antdMessage.success(data?.message);
    } catch (error: any | object) {
      antdMessage.error(error?.data?.error);
    }
    toggleRatingModal();
    toggleLoadingSpin();
  }

  useEffect(scrollToBottom, [messages]);

  useEffect(() => {
    setRating({
      rating: consult?.rating?.rating || 0,
      text: consult?.rating?.text || ''
    });
  }, [consult]);

  if (consult) {
    return (
      <ContentLoading
        loading={isLoading || isFetching}
        error={isError}
        onReload={refetch}
      >
        {() => (
          <>
            <Modal
              destroyOnClose
              visible={isRatingModal}
              footer={null}
              onCancel={toggleRatingModal}
              width={370}
            >
              <Space
                direction="vertical"
                align="center"
                style={{ width: '100%' }}
                size={16}
              >
                <Typography align="center">
                  {rated ? 'Your rating' : 'Rate your encounter with'}
                </Typography>
                <Space direction="vertical" size={4} align="center">
                  <Avatar
                    style={{
                      color: theme.palette.primary.contrastText,
                      backgroundColor: theme.palette.primary.main,
                      fontSize: 24
                    }}
                    size={50}
                  >
                    {provider?.last_name?.substring(0, 1) || ''}
                  </Avatar>
                  <Typography align="center">
                    {provider?.last_name} {provider?.first_name}
                  </Typography>
                </Space>
                <Rate
                  disabled={rated}
                  value={rating.rating}
                  style={{ color: theme.palette.primary.main }}
                  onChange={(rating) => setRating((p) => ({ ...p, rating }))}
                />
                {rated ? (
                  <Typography align="center">{rating.text}</Typography>
                ) : (
                  <Space direction="vertical" size={4}>
                    <Typography variant="body2">
                      Kindly leave a review about this encounter
                    </Typography>
                    <InputTextArea
                      // underline
                      disabled={rated}
                      autoSize={{ minRows: 4, maxRows: 6 }}
                      bordered={!rated}
                      value={rating.text}
                      placeholder="Write review here"
                      onChange={(e) =>
                        setRating((p) => ({ ...p, text: e.target.value }))
                      }
                    />
                  </Space>
                )}
                {!rated && (
                  <Button type="primary" onClick={handleRateConsult}>
                    Submit
                  </Button>
                )}
              </Space>
            </Modal>
            <ChatMessageContainer>
              <ChatMessageHeader>
                <Row align="middle" gutter={16} wrap={false}>
                  <Col flex={1} />
                  <Col>
                    <Typography>
                      {dfn.format(
                        new Date(consult?.created_at),
                        'MMMM d, yyyy'
                      )}
                    </Typography>
                  </Col>
                  <Col flex={1} />
                  {canChat && (
                    <Col>
                      <a
                        rel="noopener noreferrer"
                        onClick={() => {
                          sendMessage(
                            `I'd love for us to have a video/audio chat about my complaints. Please let me into your virtual your consultation room. ${consult?.consultation_room}`
                          );
                        }}
                        href={
                          `https://${consult?.consultation_room}` ||
                          `https://meet.jit.si/pneumaconsults/${consult.id}`
                        }
                        target="_blank"
                      >
                        <Button
                          type="ghost"
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          Start video <VideoCameraOutlined />
                        </Button>
                      </a>
                    </Col>
                  )}
                </Row>
              </ChatMessageHeader>
              <ChatMessageContent>
                {messages?.length && !isUninitialized ? (
                  [...messages]
                    .sort((a: any, b: any): any =>
                      moment(a.created_at).diff(moment(b.created_at))
                    )
                    .map((message: any, index: number) => (
                      <MessageItem key={index} data={message} />
                    ))
                ) : (
                  <Row
                    align="middle"
                    justify="center"
                    style={{ height: '100%' }}
                  >
                    <Col>
                      <Empty
                        imageStyle={{
                          height: 240
                        }}
                        image={consultUnselectedMessageSvg}
                        description={
                          <Typography>
                            {pending
                              ? 'Your consult request is currently pending'
                              : hasEnded
                              ? 'Encounter ended with empty messages'
                              : 'Chat has not started yet'}
                          </Typography>
                        }
                      />
                    </Col>
                  </Row>
                )}
                <div ref={contentRef} />
              </ChatMessageContent>
              {canChat && (
                <ChatMessageFooter>
                  <Row align="middle">
                    <Col>
                      <Upload
                        showUploadList={false}
                        accept="image/*,.pdf, .csv, .txt, video/*"
                        beforeUpload={(file) => {
                          return false;
                        }}
                        onChange={handleFileUpload}
                      >
                        <Button shape="circle" type="text">
                          <img src={ChatAttachments} alt="Chat Attachments" />
                        </Button>
                      </Upload>
                    </Col>
                    <Col flex={1}>
                      <InputTextArea
                        maxLength={256}
                        autoSize={{ minRows: 1, maxRows: 1 }}
                        bordered={false}
                        placeholder="Enter message here"
                        value={message}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        onSubmit={() => sendMessage()}
                      />
                    </Col>
                    <Col>
                      <Button shape="circle" type="text">
                        {sendMessageLoading ? (
                          <span
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Spin />
                          </span>
                        ) : (
                          <img
                            src={ChatSend}
                            alt="Chat send btn"
                            onClick={() => sendMessage()}
                          />
                        )}
                      </Button>
                    </Col>
                  </Row>
                </ChatMessageFooter>
              )}
              {hasEnded && (
                <ChatMessageFooter>
                  <Row
                    gutter={[16, 16]}
                    align="middle"
                    justify="center"
                    style={{ height: '100%' }}
                  >
                    <Col>
                      {rated ? (
                        <Space direction="vertical" align="center" size={4}>
                          <Rate
                            disabled
                            value={rating.rating}
                            style={{ color: theme.palette.primary.main }}
                          />
                          <Typography align="center">{rating.text}</Typography>
                        </Space>
                      ) : (
                        <Button type="primary" onClick={toggleRatingModal}>
                          {/* {rated ? 'View Rating' : 'Rate this encounter'} */}
                          Rate this encounter
                        </Button>
                      )}
                    </Col>
                  </Row>
                </ChatMessageFooter>
              )}
            </ChatMessageContainer>
          </>
        )}
      </ContentLoading>
    );
  }
  return <Empty image={consultUnselectedMessageSvg} />;
}

export default Messages;
