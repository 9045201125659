import Tabs from 'component/Tabs';
import React, { useState } from 'react';
import MyHealthLabSidePaneOrderTest from './MyHealthLabSidePaneOrderTest';
import MyHealthLabSidePaneTrackOrder from './MyHealthLabSidePaneTrackOrder';

function MyHealthLabSidePane(props: any) {
  const [activeTab, setActiveTab] = useState('1');

  return (
      <Tabs
        defaultActiveKey="1"
        activeKey={activeTab}
        onChange={(key) => setActiveTab(key)}
        style={{padding: 20}}
      >
        <Tabs.TabPane tab="Order investigations" key="1">
          <MyHealthLabSidePaneOrderTest setActiveTab={setActiveTab} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Previous requests" key="2">
          <MyHealthLabSidePaneTrackOrder />
        </Tabs.TabPane>
      </Tabs>
  );
}

export default MyHealthLabSidePane;
