import { Tag as AntdTag } from 'antd';
import styled from 'styled-components';

const Tag = styled(AntdTag)(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: '4px 12px',
  marginRight: '0px',
  borderRadius: '24px',
  backgroundColor: theme.palette.action.selected,
  ...theme.typography.body2
}));

export default Tag;
