import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from '../store';
import http from '../../utils/api';

export interface AuthError {
  message: string;
}

export interface AuthState {
  isAuth: boolean;
  currentUser?: any;
  isLoading: boolean;
  error: AuthError;
  isAccountActivationLoading: boolean;
  isAccountActivationError: AuthError;
}

export interface CurrentUser {
  id: string;
  display_name: string;
  email: string;
  photo_url: string;
}
export const initialState: AuthState = {
  isAuth: false,
  isLoading: false,
  error: { message: '' },
  isAccountActivationLoading: false,
  isAccountActivationError: { message: "" },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setAuthSuccess: (state, payload: PayloadAction<CurrentUser>) => {
      state.currentUser = payload;
      state.isAuth = true;
    },
    setLogOut: (state) => {
      state.isAuth = false;
      state.currentUser = undefined;
    },
    setAuthFailed: (state, { payload }: PayloadAction<AuthError>) => {
      state.error = payload;
      state.isAuth = false;
    },
    requestAccountActivationLoading: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isAccountActivationLoading = payload;
    },
    requestAccountActivationSuccess: (
      state,
      { payload }: PayloadAction<any>
    ) => {},
    requestAccountActivationFailed: (
      state,
      { payload }: PayloadAction<AuthError>
    ) => {
      state.isAccountActivationError = payload;
    },
  }
});

export const {
  setAuthSuccess, setLogOut, setLoading, setAuthFailed, 
  requestAccountActivationLoading,
  requestAccountActivationSuccess,
  requestAccountActivationFailed,
} =
  authSlice.actions;
export const authSelector = (state: { auth: AuthState }) => state.auth;
export default authSlice.reducer;

/** Actions */

export const loginProvider =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    await http
      .post('/login', payload)
      .then((res) => {
        const { token: accessToken, user: currentUser } = res?.data?.data || {};
        if (accessToken) {
          window.localStorage.setItem('accessToken', accessToken);
          dispatch(setAuthSuccess(currentUser));
          // window.open('/my-health', '_self');
        }
      })
      .catch((err) => {
        console.log('err..', err?.response?.data);
        const message = {
          message: err?.response?.data?.message || err?.response?.data?.error
        };
        dispatch(setAuthFailed(message));
      });
    dispatch(setLoading(false));
  };

export const registerProvider =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    await http
      .post('/register', payload)
      .then((res) => {
        const { jwt: accessToken, user: currentUser } = res?.data || {};
        window.localStorage.setItem('accessToken', accessToken);
        dispatch(setAuthSuccess(currentUser));
        window.open('/my-health', '_self');
      })
      .catch((err) => {
        console.log('err..', err?.response?.data);
        const message = { message: err?.response?.data?.message };
        dispatch(setAuthFailed(message));
      });
    dispatch(setLoading(false));
  };

export const logOutProvider = (): AppThunk => async (dispatch) => {
  dispatch(setLogOut());
  window.localStorage.removeItem('accessToken');
  window.localStorage.removeItem('_profile');
  window.location.replace('/');
};
