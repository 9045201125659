import { useState, useRef } from 'react';
import useWindowResizeListener from './useWindowResizeListener';

function useBoundingClientRect() {
  const ref = useRef<HTMLElement>(null!);
  const [rect, setRect] = useState<DOMRect>(undefined!);

  useWindowResizeListener(() => {
    setRect(ref.current.getBoundingClientRect());
  });

  return [ref, rect] as const;
}

export default useBoundingClientRect;
