import React from "react";
import { Grid, Drawer, Row } from 'antd';
import { Container, PaneFabToggle, Content, Pane } from './styles';
import { LeftOutlined } from '@ant-design/icons';
import useToggle from 'hooks/useToggle';

export type ScreenLayoutProps = {
  pane?: any;
  children?: any;
  fabIcon?: any;
  className?: string;
};

function ScreenLayout(props: ScreenLayoutProps) {
  const { pane, children, fabIcon, className } = props;

  const [isPane, togglePane] = useToggle();

  const paneFabToggleIcon = fabIcon || <LeftOutlined />;

  const breakpoints = Grid.useBreakpoint();
  const isXl = breakpoints.xl;

  return (
    <Container>
      <Row gutter={16} style={{ height: '100%' }}>
        <Content span={isXl && pane ? 16 : 24} className={className}>
          {children}
        </Content>
        {isXl && pane ? (
          <Pane span={isXl && pane ? 8 : 0}>{pane}</Pane>
        ) : (
          <Drawer
            bodyStyle={{ padding: 0 }}
            contentWrapperStyle={{
              width: 'calc(100% - 76px)',
              minWidth: 300,
              maxWidth: 400
            }}
            visible={isPane}
            onClose={togglePane}
          >
            {pane}
          </Drawer>
        )}
        {!isXl && pane && (
          <PaneFabToggle size="large" onClick={togglePane}>
            {paneFabToggleIcon}
          </PaneFabToggle>
        )}
      </Row>
    </Container>
  );
}

export default ScreenLayout;
