import React from 'react';
import { Space } from 'antd';
import { ChatMessageItemContainer } from './styles';
import Typography from 'component/Typography';
import Linkify from 'react-linkify';
import * as dfn from 'date-fns';
import { urlify } from 'utils/global';
import { LinkPreview } from '@dhaiwat10/react-link-preview';

import FilePreview from 'component/FilePreview';

function MessageItem(props: any) {
  const { content, sender, created_at, type, id } = props.data;
  const isProvider = sender.type === 'provider';
  // const align = isProvider ? 'left' : 'right';
  const [_, urls] = urlify(content);

  const extras = [
    <Typography key="1" variant="body2">
      {sender?.first_name} {sender?.last_name}
    </Typography>,
    <Typography key="2" color="textSecondary" variant="body2">
      {dfn.format(new Date(created_at), 'KK:mm a')}
    </Typography>
  ];
  extras.sort((a, b) => (isProvider ? 1 : -1));

  return (
    <ChatMessageItemContainer isProvider={isProvider} contentType={type}>
      <div>
        <Space wrap={false}>{extras}</Space>
      </div>
      {type === 'file' ? (
        <FilePreview file={{ content, id }} />
      ) : (
        <div className="content">
          <Space direction="vertical" style={{ width: '100%' }}>
            {urls.map((url) => (
              <LinkPreview
                key={url}
                url={url}
                imageHeight={50}
                className="link-preview"
                showLoader={true}
              />
            ))}
          </Space>
          <div className="text-content">
            <Linkify
              componentDecorator={(
                decoratedHref: any,
                decoratedText: any,
                key: any
              ) => (
                <a target="blank" href={decoratedHref} key={key}>
                  {decoratedText}
                </a>
              )}
            >
              {content}
            </Linkify>
          </div>
        </div>
      )}
    </ChatMessageItemContainer>
  );
}

export default MessageItem;
