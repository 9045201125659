import React from 'react';
import {
  DatePicker as AntdDatePicker,
  Form,
  TimePicker as AntdTimePicker
} from 'antd';
import { DatePickerWrapper, TimePickerWrapper } from './styles';

const DatePicker = (props: any) => {
  const { formItem, mode, ...rest } = props;
  return (
    <DatePickerWrapper mode={mode}>
      <Form.Item {...formItem}>
        <AntdDatePicker {...rest} />
      </Form.Item>
    </DatePickerWrapper>
  );
};

export const TimePicker = (props: any) => {
  const { formItem, mode, ...rest } = props;
  return (
    <TimePickerWrapper>
      <Form.Item {...formItem}>
        <AntdTimePicker {...rest} />
      </Form.Item>
    </TimePickerWrapper>
  );
};
export default DatePicker;
