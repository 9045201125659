import connectApi from './connect-api';
import { EncounterRTKQueryTagEnum } from 'constants/redux';
import { invalidatesList, providesList } from 'utils/redux-toolkit-query';
import Pusher from 'pusher-js';
import { message as AntdMessage } from 'antd';

export const encounterStartApi = connectApi.injectEndpoints({
  endpoints: (builder) => ({
    getEncounterComplaintForm: builder.query({
      query: ({ path }) => ({ url: `/telehealth/forms/${path.id}` })
    }),
    sumbitEncounterComplaintForm: builder.mutation({
      query: ({ data }) => ({
        url: `/telehealth/forms/answer`,
        method: 'POST',
        data
      })
    })
  })
});

export const encounterConsultApi = connectApi.injectEndpoints({
  endpoints: (builder) => ({
    getEncounterConsults: builder.query({
      query: ({ userId }) => ({
        url: `/consults/user/${userId}`
      }),
      onQueryStarted: async (
        _,
        { getState, queryFulfilled, updateCachedData }
      ) => {
        try {
          await queryFulfilled;
          updateCachedData((draft) => {
            const { id } = (getState().global as any).user;
            draft.data = draft.data.filter((data: any) => {
              return data.user.id === id;
            });
          });
        } catch (error: object | any) {
          console.log(error);
        }
      },
      providesTags: (data) =>
        providesList(EncounterRTKQueryTagEnum.CONSULT, data?.data) as any
    }),
    createEncounterConsult: builder.mutation({
      query: ({ data }) => ({ url: `/consults`, method: 'POST', data }),
      invalidatesTags: invalidatesList(EncounterRTKQueryTagEnum.CONSULT) as any
    }),
    getEncounterConsultMessages: builder.query({
      query: ({ path }) => ({ url: `/consults/${path.consultId}/messages` }),
      async onCacheEntryAdded(
        { path },
        {
          updateCachedData,
          cacheDataLoaded,
          cacheEntryRemoved,
          getState,
          dispatch
        }
      ) {
        const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY!, {
          cluster: process.env.REACT_APP_PUSHER_CLUSTER!,
          authEndpoint: process.env.REACT_APP_PUSHER_AUTH_ENDPOINT!
        });

        try {
          await cacheDataLoaded;

          Pusher.log = (msg) => {
            console.log(msg);
          };

          const channel = pusher.subscribe(path.consultId);

          channel.bind('messages', (data: any) => {
            const result =
              encounterConsultApi.endpoints.getEncounterConsults.select(
                undefined
              )(getState());

            const consult = result.data?.data?.find(
              (item: any) => item.id === path.consultId
            );

            if (data?.sender_model) {
              data.sender.type = data?.sender_model;
            }

            if (!consult?.thread) {
              dispatch(
                encounterConsultApi.util.invalidateTags(
                  invalidatesList(EncounterRTKQueryTagEnum.CONSULT) as any
                )
              );
            }
            updateCachedData((draft) => {
              draft.data.push(data);
            });
          });
        } catch (error: object | any) {}

        await cacheEntryRemoved;
        pusher.disconnect();
      }
    }),
    onEncounterConsultStatusChange: builder.query({
      queryFn: () => ({ data: '' }),
      onCacheEntryAdded: async (
        { path },
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, dispatch }
      ) => {
        const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY!, {
          cluster: process.env.REACT_APP_PUSHER_CLUSTER!,
          authEndpoint: process.env.REACT_APP_PUSHER_AUTH_ENDPOINT!
        });

        try {
          await cacheDataLoaded;
          const channel = pusher.subscribe(`consult-${path.consultId}`);

          channel.bind('consult_accepted', (data: any) => {
            dispatch(
              encounterConsultApi.util.invalidateTags(
                invalidatesList(EncounterRTKQueryTagEnum.CONSULT) as any
              )
            );

            AntdMessage.success('Consult request accepted.');
          });

          channel.bind('consult_rejected', (data: any) => {
            dispatch(
              encounterConsultApi.util.invalidateTags(
                invalidatesList(EncounterRTKQueryTagEnum.CONSULT) as any
              )
            );

            AntdMessage.warning('Consult request rejected.');
          });
        } catch (error: object | any) {}

        await cacheEntryRemoved;
        pusher.disconnect();
      }
    }),
    sendEncounterConsultMessage: builder.mutation({
      query: ({ path, data }) => ({
        url: `/consults/${path.consultId}/messages`,
        method: 'POST',
        data
      })
    }),
    markEncounterConsultComplete: builder.mutation({
      query: ({ path }) => ({
        url: `/consults/${path.consult_id}/treatment_completed`,
        method: 'PATCH'
      }),
      invalidatesTags: invalidatesList(EncounterRTKQueryTagEnum.CONSULT) as any
    }),
    rateConsult: builder.mutation({
      query: ({ path, data }) => ({
        url: `/consults/${path.consult_id}/ratings`,
        data,
        method: 'POST'
      }),
      invalidatesTags: invalidatesList(EncounterRTKQueryTagEnum.CONSULT) as any
    }),
    onPrescriptionAddition: builder.query({
      queryFn: () => ({ data: '' }),
      onCacheEntryAdded: async (
        { path },
        { cacheDataLoaded, cacheEntryRemoved, dispatch }
      ) => {
        // initialize new Pusher instance
        const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY!, {
          cluster: process.env.REACT_APP_PUSHER_CLUSTER!,
          authEndpoint: process.env.REACT_APP_PUSHER_AUTH_ENDPOINT!
        });

        try {
          await cacheDataLoaded;

          //setup pusher subscription and bind pusher events accordingly
          const channel = pusher.subscribe(`consult-${path.consultId}`);

          channel.bind('consult.medications.created', (data: any) => {
            dispatch(
              encounterConsultApi.util.invalidateTags(
                invalidatesList(EncounterRTKQueryTagEnum.CONSULT) as any
              )
            );
            AntdMessage.success('Prescription added');
          });
          channel.bind('consult.medications.deleted', (data: any) => {
            dispatch(
              encounterConsultApi.util.invalidateTags(
                invalidatesList(EncounterRTKQueryTagEnum.CONSULT) as any
              )
            );
            AntdMessage.success('Prescription deleted');
          });
        } catch (error: object | any) {}

        await cacheEntryRemoved;
        pusher.disconnect();
      }
    }),
    onLabInvestigationAddition: builder.query({
      queryFn: () => ({ data: '' }),
      onCacheEntryAdded: async (
        { path },
        { cacheDataLoaded, cacheEntryRemoved, dispatch }
      ) => {
        // initialize new Pusher instance
        const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY!, {
          cluster: process.env.REACT_APP_PUSHER_CLUSTER!,
          authEndpoint: process.env.REACT_APP_PUSHER_AUTH_ENDPOINT!
        });

        try {
          await cacheDataLoaded;

          //setup pusher subscription and bind pusher events accordingly
          const channel = pusher.subscribe(`consult-${path.consultId}`);

          channel.bind('consult.investigation.created', (data: any) => {
            dispatch(
              encounterConsultApi.util.invalidateTags(
                invalidatesList(EncounterRTKQueryTagEnum.CONSULT) as any
              )
            );
            AntdMessage.success('Lab investigation added');
          });
          channel.bind('consult.investigation.deleted', (data: any) => {
            dispatch(
              encounterConsultApi.util.invalidateTags(
                invalidatesList(EncounterRTKQueryTagEnum.CONSULT) as any
              )
            );
            AntdMessage.success('Lab investigation removed');
          });
        } catch (error: object | any) {}

        await cacheEntryRemoved;
        pusher.disconnect();
      }
    }),
    onRecommendationAddition: builder.query({
      queryFn: () => ({ data: '' }),
      onCacheEntryAdded: async (
        { path },
        { cacheDataLoaded, cacheEntryRemoved, dispatch }
      ) => {
        // initialize new Pusher instance
        const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY!, {
          cluster: process.env.REACT_APP_PUSHER_CLUSTER!,
          authEndpoint: process.env.REACT_APP_PUSHER_AUTH_ENDPOINT!
        });
        try {
          await cacheDataLoaded;

          //setup pusher subscription and bind pusher events accordingly
          const channel = pusher.subscribe(`consult-${path.consultId}`);

          channel.bind('consult.othertreament.created', (data: any) => {
            dispatch(
              encounterConsultApi.util.invalidateTags(
                invalidatesList(EncounterRTKQueryTagEnum.CONSULT) as any
              )
            );
            AntdMessage.success('Recommendation added');
          });
          channel.bind('consult.othertreament.deleted', (data: any) => {
            dispatch(
              encounterConsultApi.util.invalidateTags(
                invalidatesList(EncounterRTKQueryTagEnum.CONSULT) as any
              )
            );
            AntdMessage.success('Recommendation removed');
          });
        } catch (error: object | any) {}

        await cacheEntryRemoved;
        pusher.disconnect();
      }
    })
  })
});

encounterConsultApi.enhanceEndpoints({
  addTagTypes: Object.values(EncounterRTKQueryTagEnum)
});
