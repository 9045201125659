import React from 'react';
import styled from 'styled-components';
import { Divider as AntdDivider, DividerProps as AntdDividerProps } from 'antd';

export type DividerProps = { spacing?: string | number } & AntdDividerProps;

const Divider = styled(function Divider(props: DividerProps) {
  return <AntdDivider {...props} />;
})(({ spacing }) => ({
  '&.ant-divider-horizontal': {
    ...(spacing ? { margin: `${spacing}px 0px` } : {})
  },
  '&.ant-divider-vertical': {
    ...(spacing ? { margin: `0px ${spacing}px` } : {})
  }
}));

Divider.defaultProps = {
  spacing: 8
};

export default Divider;
