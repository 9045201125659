import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const ListContainer = styled.div`
  height: 100%;
  overflow: auto;
`;

export const ListItemContainer = styled(NavLink)`
  display: block;
  padding: 8px;
  height: 100%;
  border-left: 2px solid
    ${(props: any) =>
      props.theme.palette[
        props.status === 'pending'
          ? 'warning'
          : props.status === 'rejected'
          ? 'error'
          : props.status === 'ended'
          ? 'success'
          : 'primary'
      ].main};

  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }

  &.active {
    background-color: ${({ theme }) => theme.palette.action.selected};
  }
`;

export const NoteContainer = styled.div`
  padding: 16px;
  height: 100%;
  overflow: auto;
`;

export const ChatMessageContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ChatMessageHeader = styled.div(({ theme }) => ({
  height: 40,
  borderBottom: `1px solid ${theme.palette.divider}`
}));

export const ChatMessageFooter = styled.div(({ theme }) => ({
  maxHeight: 200,
  overflow: 'auto',
  borderTop: `1px solid ${theme.palette.divider}`,
  padding: '16px 8px 8px',
  backgroundColor: 'white'
}));

export const ChatMessageContent = styled.div`
  flex: 1;
  padding: 16px;
  overflow: auto;
`;

export const ChatMessageItemContainer = styled.div<{ isProvider: boolean, contentType?: string }>`
  text-align: ${({ isProvider }) => (isProvider ? 'left' : 'right')};
  white-space: pre-line;
  word-wrap: break-word;
  margin-bottom: 16px;

  .content {
    overflow: hidden;
    display: inline-block;
    max-width: 80%;
    width: auto;
    min-width: 50%;
    position: relative;
    background: ${({ isProvider }) => !isProvider ? "#188bf1 " :  "#fff "}
      0% 0% no-repeat padding-box;
    color: ${({ isProvider }) => (!isProvider ? "#fff " : "#002E46 ")} !important;
    
    border: 1px solid #188bf1;

    padding: 8px 25px 8px 13px;
    padding: ${({ contentType }) => contentType !== "file" ? "5px 5px 5px" : "0px"};
    border-radius: ${({ isProvider }) => (!isProvider ? '0px 5px' : '5px 0px')}
      5px 5px;

    .action {
      position: absolute;
      top: 0px;
      right: 10px;
    }

    a {
      word-break: break-all;
      white-space: pre-line;
      color: ${({isProvider}) => (!isProvider ? "#fff " : "#002E46 ")};
    }

    .link-preview {
      border: none;
    }

    .text-content {
      padding: 5px 10px;
      color: ${({ isProvider }) => (!isProvider ? "#fff " : "#002E46 ")} !important;
    }

    .fallback {
      background: #fff;
      width: 100%;
      padding: 10px;
      border-radius: 6px;
    }

    .link-preview {
      .Title {
        ${({ theme: { typography } }) => `
        font-family: ${typography.body1.fontFamily};
        font-weight: ${typography.body1.fontWeight};
        font-size: ${typography.body1.fontSize};
        line-height: ${typography.body1.lineHeight};
        letter-spacing: ${typography.body1.letterSpacing};
        `}
      }

      .Secondary {
        ${({ theme: { typography } }) => `
        font-family: ${typography.body2.fontFamily};
        font-weight: ${typography.body2.fontWeight};
        font-size: ${typography.body2.fontSize};
        line-height: ${typography.body2.lineHeight};
        letter-spacing: ${typography.body2.letterSpacing};
        `}
      }
    }
  }
`;
