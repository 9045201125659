import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Divider, Row, Col, Space, Progress, message } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Container } from '../styles';
import { FormBody } from './styles';
import Typography from 'component/Typography';
import Button from 'common/Button';
import {
  encounterStartApi,
  encounterConsultApi
} from 'redux/queries/encounter';
import ContentLoading from 'component/ContentLoading';
import QuestionForm, { QuestionResponse } from './Form';
import ScreenLayout from 'layouts/ScreenLayout';
import useLoadingSpin from 'hooks/useLoadingSpin';
import { RouteEnum } from 'constants/routes';
import { generatePath } from 'react-router-dom';
import { AppStartContext } from 'context/appStart';
import { usePaystackPayment } from 'react-paystack';
import { IConfig } from 'config/interfaces';
import { Context } from 'context/context';
import { profileApi } from 'redux/queries/profile';

function Questions(props: any) {
  const { history, match } = props;
  const { id } = match.params;

  const { toggleLoadingSpin } = useLoadingSpin();

  const config: IConfig = JSON.parse(useContext(Context));
  const { paystackKey, userId, consultationFee } = config || {};

  const { data: profile } = profileApi.useGetPatientProfileQuery({ userId });

  const userProfile = profile?.data;

  const { email } = userProfile || {};

  const appStart = useContext(AppStartContext);
  useEffect(() => {
    if (appStart?.updateAppStart) {
      appStart?.updateAppStart(false);
    }
  }, [appStart]);

  const [responses, setResponses] = useState(
    {} as { [key: string]: QuestionResponse }
  );
  const [questionIds, setQuestionIds] = useState([1]);
  const currentQuestionId = questionIds[questionIds.length - 1];

  const { isLoading, isError, data, refetch } =
    encounterStartApi.useGetEncounterComplaintFormQuery(
      useMemo(() => ({ path: { id } }), [id])
    );

  const [submitMutation] =
    encounterStartApi.useSumbitEncounterComplaintFormMutation();

  const [createConsultMutation] =
    encounterConsultApi.useCreateEncounterConsultMutation();

  const complaint = data?.data;
  const normalizedQuestions = useMemo(
    () =>
      complaint?.questions?.reduce((acc: any, curr: any) => {
        acc[curr.id] = curr;
        return acc;
      }, {}),
    [complaint?.questions]
  );

  function handleQuestionFormChange(response: QuestionResponse) {
    setResponses((p) => ({ ...p, [currentQuestionId]: response }));
  }

  async function handleSubmit() {
    toggleLoadingSpin();
    try {
      if (consultationFee) {
        await initializePayment((ref: any) => onSuccess(), onClose);
      } else {
        onSuccess();
      }
    } catch (error: object | any) {
      console.log(error);

      message.error(error?.data?.error);
    }

    toggleLoadingSpin();
  }

  const paystackConfig = {
    reference: new Date().getTime().toString(),
    email,
    amount: consultationFee ? consultationFee * 100 : 0,
    publicKey: paystackKey || ''
  };
  const initializePayment = usePaystackPayment(paystackConfig);

  const onSuccess = async () => {
    message.info('Submitting responses');
    const commonPayload = { user: userId, form: id };
    const answerResponseData = (await submitMutation({
      path: { id },
      data: {
        ...commonPayload,
        responses: Object.values(responses).map((response: any) => {
          const newResponse = { ...response };
          delete newResponse.next_question;
          return newResponse;
        })
      }
    }).unwrap()) as any;

    message.success(answerResponseData?.message);
    //@TODO load paystack
    message.info('Creating consult');
    const consultResponseData = (await createConsultMutation({
      data: {
        ...commonPayload,
        answer: answerResponseData?.data?.id
      }
    }).unwrap()) as any;
    message.success(consultResponseData?.message);
    history.push(
      generatePath(RouteEnum.ENCOUNTERS_CONSULTS_DETAILS, {
        id: consultResponseData.data.id
      })
    );
  };

  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log('closed');
  };

  function handleNextQuestion() {
    const currentQuestion = normalizedQuestions[currentQuestionId];

    if (
      responses &&
      !responses[currentQuestionId]?.value &&
      currentQuestion?.required === false
    ) {
      handleQuestionFormChange({
        question: currentQuestion.id,
        question_text: currentQuestion.text,
        response: currentQuestion.response?.id,
        value: 'null',
        next_question: currentQuestion?.next_question
      });

      setQuestionIds((p) => {
        return [...p, currentQuestion.next_question];
      });
    } else {
      setQuestionIds((p) => {
        return [...p, responses[currentQuestionId].next_question];
      });
    }
  }

  return (
    <ScreenLayout>
      <Container>
        <ContentLoading loading={isLoading} error={isError} onReload={refetch}>
          {() => (
            <>
              <Typography variant="h6" weight="bold">
                {complaint?.name}
              </Typography>
              <Divider style={{ marginTop: 8 }} />
              <FormBody>
                <QuestionForm
                  question={normalizedQuestions[currentQuestionId]}
                  value={responses[currentQuestionId]}
                  onChange={handleQuestionFormChange}
                />
                {normalizedQuestions[currentQuestionId].image && (
                  <img
                    src={normalizedQuestions[currentQuestionId].image}
                    alt=""
                  />
                )}
              </FormBody>
              <Divider />
              <Row gutter={[16, 16]} align="middle">
                <Col sm={8} flex={1}>
                  <Typography color="textSecondary">
                    Question {currentQuestionId} of{' '}
                    {complaint?.number_of_questions}
                  </Typography>
                  <Progress
                    size="small"
                    showInfo={false}
                    percent={
                      (100 / complaint?.number_of_questions) * currentQuestionId
                    }
                  />
                </Col>
                <Col>
                  <Space>
                    <Button
                      disabled={currentQuestionId === 1}
                      type="primary"
                      onClick={() =>
                        setQuestionIds((p) => {
                          const result = [...p];
                          result.pop();
                          return result;
                        })
                      }
                    >
                      <LeftOutlined />
                    </Button>
                    {normalizedQuestions[currentQuestionId]?.id ===
                    complaint?.number_of_questions ? (
                      <Button
                        type="primary"
                        disabled={
                          normalizedQuestions[currentQuestionId]?.required &&
                          !responses[currentQuestionId]?.value
                        }
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    ) : (
                      <Button
                        disabled={
                          normalizedQuestions[currentQuestionId]?.required &&
                          !responses[currentQuestionId]?.value
                        }
                        type="primary"
                        onClick={handleNextQuestion}
                      >
                        Next
                        <RightOutlined />
                      </Button>
                    )}
                  </Space>
                </Col>
              </Row>
            </>
          )}
        </ContentLoading>
      </Container>
    </ScreenLayout>
  );
}

export default Questions;
