export function getObjectWithDotString(obj: any, desc: string) {
  const arr = desc.split('.');
  let result = obj;
  while (arr.length && (result = result[arr.shift()!]));
  return result;
}

export function removeEmptyFields(
  values: any,
  options: { allowEmptyArray?: boolean } = {}
) {
  const newTarget = (
    Array.isArray(values) ? [] : isObject(values) ? {} : values
  ) as any;
  if (typeof newTarget === 'object') {
    for (const key in values) {
      const value = values[key];
      if (
        (Array.isArray(value) && (options.allowEmptyArray || value.length)) ||
        (isObject(value) && Object.entries(value).length !== 0)
      ) {
        newTarget[key] =
          value instanceof File ? value : removeEmptyFields(value);
      } else if (value && !Array.isArray(value) && !isObject(value)) {
        newTarget[key] = removeEmptyFields(value);
      }
    }
  }
  return newTarget;
}

export function isObject(item: any) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

export function urlify(text: string, replacer?: Function) {
  const urls: string[] = [];
  // var urlRegex = /(https?:\/\/[^\s]+)/g;
  var urlRegex =
    /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  const replacedText = text.replace(urlRegex, handleReplacer as any);

  return [replacedText, urls] as const;
  // or alternatively
  // return text.replace(urlRegex, '<a href="$1">$1</a>')

  function handleReplacer(url: string) {
    const normalizedUrl = /^\w+(?=(:\/\/))/.test(url) ? url : `https://${url}`;
    urls.push(normalizedUrl);
    return (
      replacer?.(url) ||
      '<a href="' + normalizedUrl + '" target="_blank">' + url + '</a>'
    );
  }
}

export function normalizeDateString(date: string = '') {
  const split = date?.split('/');
  if (split.length) {
    return `${split[1]}/${split[0]}/${split[2]}`;
  }
  return '';
}

export async function hmac(key: string, message: string) {
  const g = (str: string) =>
      new Uint8Array(
        [...(unescape(encodeURIComponent(str)) as any)].map((c) =>
          c.charCodeAt(0)
        )
      ),
    k = g(key),
    m = g(message),
    c = await crypto.subtle.importKey(
      'raw',
      k,
      { name: 'HMAC', hash: 'SHA-256' },
      true,
      ['sign']
    ),
    s = await crypto.subtle.sign('HMAC', c, m);
  [...(new Uint8Array(s) as any)]
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  return btoa(String.fromCharCode(...(new Uint8Array(s) as any)));
}
