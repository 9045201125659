import styled from 'styled-components';
import Modal from 'component/Modal'
import Card from 'common/Card';

const VCard = styled(Card)`
  cursor: pointer;

  .ant-card-body {
    padding: 16px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 270px;
  }

  /* .CircularProgressbar {
    width: 50%;
    font-weight: bold;
  } */

  /* .ant-progress-steps-item-active {
    border-radius: 50%;
    width: 8px !important;
    height: 8px !important;
    margin: 2px 3px;
  }

  .ant-progress-status-success .ant-progress-text {
    display: none;
  } */
`;

const ModalContainer = styled(Modal)`
  .ant-modal-close {
    display: none;
  }

  .ant-modal-header {
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid #002e4659;
  }
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-body {
    padding: 24px 40px;
  }
  .ant-radio-group {
    margin: 8px 0 20px 0;
  }

  .ant-form-item-required {
    color: #0078e2;
    font-family: 'DM Sans';
    font-weight: 500;
    font-size: 13px;
  }

  .ant-modal-title {
    font-family: 'DM Sans';
    font-weight: 500;
    font-size: 15px;
  }

  .ant-form-item-explain {
    color: crimson;
  }
`;

const Plus = styled.img`
  height: 50px;
  margin: 60px 0 20px 0;
`;

const P = styled.p`
  color: #4e667e;
  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 13px;
`;

const Heading = styled.p`
  font-family: 'DM Sans';
  font-weight: 600;
  font-size: 18px;
  color: #4e667e;
`;

const VitalIcon = styled.img`
  height: 30px;
  padding: 5px;
  border: 1px solid #0078e240;
  border-radius: 50%;
  background: #0078e240;
  margin-bottom: 10px;
`;

const Goal = styled.div`
  flex: 1;
  margin-top: -25px;
  text-align: center;
  background: #fff;
  padding: 10px;
  border-radius: 50%;
`;

const FormLabel = styled(P)`
  font-size: 13px;
  color: #0078e2;
  margin-bottom: 0;
`;

const ProgressStep = styled.div`
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
  /* margin: 0 3px; */
  background: ${(props) => props.color ?? '#0ABB94'};
`;

const Percentage = styled.div`
  p:nth-child(1) {
    margin: 0;
    font-weight: bold;
    font-size: 18px;
    font-family: 'DM sans';
  }

  p:nth-of-type(2) {
    margin: 0;
    font-size: 12px;
    font-family: 'DM sans';
  }
`;

const EmptyDiv = styled.div`
  height: 62.1px;
`;

const Div = styled.div`
  display: flex;
  cursor: pointer;
  margin-top: 12px;

  img {
    height: 14px;
    margin-right: 5px;
  }
`;

const TableFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    > p {
      margin: 0 10px;
      font-size: 13px;
      font-family: DM sans;
      color: #4e667e;
    }
  }

  > p {
    text-align: start;
    position: absolute;
    right: 50px;
    font-size: 13px;
    font-family: DM sans;
    color: #4e667e;
    margin-top: 5px;
  }
`;

const Reading = styled.span`
  margin-left: 6px;
`;

export const LabSidePaneColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
 
  .flex-column-content {
    height: 100%;
    flex: 1;
    overflow: auto;
  }
`;

export {
  Plus,
  P,
  Heading,
  VCard,
  ModalContainer,
  VitalIcon,
  Goal,
  FormLabel,
  ProgressStep,
  Percentage,
  EmptyDiv,
  Div,
  TableFooter,
  Reading
};
