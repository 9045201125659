import { axiosBaseQuery } from './../../utils/redux-toolkit-query';
import { createApi } from '@reduxjs/toolkit/query/react';
import http from 'utils/api';

const PROVIDER_BASE_URL = 'https://provider-api.pneumahealth.co';

const connectApi = createApi({
  reducerPath: 'connectApi',
  keepUnusedDataFor: 60 * 3,
  baseQuery: axiosBaseQuery(undefined, http),
  endpoints: (builder) => ({
    unfurl: builder.query({
      queryFn: async ({ data: url }, __, ___, baseQuery) => {
        try {
          //https://rlp-proxy.herokuapp.com/v2?url=http://daily.co
          return await baseQuery({
            url: `https://rlp-proxy.herokuapp.com/v2`,
            params: { url }
          });
        } catch (error: object | any) {
          console.log(error);
          return {
            error: {
              data: '',
              message: '',
              status: ''
            }
          };
        }
      }
    }),
    getMedications: builder.query({
      query: () => ({ baseURL: PROVIDER_BASE_URL, url: '/medications' })
    }),
    getConditions: builder.query({
      query: () => ({ baseURL: PROVIDER_BASE_URL, url: '/diagnosis' })
    }),
    getInvestigations: builder.query({
      query: () => ({ baseURL: PROVIDER_BASE_URL, url: '/investigations' })
    })
  })
});

export default connectApi;
