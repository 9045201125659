import React, { useState, useMemo } from 'react';

import { Row, Col, Checkbox } from 'antd';
import { LabSidePaneColumnContainer } from './styles';
import Typography from 'component/Typography';
import laboratoryApi from 'redux/queries/laboratory';
import ContentLoading from 'component/ContentLoading';
import MyHealthLabSidePaneOrderTestLabCard from './MyHealthLabSidePaneOrderTestLabCard';

import Input from 'common/Input';

function MyHealthLabSidePaneOrderTestLabSelection(props: any) {
  const [searchTerm, setSearchTerm] = useState('');
  const { formik } = props;

  const investigations = useMemo(
    () => Object.values(formik.values.investigations),
    [formik.values.investigations]
  );

  const investigationIds = investigations.map((item: any) => item.id).join(',');

  const { data, isLoading, isError, refetch } =
    laboratoryApi.useGetLaboratoriesQuery({ ids: investigationIds });

  const laboratories = data?.data;

  // filter labs by searched term
  const searchedLabs = laboratories?.filter(
    (lab: any) =>
      lab.name.toLowerCase().includes(searchTerm.trim().toLowerCase()) ||
      lab.address.city
        .toLowerCase()
        .includes(searchTerm.trim().toLowerCase()) ||
      lab.address.state
        .toLowerCase()
        .includes(searchTerm.trim().toLowerCase()) ||
      lab.address.street_line_one
        .toLowerCase()
        .includes(searchTerm.trim().toLowerCase())
  );

  return (
    <LabSidePaneColumnContainer>
      {laboratories?.length ? (
        <>
          <Input
            placeholder="Search laboratories..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ marginBottom: '.5em' }}
          />
        </>
      ) : null}
      <Typography color="textSecondary" style={{ marginBottom: 16 }}>
        Select at least one diagnostic service provider
      </Typography>
      <div className="flex-column-content">
        <Row gutter={[0, 8]} justify="center">
          <ContentLoading
            loading={isLoading}
            error={isError}
            onReload={refetch}
          >
            {() =>
              searchedLabs?.length ? (
                searchedLabs?.map((laboratory: any, index: number) => (
                  <Col span={24} key={index}>
                    <MyHealthLabSidePaneOrderTestLabCard
                      laboratory={laboratory}
                      onClick={() => {
                        const _laboratory = Object.assign(
                          {},
                          formik.values.laboratory
                        );
                        if (!formik.values.laboratory[laboratory.id]) {
                          _laboratory[laboratory.id] = laboratory;
                        } else {
                          delete _laboratory[laboratory.id];
                        }
                        formik.setFieldValue('laboratory', _laboratory);
                      }}
                      action={
                        <Checkbox
                          checked={!!formik.values.laboratory[laboratory.id]}
                          onChange={(e) => {
                            const _laboratory = Object.assign(
                              {},
                              formik.values.laboratory
                            );
                            if (e.target.checked) {
                              _laboratory[laboratory.id] = laboratory;
                            } else {
                              delete _laboratory[laboratory.id];
                            }
                            formik.setFieldValue('laboratory', _laboratory);
                          }}
                        />
                      }
                    />
                  </Col>
                ))
              ) : (
                <Typography color="textSecondary" style={{ marginTop: 16 }}>
                  No laboratory currently offers that service.
                </Typography>
              )
            }
          </ContentLoading>
        </Row>
      </div>
    </LabSidePaneColumnContainer>
  );
}

export default MyHealthLabSidePaneOrderTestLabSelection;
