import React, { useMemo } from 'react';

import { encounterConsultApi } from 'redux/queries/encounter';

// import download from 'assets/icons/download.svg';
import Drug from './PrescriptionDrug';
// import LinkButton from 'component/LinkButton';
import EmptyTabContent from './EmptyContent';
import prescription from 'assets/icons/prescription.svg';
import SidePaneCard from './Card';

function Prescriptions(props: any) {
  const { data } = props;

  // setup connection with state for refetching - with memoized object arg to prevent unneccesarry rerenders
  encounterConsultApi.useOnPrescriptionAdditionQuery(
    useMemo(() => ({ path: { consultId: data?.id } }), [data?.id]),
    { skip: !data?.id }
  );

  if (data?.status !== 'pending') {
    return (
      <SidePaneCard data={data}>
        {data?.medications?.length ? (
          data?.medications?.map((med: any) => (
            <Drug
              name={`${med.name} ${med.dosage ? `(${med.dosage})` : ''}`}
              description={med.category}
              prescription={`${med.signature || ''} for ${
                med.number_of_days
                  ? `${med.number_of_days} day${
                      med.number_of_days > 1 ? 's' : ''
                    }`
                  : ''
              }`}
            />
          ))
        ) : (
          <EmptyTabContent
            title="You do not have any prescriptions."
            description="Your prescriptions will appear here."
            icon={prescription}
          />
        )}
        {/* <Divider /> */}
        {/* <Row justify="center" align="middle">
          <Col>
            <LinkButton to="#">
              <Space>
                <img
                  src={download}
                  alt=""
                  className="download-img"
                  height={11}
                />
                Download as PDF
              </Space>
            </LinkButton>
          </Col>
          <Col>
            <LinkButton to="#">{`Request medications >`}</LinkButton>
          </Col>
        </Row> */}
      </SidePaneCard>
    );
  }
  return null;
}

export default Prescriptions;
