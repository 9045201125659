import connectApi from './connect-api';
import { LaboratoryRTKQueryTagEnum } from 'constants/redux';

export const laboratoryApi = connectApi.injectEndpoints({
  endpoints: (builder) => ({
    getLaboratories: builder.query<any, { ids?: string }>({
      query: ({ ids }) => ({
        url: `/diagnostics/laboratories?investigations=${ids}`
      })
    }),
    getLaboratory: builder.query({
      query: ({ path }) => ({ url: `/diagnostics/laboratories/${path.id}` })
    }),
    getLaboratoryRequests: builder.query({
      queryFn: (userId, { getState }, __, baseQuery) => {
        return baseQuery({ url: `/diagnostics/requests/user/${userId}`});
      },
      providesTags: [{ type: LaboratoryRTKQueryTagEnum.REQUEST as never }]
    }),
    orderLaboratoryTest: builder.mutation({
      queryFn: async ({ data }, _, __, baseQuery) => {
        const [firstQueryResult] = await Promise.all(
          data.map((item: any) =>
            baseQuery({ url: '/diagnostics/requests', data: item, method: 'POST' })
          )
        );
        return firstQueryResult as any;
      },
      invalidatesTags: [{ type: LaboratoryRTKQueryTagEnum.REQUEST as never }]
    })
  })
});

laboratoryApi.enhanceEndpoints({
  addTagTypes: Object.values(LaboratoryRTKQueryTagEnum)
});

export default laboratoryApi;
