import styled from 'styled-components';

interface CardProps {
  marginBottom: number;
  padding: number;
}

const CardWrapper = styled.div<CardProps>`
  .ant-card {
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 120, 226, 0.1);
    ${(props) => `margin-bottom: ${props.marginBottom}px`};
    .ant-card-body {
      ${(props) => `padding: ${props.padding}px`};
    }
  }

  //collapse styles
  .ant-collapse {
    background: none;
    border: 0;
  }

  .ant-collapse-header {
    padding: 0 !important;
  }

  .ant-collapse-content {
    border: none;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  .ant-collapse > .ant-collapse-item {
    border-bottom: none;
  }

  //table styles
  .ant-table {
    font-size: 13px;
    font-family: DM sans;
    color: #4e667e;

    .ant-table-thead > tr > th {
      background: none;
      color: #4e667e;
    }

    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      background: none;
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
      padding: 7px 34px;
    }
  }
  .ant-table-pagination.ant-pagination {
    display: none;
  }
`;

export { CardWrapper };
