import React, { useMemo } from 'react';

import { encounterConsultApi } from 'redux/queries/encounter';

import { Space } from 'antd';
import testtube from 'assets/icons/test-tube.svg';
import Typography from 'component/Typography';
import EmptyTabContent from './EmptyContent';
import lab from 'assets/icons/labs.svg';
import SidePaneCard from './Card';

function Lab(props: any) {
  const { data } = props;

  // setup connection with state for refetching - with memoized object arg to prevent unneccesarry rerenders
  encounterConsultApi.useOnLabInvestigationAdditionQuery(
    useMemo(() => ({ path: { consultId: data?.id } }), [data?.id]),
    { skip: !data?.id }
  );

  if (data?.status !== 'pending') {
    return (
      <SidePaneCard data={data}>
        {data?.investigations?.length ? (
          <Space direction="vertical">
            {data?.investigations?.map((investigation: any) => (
              <Space>
                <img src={testtube} alt="" height={11} />
                <Typography>{investigation.name}</Typography>
              </Space>
            ))}
          </Space>
        ) : (
          <EmptyTabContent
            title="You do not have any lab requests."
            description="Your lab requests will appear here."
            icon={lab}
          />
        )}
      </SidePaneCard>
    );
  }
  return null;
}

export default Lab;
