import React, { useContext } from 'react';
import { Col, Row } from 'antd';
import Button from 'component/Button';
import { PlanWrapper } from './styles';
import { SinglePlan, IConfig } from 'config/interfaces';
import { priceFormatter } from 'utils/helpers';
import { Context } from 'context/context';

interface ServicePlanProps {
  data: SinglePlan;
  setSelectedPlan: (plan: SinglePlan) => void;
  actionText?: string;
}

const ServicePlan = ({
  data,
  setSelectedPlan,
  actionText
}: ServicePlanProps) => {
  const { name, description, schedule, unit, price } = data;

  const config: IConfig = JSON.parse(useContext(Context));

  const { markup } = config || '';

  const updatedPrice = markup
    ? (Number(markup) / 100) * (Number(price) / 100) + Number(price) / 100
    : Number(price) / 100;

  return (
    <>
      <PlanWrapper className="plans">
        <Row>
          <Col span={24}>
            <h3>{name}</h3>
            <p>
              <span>{description} </span> <br />
              <span>
                <b>Schedule</b>: {schedule}
              </span>{' '}
              <br />
            </p>
          </Col>
          <Col span={24}>
            <span className="price--amount">
              {Number(price) / 100 >= 1000
                ? `${priceFormatter(updatedPrice)} ${unit.toLowerCase()}`
                : 'Free'}
            </span>
            <Button type="primary" onClick={() => setSelectedPlan(data)}>
              {actionText ?? 'Book appointment'}
            </Button>
          </Col>
        </Row>
      </PlanWrapper>
    </>
  );
};

export default ServicePlan;
