import React from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { toggleLoadingSpinAction } from 'redux/reducers/global';

export function useLoadingSpin() {
  const dispatch = useAppDispatch();
  const isLoadingSpin = useAppSelector((state) => state.global.isLoadingSpin);

  function toggleLoadingSpin(payload?: boolean) {
    return dispatch(toggleLoadingSpinAction(payload));
  }

  function openLoadingSpin() {
    return toggleLoadingSpin(true);
  }

  function closeLoadingSpin() {
    return toggleLoadingSpin(false);
  }

  return {
    isLoadingSpin,
    toggleLoadingSpin,
    openLoadingSpin,
    closeLoadingSpin
  };
}

export default useLoadingSpin;
