import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "../store";
import { message as AntdMessage } from "antd";
import httpPublic from "../../utils/api";

export interface UserError {
  message: string;
}

export interface UserState {
  user: any;
  users: Array<any>;
  isUserLoading: boolean;
  isUsersLoading: boolean;
  isCreateUserLoading: boolean;
  isVerifyUserLoading: boolean;
  isRequestUserAccessLoading: boolean;
  hasCreatedUser: boolean;
  hasVerifiedUser: boolean|null;
  hasRequestedUserAccess: boolean|null;
  userError: UserError;
  usersError: UserError;
  createUserError: UserError;
  verifyUserError: UserError;
  requestUserAccessError: UserError;
  pageStepNo: number;
}

export const initialState: UserState = {
  user: null,
  users: [],
  isUserLoading: true,
  isUsersLoading: true,
  isCreateUserLoading: false,
  isVerifyUserLoading: false,
  isRequestUserAccessLoading: false,
  hasCreatedUser: false,
  hasVerifiedUser: null,
  hasRequestedUserAccess: false,
  userError: { message: "" },
  usersError: { message: "" },
  createUserError: { message: "" },
  verifyUserError: { message: "" },
  requestUserAccessError: { message: "" },
  pageStepNo: 0
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    // CREATE USER
    createUserLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreateUserLoading = payload;
      state.hasCreatedUser = false;
    },
    createUserSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
      state.user = payload;
      state.hasCreatedUser = true;
    },
    createUserFailed: (state, { payload }: PayloadAction<UserError>) => {
      state.createUserError = payload;
      state.user = null;
      state.hasCreatedUser = false;
    },
    // VERIFY USER
    resetHasVerifiedUser: (state) => {
        state.hasVerifiedUser = null;
    },
    verifyUserLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isVerifyUserLoading = payload;
    },
    verifyUserSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
      state.hasVerifiedUser = true;
    },
    verifyUserFailed: (state, { payload }: PayloadAction<UserError>) => {
      state.verifyUserError = payload;
      state.hasVerifiedUser = false;
    },
    // REQUEST USER ACCESS
    requestUserAccessLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isRequestUserAccessLoading = payload;
      state.hasRequestedUserAccess = null;
    },
    requestUserAccessSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
      state.hasRequestedUserAccess = true;
    },
    requestUserAccessFailed: (state, { payload }: PayloadAction<UserError>) => {
      state.requestUserAccessError = payload;
      state.hasRequestedUserAccess = false;
    },
    // FETCH SINGLE USER
    fetchUserLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isUserLoading = payload;
    },
    fetchUserSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
      state.user = payload;
    },
    fetchUserFailed: (state, { payload }: PayloadAction<UserError>) => {
      state.userError = payload;
      state.user = null;
    },
    // FETCH ALL USERS
    fetchUsersLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isUsersLoading = payload;
    },
    fetchUsersSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
      state.users = payload;
    },
    fetchUsersFailed: (state, { payload }: PayloadAction<UserError>) => {
      state.usersError = payload;
    },
    setPageStepNo: (state, { payload }: PayloadAction<number>) => {
      state.pageStepNo = payload;
    }
  },
});

export const {
  createUserLoading,
  createUserSuccess,
  createUserFailed,
  resetHasVerifiedUser,
  verifyUserLoading,
  verifyUserSuccess,
  verifyUserFailed,
  requestUserAccessLoading,
  requestUserAccessSuccess,
  requestUserAccessFailed,
  fetchUserLoading,
  fetchUserSuccess,
  fetchUserFailed,
  fetchUsersLoading,
  fetchUsersSuccess,
  fetchUsersFailed,
  setPageStepNo
} = usersSlice.actions;
export const userSelector = (state: { users: UserState }) => state.users;
export default usersSlice.reducer;

/** Actions */

export const createUser =
  (payload: any, firebaseHandler: any): AppThunk =>
  async (dispatch: any) => {
    dispatch(createUserLoading(true));
    await httpPublic
      .post(`/users`, payload)
      .then((res) => {
        const user = res?.data?.data;
        dispatch(createUserSuccess(user));
        firebaseHandler(user);
        AntdMessage.success("User created successfully");
      })
      .catch((err) => {
        const message = {
          message: err?.response?.data?.message || "An error occurred",
        };
        dispatch(createUserFailed(message));
      });
    dispatch(createUserLoading(false));
  };

export const verifyUser =
  (payload: any, firebaseHandler: any): AppThunk =>
  async (dispatch: any) => {
    dispatch(verifyUserLoading(true));
    dispatch(setPageStepNo(0));
    await httpPublic
      .post(`/users/verify`, payload)
      .then((res) => {
        const user = res?.data?.data;
        dispatch(verifyUserSuccess(user));
        dispatch(requestUserAccess(payload, firebaseHandler))
      })
      .catch((err) => {
        const message = {
          message: err?.response?.data?.message || "An error occurred",
        };
        dispatch(verifyUserFailed(message));
        dispatch(setPageStepNo(1));
      });
    dispatch(verifyUserLoading(false));
  };

export const requestUserAccess =
  (payload: any, firebaseHandler: any): AppThunk =>
  async (dispatch: any) => {
    dispatch(requestUserAccessLoading(true));
    await httpPublic
      .post(`/users/request_access`, payload)
      .then((res) => {
        const user = res?.data?.data;
        dispatch(requestUserAccessSuccess(user));
        dispatch(setPageStepNo(2));
        AntdMessage.success("User access requested successfully");
      })
      .catch((err) => {
        const message = {
          message: err?.response?.data?.message || "An error occurred",
        };
        dispatch(requestUserAccessFailed(message));
        if (err?.response?.data?.message === "User already on integration") {
          // check on firebase and retrieve
          firebaseHandler(payload);
        }
      });
    dispatch(requestUserAccessLoading(false));
  };

  export const approveUserAccessRequest =
  (otp: string): AppThunk =>
  async (dispatch: any) => {
    dispatch(createUserLoading(true));
    await httpPublic
      .post(`/users/approve_request/${otp}`)
      .then((res) => {
        const user = res?.data?.data;
        dispatch(createUserSuccess(user));
        AntdMessage.success("User successfully onboarded");
      })
      .catch((err) => {
        const messages = {
          message: err?.response?.data?.message || "An error occurred",
        };
        AntdMessage.error("Wrong OTP! Please go back and request OTP again");
        dispatch(createUserFailed(messages));
      });
    dispatch(createUserLoading(false));
  }
