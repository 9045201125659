import React, { useContext } from 'react';
import { Col, Row, Empty, Divider } from 'antd';
import Note from './Note';
import Messages from './Messages';
import List from './List';
import Button from 'common/Button';
import EmptyContent from 'component/SidePane/EmptyContent';
import consultUnselectedMessageSvg from 'assets/consult-unselected-message.gif';
import Typography from 'component/Typography';
import { Link } from 'react-router-dom';
import { RouteEnum } from 'constants/routes';
import ConsultContext from '../Context';

function Chats(props: any) {
  const { isViewNotes, consult, consults } = props;
  const { isChatList, breakpoints } = useContext(ConsultContext);

  if (!consults?.length) {
    return (
      <Row style={{ height: '100%', overflow: 'auto' }} justify="center">
        <Col>
          <Empty
            image={consultUnselectedMessageSvg}
            imageStyle={{
              height: 250
            }}
            description={
              <Typography>
                Oh! you’ve not had an encounter with a doctor
              </Typography>
            }
          >
            <Link to={RouteEnum.ENCOUNTER_START}>
              <Button type="primary">Start an encounter</Button>
            </Link>
          </Empty>
        </Col>
      </Row>
    );
  }

  return (
    <Row style={{ height: '100%' }} wrap={false} justify="center">
      <Col xs={isChatList ? 24 : 0.1} md={8} style={{ height: '100%' }}>
        <List />
      </Col>
      {breakpoints.md ? (
        <Col>
          <Divider type="vertical" style={{ height: '100%' }} />
        </Col>
      ) : null}
      <Col xs={isChatList ? 0 : 24} md={15} style={{ height: '100%' }}>
        {!!consult ? (
          isViewNotes ? (
            <Note consult={consult} />
          ) : (
            <Messages consult={consult} />
          )
        ) : (
          <Row align="middle" justify="center" style={{ height: '100%' }}>
            <Col
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                alignItems: 'center'
              }}
            >
              <EmptyContent
                imageStyle={{ height: 250 }}
                title="Select a previous consultation to view or"
                // description="Please select a consult"
                icon={consultUnselectedMessageSvg}
              />

              <Link to={RouteEnum.ENCOUNTER_START}>
                <Button type="primary">Start a consultation</Button>
              </Link>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
}

export default Chats;
