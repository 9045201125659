import { combineReducers } from '@reduxjs/toolkit';
import global from './global';
import connectApi from '../queries/connect-api';
import AuthReducer from './auth';
import UserReducer from './users';
import ExaminationReducer from './examinations';
import EncountersReducer from './encounters';
import SymptomReducer from './symptoms';
import DiagnosisReducer from './diagnoses';
import ConsultReducer from './consults';
import MessageReducer from './messages';
import InvestigationReducer from './investigations';
import MedicationReducer from './medications';

const rootReducer = combineReducers({
  global,
  [connectApi.reducerPath]: connectApi.reducer,
  auth: AuthReducer,
  users: UserReducer,
  encounters: EncountersReducer,
  examinations: ExaminationReducer,
  symptoms: SymptomReducer,
  medications: MedicationReducer,
  investigations: InvestigationReducer,
  diagnoses: DiagnosisReducer,
  consults: ConsultReducer,
  messages: MessageReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
