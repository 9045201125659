import React from 'react';
import { Card as AntdCard } from 'antd';
import { CardWrapper } from './styles';

const Card = (props: any) => {
  const { padding, marginBottom, ...rest } = props;
  return (
    <CardWrapper marginBottom={marginBottom} padding={padding}>
      <AntdCard {...rest}>{props.children}</AntdCard>
    </CardWrapper>
  );
};

export default Card;
