export const EncounterRTKQueryTagEnum = {
  CONSULT: 'CONSULT'
};

export const ProfileRTKQueryTagEnum = {
  PROFILE: 'PROFILE'
};

export const DeviceRTKQueryTagEnum = {
  AUTHORIZED_DEVICES: 'AUTHORIZED_DEVICES'
};

export const HealthRTKQueryTagEnum = {
  VITALS: 'HealthVitals'
};

export const LaboratoryRTKQueryTagEnum = {
  REQUEST: 'Request'
};
