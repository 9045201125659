import connectApi from './connect-api';
import { ProfileRTKQueryTagEnum } from 'constants/redux';
import { objectToFormData } from '../../utils/api';
import firebase from 'firebase/app';
import { removeEmptyFields } from '../../utils/global';

export const profileApi = connectApi.injectEndpoints({
  endpoints: (builder) => ({
    getPatientProfile: builder.query({
      query: ({ userId }) => ({ url: `/users/${userId}` }),
      // queryFn: async (userId, { getState, dispatch }, __, baseQuery) => {
      //   try {
      //     const db = firebase.firestore();
      //     const bbb = (await db.collection('users').doc(userId).get());
      //     console.log(bbb);
      //     const patientId = (getState() as any).global?.user?.patientId || (await db.collection('users').doc(userId).get()).data()!.patientId;
      //     const profile = await baseQuery({
      //       url: `/users/${patientId}`
      //     });
      //     if (profile.error) {
      //       if (profile.error.data?.error === 'Patient not found') {
      //         dispatch(profileApi.endpoints.logoutPatient.initiate(undefined))
      //       }

      //       throw profile.error;
      //     }
      //     const result = Object.assign({}, profile);
      //     Object.assign(result.data.data, { patientId });
      //     console.log(result)
      //     return result;
      //   } catch (error: any | object) {
      //     console.log(error);
      //     return {
      //       error: {
      //         message: error?.message,
      //         status: error?.code,
      //         data: {}
      //       }
      //     };
      //   }
      // },
      providesTags: [{ type: ProfileRTKQueryTagEnum.PROFILE as never }]
    }),
    logoutPatient: builder.mutation({
      queryFn: async () => {
        try {
          await firebase.auth().signOut();
          return { data: null };
        } catch (error: object | any) {
          return {
            error: {
              status: 0,
              message: 'Something went wrong',
              data: null
            }
          };
        }
      }
    }),
    loginPatient: builder.mutation({
      queryFn: async ({ data: { email, password }, firebase }) => {
        try {
          const res = await firebase
            .auth()
            .signInWithEmailAndPassword(email, password);

          return {
            data: {
              uid: res.user?.uid
            }
          };
        } catch (error: any | object) {
          return {
            error: {
              message: error?.message || error,
              status: error?.code,
              data: {}
            }
          };
        }
      },
      invalidatesTags: [{ type: ProfileRTKQueryTagEnum.PROFILE as never }]
    }),
    createPatientProfile: builder.mutation({
      queryFn: async (
        {
          data: { email, password, confirm_password, ...data },
          firebase,
          collectionName
        },
        __,
        ___,
        baseQuery
      ) => {
        try {
          const db = firebase.firestore();
          const { user } = await firebase
            .auth()
            .createUserWithEmailAndPassword(email, password);
          const profile = await baseQuery({
            url: '/users',
            data: { ...data, email },
            method: 'POST'
          });

          if (profile.error) {
            throw profile;
          }

          const docRef = db.collection(collectionName).doc(user?.uid);

          const result = Object.assign({}, profile);

          Object.assign(result.data.data, { patientId: result.data.data.id });

          await docRef.set({
            patientId: result.data?.data?.id
          });

          return result;
        } catch (error: any | object) {
          // @TODO delete firebase user
          console.log(error);
          if (error.error) {
            return error;
          }
          return {
            error: {
              message: error?.message,
              status: error?.code,
              data: {}
            }
          };
        }
      }
      // invalidatesTags: [{ type: ProfileRTKQueryTagEnum.PROFILE as never }]
    }),
    updatePatientProfile: builder.mutation({
      // query: ({ path, data }) => ({
      //   url: `/users/${path.patient_id}`,
      //   method: 'PUT',
      //   // data: objectToFormData(data)
      //   data
      // }),
      queryFn: async (
        { path, data: { medical_record, image, ...basics } },
        { getState },
        ___,
        baseQuery
      ) => {
        // const user = (getState() as any).global.user;

        // const [removedMedicalRecords, addedMedicalRecords] =
        //   getMedicalRecordDiff(user?.medical_record, medical_record);

        const [basicResult] = await Promise.allSettled([
          baseQuery({
            url: `/users/${path.patient_id}`,
            data: objectToFormData({ image }),
            method: 'PUT'
          }),
          baseQuery({
            url: `/users/${path.patient_id}`,
            data: removeEmptyFields(basics),
            method: 'PUT'
          }),
          await baseQuery({
            url: `/users/${path.patient_id}/medical_records`,
            data: removeEmptyFields(medical_record, { allowEmptyArray: true }),
            method: 'POST'
          })
        ]);

        // if (Object.keys(removedMedicalRecords).length) {
        //   await baseQuery({
        //     url: `/users/${path.patient_id}/medical-records`,
        //     data: removedMedicalRecords,
        //     method: 'DELETE'
        //   });
        // }

        // if (Object.keys(addedMedicalRecords).length) {
        //   await baseQuery({
        //     url: `/users/${path.patient_id}/medical-records`,
        //     data: addedMedicalRecords,
        //     method: 'POST'
        //   });
        // }

        return (basicResult as any).value;

        function getMedicalRecordDiff(prevRecords: any, newRecords: any) {
          const removedRecords = {} as any;
          const addedRecords = {} as any;
          for (const key in newRecords) {
            if (Array.isArray(newRecords[key])) {
              const [removed, added] = getDiff(
                prevRecords[key],
                newRecords[key]
              );
              console.log(key, removed, added);

              removedRecords[key] = removed.map((item) => {
                if (typeof item === 'string') {
                  return item;
                }
                return item.type;
              });
              addedRecords[key] = added;
            } else {
              addedRecords[key] = newRecords[key];
            }
          }
          return [
            removeEmptyFields(removedRecords),
            removeEmptyFields(addedRecords)
          ];
          function getDiff(prevArray: any[], newArray: any[]) {
            const removed = [];
            const added = [];
            for (const value of prevArray) {
              const found = newArray.some(
                (item) => JSON.stringify(item) === JSON.stringify(value)
              );
              if (!found) {
                removed.push(value);
              }
            }
            for (const value of newArray) {
              const found = prevArray.some(
                (item) => JSON.stringify(item) === JSON.stringify(value)
              );
              if (!found) {
                added.push(value);
              }
            }
            return [removed, added];
          }
        }
      },
      invalidatesTags: [{ type: ProfileRTKQueryTagEnum.PROFILE as never }]
    }),
    verifyPatientEmail: builder.mutation({
      queryFn: async ({ data: { email } }, _, __, baseQuery) => {
        try {
          const profile = await baseQuery({
            url: '/users/verify',
            data: { email },
            method: 'POST'
          });

          if (profile.error) {
            throw profile;
          }
          return profile;
        } catch (error: any | object) {
          if (error.error) {
            return error;
          }
          return {
            error: {
              message: error?.message,
              status: error?.code,
              data: {}
            }
          };
        }
      }
    }),
    requestPatientAccess: builder.mutation({
      queryFn: async ({ data: { email } }, _, __, baseQuery) => {
        try {
          const profile = await baseQuery({
            url: 'users/request_access',
            data: { email },
            method: 'POST'
          });

          if (profile.error) {
            throw profile;
          }
          return profile;
        } catch (error: any | object) {
          if (error.error) {
            return error;
          }
          return {
            error: {
              message: error?.message,
              status: error?.code,
              data: {}
            }
          };
        }
      }
    }),
    approvePatientAccess: builder.mutation({
      queryFn: async ({ data: { code } }, _, __, baseQuery) => {
        try {
          const res = await baseQuery({
            url: `users/approve_request/${code}`,
            method: 'POST'
          });

          if (res.error) {
            throw res;
          }

          return res;
        } catch (error: any | object) {
          if (error.error) {
            return error;
          }
          return {
            error: {
              message: error?.message,
              status: error?.code,
              data: {}
            }
          };
        }
      }
    }),
    recoverPatientPassword: builder.mutation({
      queryFn: async ({ data: { email }, firebase }) => {
        try {
          await firebase
            .auth()
            .sendPasswordResetEmail(email, {
              url: window.location.origin,
              handleCodeInApp: false
            })
            .then(() => {
              // password reset email sent
            })
            .catch((err: object | any) => {
              // error occurred
              throw new Error(err);
            });

          return {
            data: {
              message: 'Reset email sent successfully'
            }
          };
        } catch (error: any | object) {
          return {
            error: {
              message: error?.message || error,
              status: error?.code,
              data: {}
            }
          };
        }
      }
    })
  })
});

profileApi.enhanceEndpoints({
  addTagTypes: Object.values(ProfileRTKQueryTagEnum)
});
