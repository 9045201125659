import { Divider, Row, Col, Space } from 'antd';
import Card from 'common/Card';
import laboratoryApi from 'redux/queries/laboratory';
import ContentLoading from 'component/ContentLoading';
import Typography from 'component/Typography';
import Button from 'common/Button';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'component/Modal';
import { ReactComponent as Track1 } from 'assets/lab_investigation_track1.svg';
import { ReactComponent as Track2 } from 'assets/lab_investigation_track2.svg';
import { ReactComponent as Track3 } from 'assets/lab_investigation_track3.svg';
import { ReactComponent as Track4 } from 'assets/lab_investigation_track4.svg';
import moment from 'moment';
import http from 'utils/connect-http';
import { IConfig } from 'config/interfaces';
import { Context } from 'context/context';

function MyHealthLabSidePaneTrackOrder(props: any) {
  const config: IConfig = JSON.parse(useContext(Context));
  const { userId } = config || '';
  const { data, isLoading, isError, refetch } =
    laboratoryApi.useGetLaboratoryRequestsQuery(userId);

  const [enrichedOrders, setEnrichedOrders] = useState<any[]>([]);

  useEffect(() => {
    handleEnrichOrders(data)
  }, [data]);

  const handleEnrichOrders = async(data: any) => {
    let orders = data?.data.map((item: any) => 
      Object.assign({}, item, {selected:false})
    ) || []
    setEnrichedOrders(orders)
  }

  const [selected, setSelected] = useState(null as any);
  return (
    <ContentLoading loading={isLoading} error={isError} onReload={refetch}>
      {() => (
        <>
          {!!selected && (
            <Modal visible footer={null} onCancel={() => setSelected(null)}>
              <Row>
                <Col>
                  <Typography weight="bold">
                    {selected.investigation.investigation_name}
                  </Typography>
                  <Typography>{selected.order?.laboratory?.name}</Typography>
                  <div
                    style={{
                      borderLeft: '1px dashed grey',
                      marginTop: 32,
                      marginLeft: 20
                    }}
                  >
                    <div style={{ marginLeft: -20 }}>
                      <Space
                        direction="vertical"
                        style={{ width: '100%' }}
                        size={40}
                      >
                        <Step
                          icon={Track1}
                          title="You sent a request"
                          date="9:30 AM, Sep 18 2021"
                        />
                        <Step
                          icon={Track2}
                          title="Samples collected"
                          date="9:30 AM, Sep 18 2021"
                        />
                        <Step
                          icon={Track3}
                          title="Your result has been reviewed"
                          date="9:30 AM, Sep 18 2021"
                        />
                        <Step
                          icon={Track4}
                          title="Your result has been sent to you inbox"
                          date="9:30 AM, Sep 18 2021"
                        />
                      </Space>
                    </div>
                  </div>
                </Col>
              </Row>
            </Modal>
          )}
          <Row gutter={[0, 8]}>
            {enrichedOrders?.map((order, index) => {
              return (
               <Col span={24} key={index}>
                <Card bodyStyle={{ padding: 16 }}>
                  <Row>
                    <Col span={12}>
                      <Typography>{order?.laboratory?.name}</Typography>
                    </Col>
                    <Col span={12} style={{textAlign: 'right'}}>
                      <Typography style={{textTransform: 'capitalize'}}>{order.status}</Typography>
                    </Col>
                  </Row>
                  <Divider style={{ margin: '8px 0px' }} />
                  <Row wrap={false} align="middle">
                    <Col flex={1}>
                      <Typography>
                        Total Price: NGN {(order?.total_price ? (order?.total_price/100) : 0)?.toLocaleString()}
                      </Typography>
                    </Col>
                    <Col>
                      <Typography weight="normal">
                        {moment(order.created_at).format('LL')}
                      </Typography>
                    </Col>
                  </Row>
                  <div className="" style={{ width: '100%'}}>
                    {order?.investigations?.map((investigation: any) => (
                      <Row wrap={false} align="middle">
                        <Col flex={1}>
                          <Typography key={index}>
                            {investigation?.name}
                          </Typography>
                        </Col>
                      </Row>
                    ))}
                  </div>
                </Card>
              </Col>
              )
            })}
          </Row>
        </>
      )}
    </ContentLoading>
  );
}

export default MyHealthLabSidePaneTrackOrder;

function Step(props: any) {
  const { icon, title, date } = props;
  const Icon = icon;

  return (
    <Row gutter={16} wrap={false} align="middle">
      <Col style={{ height: 40 }}>{<Icon height={40} width={40} />}</Col>
      <Col flex={1}>
        <Typography weight="bold">{title}</Typography>
        <Typography color="textSecondary" variant="body2">
          {date}
        </Typography>
      </Col>
    </Row>
  );
}
