import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: 56px 16px 16px;
  box-shadow: ${({ theme }) => theme.shadows[2]};
  height: 100%;

  @media (min-width: 1200px) {
    padding: 12px;
  }
`;

export const EmptyContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    /* height: 91px; */
    margin-bottom: 15px;
  }
`;

export const OverallDiv = styled.div<{ completed: boolean }>`
  border-left: 5px solid
    ${({ theme, completed }) =>
      theme.palette[completed ? 'primary' : 'success'].main};
  padding-left: 15px;

  .treatment-name {
    opacity: 0.6;
  }

  .provider-name {
    color: #0078e2;
  }

  .ant-checkbox-wrapper {
    color: #0078e2;
    font-size: 12px;
    font-family: 'DM Sans';
  }
`;

export const InnerDiv = styled.div`
  border-top: 2px solid #f0f0f0;
  padding: 10px 0;
  margin: 10px 0;
  border-bottom: 2px solid #f0f0f0;

  img {
    height: 11px;
    margin-top: -5px;
  }
`;
