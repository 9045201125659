import React, { ReactNode } from 'react';
import { Col, ColProps, Row, Space } from 'antd';
import Typography, { TypographyProps } from 'component/Typography';

export type InfoTypographyProps = {
  label?: ReactNode;
  value?: ReactNode;
  labelCol?: ColProps;
  valueCol?: ColProps;
  labelTypographyProps?: TypographyProps;
  valueTypographyProps?: TypographyProps;
};

function InfoTypography(props: InfoTypographyProps) {
  const {
    label,
    value,
    labelCol,
    valueCol,
    labelTypographyProps,
    valueTypographyProps
  } = props;

  return (
    <Row style={{ marginBottom: 12 }}>
      <Col xs={24} sm={12} md={5} {...labelCol}>
        {renderItem(label, labelTypographyProps)}
      </Col>
      <Col {...valueCol}>
        {Array.isArray(value) ? (
          <Space direction="vertical" size="small">
            {value.map(
              (item) =>
                renderItem(item, {
                  color: 'textSecondary',
                  ...valueTypographyProps
                }) as any
            )}
          </Space>
        ) : (
          renderItem(value, { color: 'textSecondary', ...valueTypographyProps })
        )}
      </Col>
    </Row>
  );
}

export default InfoTypography;

function renderItem(item: any, props?: any) {
  if (typeof item === 'string')
    return <Typography {...props}>{item}</Typography>;
  return item;
}
