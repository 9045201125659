import { Space, Row, Col } from 'antd';
import { PhoneFilled } from '@ant-design/icons';
import Card from 'component/Card';
import Typography from 'component/Typography';
import CurrencyTypography from 'component/CurrencyTypography';
import { ReactComponent as AmountSvg } from 'assets/lab_investigation_amount.svg';
import { ReactComponent as ClockSvg } from 'assets/lab_investigation_clock.svg';
import { ReactComponent as LocationSvg } from 'assets/lab_investigation_location.svg';
import { ReactComponent as TestSvg } from 'assets/lab_investigation_test.svg';
import { useTheme } from 'styled-components';
import React, { useMemo } from 'react';
import currency from 'currency.js';

function MyHealthLabSidePaneOrderTestLabCard(props: any) {
  const {
    laboratory,
    labInvestigations,
    availableInvestigations,
    requestedInvestigations,
    investigations,
    onClick,
    children,
    action
  } = props;

  const theme = useTheme() as any;

  const amount = useMemo(
    () =>
      availableInvestigations?.reduce((acc: any, curr: any) => {
        acc = currency(acc).add(curr.price / 100);
        return acc;
      }, 0),
    [availableInvestigations]
  );

  return (
    <Card bodyStyle={{ padding: 16 }} onClick={onClick}>
      <Row wrap={false}>
        <Col flex={1}>
          <Typography weight="bold">{laboratory.name}</Typography>
          <Space direction="vertical" size={2}>
            <Space>
              <LocationSvg />
              <Typography variant="body2" color="textSecondary">
                {`${
                  laboratory?.address?.post_code
                    ? laboratory?.address?.post_code + ','
                    : ''
                } ${
                  laboratory?.address?.street_line_one
                    ? laboratory?.address?.street_line_one + ','
                    : ''
                } ${
                  laboratory?.address?.city
                    ? laboratory?.address?.city + ','
                    : ''
                } ${
                  laboratory?.address?.state
                    ? laboratory?.address?.state + ','
                    : ''
                } ${laboratory?.address?.country || ''}`}
              </Typography>
            </Space>
            <Space>
              <PhoneFilled
                style={{ color: theme.palette.primary.main, fontSize: 10 }}
              />
              <Typography variant="body2" color="textSecondary">
                {laboratory?.phone}
              </Typography>
            </Space>
            {availableInvestigations && investigations ? (
              <Space>
                <TestSvg />
                <Typography variant="body2" color="textSecondary">
                  {availableInvestigations.length} of {requestedInvestigations.length}{' '}
                  tests available
                </Typography>
              </Space>
            ) : null}
            {availableInvestigations && investigations ? (
              <Space>
                <AmountSvg />
                <CurrencyTypography variant="body2" color="textSecondary">
                  {amount}
                </CurrencyTypography>
              </Space>
            ) : null}
          </Space>
        </Col>
        <Col>{action}</Col>
      </Row>
      {children}
    </Card>
  );
}

export default MyHealthLabSidePaneOrderTestLabCard;
